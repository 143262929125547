import { useData } from '../..';
import { mapElements, pathSel } from '../project';

type Tprops_CustomListener = {
  pass: { variable: string[]; childrenItems: any[]; args: any };
};
export const CustomListener = (props: Tprops_CustomListener) => {
  // ---------- set Props
  const { variable, childrenItems, args } = props.pass;

  // ---------- set Watch Data
  const watchData = useData(ct => pathSel(ct, variable.join()));
  args.watch = watchData; // pass values to children

  console.log({ watchData });
  console.log({ args });

  // ---------- set Children Items
  const children = mapElements(childrenItems, args);

  return <>{children}</>;
};
