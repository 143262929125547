import { useEffect, useState } from 'react';
import { Text, TextStyle, View } from 'react-native';

import { calcPriceOver } from './calcPriceOver';
import { getHourAndMin } from './getHourAndMin';
import { pathSel, setData } from '../../project';
import { useData } from '../../..';

type Tprops = { pass: { pathToCheck: any[]; pathRunning: any[]; args: any } };

export const CountTimer = (props: Tprops) => {
  const { pathToCheck, pathRunning, args } = props.pass;

  // ---------- set Watchers
  const [seconds, setSeconds] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const secondsToInit = useData(ct => pathSel(ct, pathToCheck.join()));
  const externalControl = useData(ct => pathSel(ct, pathRunning.join()));

  // ----------- AJUSTAR P/ TRAZER PELO FLAX
  const prefixPath = 'sc.A14.forms.editData';
  const prefixPath2 = 'sc.B5.currData';
  const finalPath = 'timer.initTimeStamp';

  const initStamp14 = useData(ct => pathSel(ct, `${prefixPath}.${finalPath}`));
  const initStamp5 = useData(ct => pathSel(ct, `${prefixPath2}.${finalPath}`));

  const selectedHours14 = useData(ct =>
    pathSel(ct, `${prefixPath}.totalHours`),
  );
  const selectedHours5 = useData(ct =>
    pathSel(ct, `${prefixPath2}.totalHours`),
  );

  const bikesList14 = useData(ct => pathSel(ct, `${prefixPath}.bikesList`));
  const bikesList5 = useData(ct => pathSel(ct, `${prefixPath2}.bikesList`));

  // ---------- set Controlers Funcs Start / Stop
  const startTimer = () => {
    // setRunning(true);

    const funcToLoop = () => setSeconds(prevSeconds => prevSeconds + 1);
    const newInterval = setInterval(funcToLoop, 1000);
    setIntervalId(newInterval);

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(newInterval); // Use newInterval aqui
  };

  const stopTimer = () => {
    // setSeconds(0);
    clearInterval(intervalId);
  };

  // ---------- Defina o formato como 00:00:00
  const { hours, minutes } = getHourAndMin(seconds);
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const newCounter = seconds % 60;
  const remainingSeconds = newCounter.toFixed(0).padStart(2, '0');
  const formattedTime = `${formattedHours}:${formattedMinutes}:${remainingSeconds}`;

  // ---------- set Watch Init / Stop
  const condTime1 = externalControl === 'true';
  const condTime2 = externalControl === 'false';
  const condTime3 = externalControl === 'waiting';
  const condNaN = isNaN(secondsToInit);

  // ------------- CALC PRICE TO BE CHARGED
  const passProps = {
    initStamp: initStamp14 || initStamp5,
    selectedHours: selectedHours14 || selectedHours5,
    bikesList: bikesList14 || bikesList5,
  };
  const { currencyVal, condOver } = calcPriceOver(passProps);
  if (condOver) {
    setData({ path: 'sc.A14.currData.priceOver', value: currencyVal });
  } else {
    setData({ path: 'sc.A14.currData.priceOver', value: 'R$0,00' });
  }
  // console.log({ currencyVal });

  // const currencyVal = setCurrPrice(bikesList, selectedHours, 'manyTimes');
  // console.log({ pathToCheck });
  // console.log({ pathToCheck });
  // console.log({ secondsToInit });
  // console.log({ pathRunning });
  // console.log({ externalControl });

  // ------------- START & STOP CONTROL
  useEffect(() => {
    // console.log({ externalControl });
    // console.log({ condTime1 });
    // console.log({ condTime2 });
    // console.log({ condTime3 });
    // console.log({ condNaN });

    condTime1 && startTimer();
    condTime2 && stopTimer();

    if (condTime3 || condNaN) {
      setSeconds(0);
      stopTimer();
    }
    if (!!secondsToInit) setSeconds(secondsToInit);
  }, [externalControl]);

  // ---------- TIME SECONDS CONTROL
  useEffect(() => {
    if (condTime3 || condNaN) setSeconds(0);
    if (!!secondsToInit) setSeconds(secondsToInit);
  }, [secondsToInit]);

  return (
    <View>
      <Text style={stlTxtCounter}> {`${formattedTime}`}</Text>
    </View>
  );
};

const stlTxtCounter: TextStyle = {
  fontSize: 18,
  fontWeight: 'bold',
};
