import { getCtData, setData } from '../../project';

type Tprops = {
  pass: {
    ipt1: any[];
    ipt2: string;
    ipt3: any[];
  };
  args?: any;
};

export const custom02 = async (props: Tprops) => {
  const { ipt1, ipt2, ipt3 } = props.pass;
  const myArray = ipt1[0];
  console.log({ myArray });

  const isArray = Array.isArray(myArray);

  const newArray = [];
  if (isArray) {
    for (const key in myArray) {
      const item = myArray[key];

      newArray.push({ label: item.localName, id: item.docId });
    }
  }

  console.log({ path: ipt2 });
  console.log({ newArray });
  setData({ path: ipt2, value: newArray });
};
