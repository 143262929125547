import { setData } from '../../../project';
import { errorPathShow, errorPathTxt, txtErrorDefault1 } from './paths';

type Tcheck =
  | {
      [key: string]: any;
    }
  | Response;

type TarrTransaction = {
  last_transaction: {
    status: 'not_authorized' | 'captured';
  };
  status: 'failed';
}[];

type TjsonRes = {
  errors?: Record<string, string[]>;
  message?: string;
  charges?: // | {
  //     last_transaction: {
  //       gateway_response: { errors: { message: string }[]; message?: string };
  //     };
  //     errors?: string[];
  //   }
  TarrTransaction;
  status: 'failed' | 'paid';
};

export const setErrorTrue = (msg: string) => {
  setData({ path: 'all.toggles.loaderPurchase', value: null });
  setData({ path: errorPathTxt, value: 'Erro: ' + msg });
  setData({ path: errorPathShow, value: 'true' });

  return 'error';
};

export const setCtDataError = (checkResponse: TjsonRes, callStatus: number) => {
  const checkCustomer1 = checkResponse?.errors;
  if (checkCustomer1)
    return setErrorTrue(
      Object.values(checkCustomer1)
        .map(i => i.join(' / '))
        .join(' / '),
    );

  const checkOrder1 = checkResponse?.message;
  if (checkOrder1) return setErrorTrue(checkOrder1);

  const checkOrder2 = checkResponse?.status === 'failed';
  if (checkOrder2) return setErrorTrue('Pagamento não autorizado!');
  // return setErrorTrue(checkResponse?.charges[0].last_transaction.status);

  // const checkOrder2 =
  //   checkResponse?.charges?.last_transaction?.gateway_response?.errors;
  // if (checkOrder2)
  //   return setErrorTrue(checkOrder2.map(i => i.message).join(' / '));

  // const checkOrder3 = checkResponse?.charges?.errors;
  // if (checkOrder3) return setErrorTrue(checkOrder3.join(' / '));

  // const checkOrder4 =
  //   checkResponse?.charges?.last_transaction?.gateway_response?.message;
  // if (checkOrder4) return setErrorTrue(checkOrder4);

  if (callStatus !== 200) return setErrorTrue('Não identificado!');

  return 'follow';
};

// export const setCtDataError1 = async (checkResponse: Tcheck) => {
//   console.log('Set Error Msg...');
//   // let condError = false;

//   if (checkResponse) {
//     const status = checkResponse?.status;

//     if (status === 400 || status === 422) {
//       // condError = true;
//       // console.log('setErr 1', { condError });

//       const statusText = checkResponse?.statusText;
//       // console.log('setErr', { statusText });

//       const resErr = statusText ? 'Error ' + status + ': ' + statusText : false;
//       console.log({ resErr });

//       // ------------------------------
//       // --------- UPDATE CT DATA ERROR
//       // ------------------------------
//       setData({ path: errorPathTxt, value: resErr ?? txtErrorDefault1 });
//       setData({ path: errorPathShow, value: 'true' });
//       return;
//     }

//     if (status >= 401) {
//     }

//     // return;
//   }
// };
