import { pgrme } from './secrets';

// ::urls
export const proxy = 'https://proxycall-gj5n6pnsva-uc.a.run.app?url=';
export const baseUrl = proxy + 'https://api.pagar.me/core/v5';

// ::vars
// :::: options :::: (default) || dev1 || dev2
const currEnv = '(default)';

// **************************************
// ******** DATABASE FIRESTORE **********
// **************************************

export const firestoreId = currEnv;
// OBS.: devs possuem acesso apenas de leitura ao firestore

// **************************************
// **************************************

// ----------------------------
// -- SITEs FIRESTORE (hosting)
// ----------------------------
export const sites = {
  // --- Produção
  '(default)': {
    app: 'app-goodbike',
    adm: 'goodbike-cd338',
  },
  // --- Testes
  dev1: 'dev1-goodbike', // --- adm & app (deploy unificado)
  dev2: 'dev2-goodbike', // --- adm & app (deploy unificado)
};

// ----------------------
// ------------- PAGAR.ME
// ----------------------
export const secrets = { ...pgrme[currEnv] };
console.log('SECRETS :::::::::::::::::', { secrets });
console.log('SECRETS :::::::::::::::::', { currEnv });
console.log('SECRETS :::::::::::::::::', { firestoreId });
