import { useData } from '../../..';
import { mapElements, pathSel } from '../../project';

type Tprops = {
  pass: {
    ipt1: any[];
    ipt2: any[];
    ipt3: any[];
  };
  args?: any;
};

export const CondFormCard = (props: Tprops) => {
  // ---------- set Props
  const { ipt1, ipt2, ipt3 } = props.pass;

  // const tokenCard = useData(ct => !!ct?.all?.userData?.paymentData?.id);
  console.log({ ipt1 });
  const condShowComps = useData(ct => {
    const selectPath = !!pathSel(ct, ipt1.join());
    console.log({ selectPath });
    return selectPath;
  });

  console.log({ condShowComps });
  const children1 = mapElements(ipt2, props.args);
  const children2 = mapElements(ipt3, props.args);

  if (condShowComps) return <>{children1}</>;
  if (!condShowComps) return <>{children2}</>;
};
