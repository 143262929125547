// file: X4_Fernandes.tsx

// ---------- import Local Tools
import { mapElements } from '../tools/project/mapElements';
import * as functions from '../tools/functions';
import * as Elements from '../tools/Elements';
import * as customs from '../tools/customs';
import { Project } from '../tools/project/';
import * as jsvals from '../tools/jsvals';
import { props } from '../tools/props';
import * as stls from '../tools/stls';
import { tools } from '../tools';

// ---------- set Temp Dev DataBase
import * as devDB from '../devDB';
import { mock } from '../devDB';

export const screen = (...args: any) => (
  <Elements.Screen3
    pass={{
      pathScreen: 'fernandes',

      styles: [
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.flex({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '1px',
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.deepPurple',
                    },
                  }),
                },
              }),
            ],
          },
        }),
      ],

      screenElements: [
        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.flexDirection({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'row',
                        },
                      }),
                    ],
                  },
                }),
                stls.justifyContent({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'space-between',
                        },
                      }),
                    ],
                  },
                }),
                stls.backgroundColor({
                  pass: {
                    arrayValue: [
                      jsvals.varReader({
                        pass: {
                          path: jsvals.j8({
                            pass: {
                              propertieValues: 'all.colors.white',
                            },
                          }),
                        },
                      }),
                    ],
                  },
                }),
                stls.padding({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '10px',
                        },
                      }),
                    ],
                  },
                }),
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [
                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.alignItems({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'center',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.justifyContent({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'center',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.SvgView1
                            pass={{
                              componentSvg: (Svg: any, SvgObj: any) => {
                                const {
                                  Defs,
                                  Stop,
                                  Path,
                                  LinearGradient,
                                  G,
                                  Circle,
                                  Rect,
                                  Mask,
                                } = SvgObj;
                                return (props: any) => (
                                  <Svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={20}
                                    height={14}
                                    viewBox="0 0 20 14"
                                    {...props}
                                  >
                                    <Path
                                      fillRule="evenodd"
                                      d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                                      clipRule="evenodd"
                                      {...props}
                                    />
                                  </Svg>
                                );
                              },

                              svgOriginal: `
        <svg></svg>
      `,

                              altura: '10px',

                              largura: '10px',

                              preenchimento: ['transparent'],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.paddingHorizontal({
                          pass: { arrayValue: ['10px'] },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [
                                stls.color({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.grey',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.fontSize({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.textSizes.small',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              children: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'Formulário de',
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [
                                stls.fontSize({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.textSizes.superLarge',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              children: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'REGISTRO DE PERMISSÃO',
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.alignItems({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'center',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.justifyContent({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'center',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.SvgView1
                            pass={{
                              componentSvg: (Svg: any, SvgObj: any) => {
                                const {
                                  Defs,
                                  Stop,
                                  Path,
                                  LinearGradient,
                                  G,
                                  Circle,
                                  Rect,
                                  Mask,
                                } = SvgObj;
                                return (props: any) => (
                                  <Svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={20}
                                    height={14}
                                    viewBox="0 0 20 14"
                                    {...props}
                                  >
                                    <Path
                                      fillRule="evenodd"
                                      d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                                      clipRule="evenodd"
                                      {...props}
                                    />
                                  </Svg>
                                );
                              },

                              svgOriginal: `
        <svg></svg>
      `,

                              altura: '10px',

                              largura: '10px',

                              preenchimento: ['transparent'],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),
              ],

              args,
            }}
          />
        ),

        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                {
                  backgroundColor: 'white',
                  justifyContent: 'center',
                  minHeight: 22,
                  width: '100%',
                },
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [
                (...args: any) => (
                  <Elements.Text
                    pass={{
                      arrProps: [{}],

                      arrStyles: [{ color: 'black', fontSize: 12 }],

                      children: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'Nome Completo',
                          },
                        }),
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.IptTxt2
                    pass={{
                      propsArray: [{}],

                      stylesArray: [
                        {
                          color: 'black',
                          fontSize: 14,
                          // fontSize: '20px',<= #ATTENTION: Native ERROR! No string!
                        },
                      ],

                      funcsArray: [
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues:
                                      'sc.A3.iptsChanges.fullName',
                                  },
                                }),
                              ],
                              value: jsvals.argReader({
                                pass: {
                                  argPath: jsvals.j8({
                                    pass: {
                                      propertieValues: '#0',
                                    },
                                  }),
                                  args,
                                },
                              }),
                            },
                          }),
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.Text
                    pass={{
                      arrProps: [{}],

                      arrStyles: [{ color: 'black', fontSize: 12 }],

                      children: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'Email',
                          },
                        }),
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.IptTxt2
                    pass={{
                      propsArray: [{}],

                      stylesArray: [
                        {
                          color: 'black',
                          fontSize: 14,
                          // fontSize: '20px',<= #ATTENTION: Native ERROR! No string!
                        },
                      ],

                      funcsArray: [
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'sc.A3.iptsChanges.email',
                                  },
                                }),
                              ],
                              value: jsvals.argReader({
                                pass: {
                                  argPath: jsvals.j8({
                                    pass: {
                                      propertieValues: '#0',
                                    },
                                  }),
                                  args,
                                },
                              }),
                            },
                          }),
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.Text
                    pass={{
                      arrProps: [{}],

                      arrStyles: [{ color: 'black', fontSize: 12 }],

                      children: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'Senha',
                          },
                        }),
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.IptTxt2
                    pass={{
                      propsArray: [{}],

                      stylesArray: [
                        {
                          color: 'black',
                          fontSize: 14,
                          // fontSize: '20px',<= #ATTENTION: Native ERROR! No string!
                        },
                      ],

                      funcsArray: [
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues:
                                      'sc.A3.iptsChanges.password',
                                  },
                                }),
                              ],
                              value: jsvals.argReader({
                                pass: {
                                  argPath: jsvals.j8({
                                    pass: {
                                      propertieValues: '#0',
                                    },
                                  }),
                                  args,
                                },
                              }),
                            },
                          }),
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.Text
                    pass={{
                      arrProps: [{}],

                      arrStyles: [{ color: 'black', fontSize: 12 }],

                      children: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'Ponto de Retirada',
                          },
                        }),
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.IptTxt2
                    pass={{
                      propsArray: [{}],

                      stylesArray: [
                        {
                          color: 'black',
                          fontSize: 14,
                          // fontSize: '20px',<= #ATTENTION: Native ERROR! No string!
                        },
                      ],

                      funcsArray: [
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues:
                                      'sc.A3.iptsChanges.withdrawal',
                                  },
                                }),
                              ],
                              value: jsvals.argReader({
                                pass: {
                                  argPath: jsvals.j8({
                                    pass: {
                                      propertieValues: '#0',
                                    },
                                  }),
                                  args,
                                },
                              }),
                            },
                          }),
                      ],

                      args,
                    }}
                  />
                ),
              ],

              args,
            }}
          />
        ),
      ],

      startFunctions: [() => {}],

      args,
    }}
  />
);
