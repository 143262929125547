import { setArgsVars } from '../project';

// F4 - if function (fromDB_var)  (newBase)
type Tprops_F4 = {
  pass: {
    conditionPath: any;
    expectedValue: any;
    functionsTrue: any;
    functionsFalse: any;
    args: any;
  };
};
export const F4 =
  (props: Tprops_F4) =>
  // async (...args: any) => //#ATTENTION: Falar com Carlos
  async () => {
    const {
      conditionPath,
      expectedValue,
      functionsTrue,
      functionsFalse,
      args,
    } = props.pass;

    // ---------- set Capsules Inputs
    // const arrKeyPath = [!#!condition path!#!];
    // const expectedValue = !#!expected value!#!;
    // const trueFuncs = [!#!functions (case true)!#!];
    // const falseFuncs = [!#!functions (case false)!#!];
    // ---------- set Path Array with Arguments + Variables
    const newArrPath = conditionPath.map((item: any) =>
      setArgsVars(item, args),
    );

    // console.log({ newArrPath });
    // console.log({ newArrPath });
    // ---------- set Path Value as a single string
    let varPath = newArrPath.reduce((prev: any, curr: any) => prev + curr, '');

    // console.log({ varPath });
    // ---------- set Arguments / Vars Values (If Exists)
    const setValue = setArgsVars(varPath, args);
    // console.log({ setValue });
    // console.log({ B });
    const condIF = setValue === expectedValue;

    // console.log({ condIF });
    if (condIF) {
      for (const currFunc of functionsTrue) await currFunc(args);
    } else {
      for (const currFunc of functionsFalse) await currFunc(args);
    }
  };
