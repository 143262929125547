import React from 'react';
import { ViewStyle } from 'react-native';
import { regionFrom } from './regionFrom';
import { useData } from '../../..';
import { CustomMarkers } from './CustomMarkers';
import { getCtData } from '../../project';

type Tres =
  typeof import('/workspace/flaxboll/C_preview/node_modules/react-native-maps/lib/index');

type Tprops2 = {
  pass: {
    keyPlaces: string;
    pathInitMap: string[];
    arrPins: string[];
    arrFuncs: any[];
  };
};

type Tprops = {
  res: Tres;
  props: Tprops2;
};

export const importRender = ({ res, props }: Tprops) => {
  const { pathInitMap, arrPins } = props?.pass;
  console.log({ pathInitMap });
  console.log({ arrPins });

  const currInitMap = getCtData(pathInitMap.join());
  console.warn({ currInitMap });

  if (!currInitMap?.coords)
    return console.log('Erro em: currInitMap?.coords', currInitMap?.coords);

  const { latitude, longitude } = currInitMap?.coords;
  const stlAllArea: ViewStyle = { width: '100%', height: '100%' };
  console.warn({ props });

  const initArea = {
    latitude,
    longitude,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  };
  const MapView = res.default;

  return () => {
    return (
      <MapView
        style={stlAllArea}
        initialRegion={initArea}
        showsTraffic={false}
        showsUserLocation={false}
        showsMyLocationButton={false}
      >
        <CustomMarkers pass={props.pass} />
      </MapView>
    );
  };
};
