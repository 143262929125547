import { setVar } from '../../functions';
import { where } from '../../functions/firebase/where';
import { argReader } from '../../jsvals';
import { getCtData, setData } from '../../project';

type Tprops = { args: any; pass: {} };

export const listRents = async (props: Tprops) => {
  console.log('Listando Reservas...');

  // ---------- EndPoint
  //   const customerId = getCtData('all.userData.customerId');
  const selectedUser = getCtData('all.userData.docId');
  const fireInit = getCtData('all.fireInit');

  // ------ CALL FB
  where({
    args: {},
    pass: {
      fbInit: [fireInit],
      arrRefStrings: ['rents'],
      arrWhere: [
        () => ({ field: 'userId', operator: '==', value: selectedUser }),
      ],
      arrFuncs: [
        (...args) => {
          const listArr = argReader({ pass: { argPath: '#0', args } });
          console.log({ listArr });
          const newArray = listArr.map(i => {
            const date = i?.createdAt.toDate();
            const formattedDate = `${date
              .getDate()
              .toString()
              .padStart(2, '0')}/${(date.getMonth() + 1)
              .toString()
              .padStart(2, '0')}/${date.getFullYear()}`;

            return {
              totalPrice: i?.totalPrice,
              createdAt: formattedDate,
              bikesList: i?.bikesList,
            };
          });
          setVar({
            args,
            pass: {
              keyPath: ['sc.B4C.lists.mainList'],
              value: newArray,
            },
          });
        },
      ],
    },
  });
};
