import { getCtData, setData } from '../../project';

type Tprops = {
  pass: {
    ipt1: any[]; // path
    ipt2: string; // field
    ipt3: any[]; // compare
    ipt4: any[]; // funcs
  };
  args?: any;
};

export const custom03 = (props: Tprops) => {
  console.log('Chegou no custom 03?');

  const { args } = props;
  const { ipt1, ipt2, ipt3, ipt4 } = props.pass;

  console.log({ args });
  console.log({ ipt1 });
  console.log({ ipt2 });
  console.log({ ipt3 });
  console.log({ ipt4 });
  // const arrCond = ipt2.split(',');

  const arrItems = getCtData(ipt1.join());
  console.log({ arrItems });
  const isArray = Array.isArray(arrItems);
  const compareValue = ipt3.join();
  const foundValue =
    isArray &&
    arrItems.find(i => {
      const match = i[ipt2] === compareValue;
      console.log({ i });
      console.log({ ipt2 });
      console.log({ compareValue });
      return match;
    });

  console.log({ foundValue });

  // const arrFuncs = ipt3;
  if (foundValue) {
    for (const currFunc of ipt4) currFunc(args, foundValue);
  }
  // return foundValue[ipt2];
};
