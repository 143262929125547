// file: index.tsx

// ---------- import React Packs
import React from 'react';
import * as RN from 'react-native';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';

// ---------- import Variables Pack
import { create } from 'zustand';

// ---------- import Local Tools
import { mapElements } from './tools/project/mapElements';
import * as functions from './tools/functions';
import * as Elements from './tools/Elements';
import * as customs from './tools/customs';
import { Project, goTo, setData } from './tools/project/';
import * as jsvals from './tools/jsvals';
import * as stls from './tools/stls';
import { tools } from './tools';
import * as sc from './screens';

// ---------- set Temp Dev DataBase
import { firestoreId } from './globalVars/data';
import { devVars } from './devDB';
import { mock } from './devDB';

// ---------- set Caps Inputs
const currRoute = 'a1login';

let args: any = [];

const screens = [
  sc.A0_1_Biblioteca,
  // sc.A0_2_Biblioteca,
  sc.A0_Escolha,
  sc.A1_Login,
  sc.A2_Permissions,
  sc.A4_Users,
  sc.A6_Locations,
  sc.A8_Models,
  sc.A10_Bicycles,
  sc.A12_Retiradas,

  sc.A13_Retiradas,
  sc.B1_Login,
  sc.B2_Register,
  sc.B3A_Map,
  sc.B3B_BikeList,

  sc.B3C_Checkout,
  sc.B4A_Wallet,
  sc.B4B_Pix,
  sc.B4C_CardHistory,
  sc.B5_Timer,

  sc.B7_MyProfile,
  sc.B8_BlockScreen,
  sc.X1_Carlos,
  sc.X2_Juan,
  sc.X3_Renan,
  sc.X4_Fernandes,
];

const initCt = () => ({
  true: jsvals.j8({
    pass: {
      propertieValues: 'true',
    },
  }),
  all: jsvals.j8({
    pass: {
      propertieValues: {
        firebaseConfig: jsvals.j8({
          pass: {
            propertieValues: {
              apiKey: jsvals.j8({
                pass: {
                  propertieValues: 'AIzaSyBJ1MNdqlCOmPXK3taPtmuEJbsrmtiUDxU',
                },
              }),
              authDomain: jsvals.j8({
                pass: {
                  propertieValues: 'goodbike-cd338.firebaseapp.com',
                },
              }),
              projectId: jsvals.j8({
                pass: {
                  propertieValues: 'goodbike-cd338',
                },
              }),
              storageBucket: jsvals.j8({
                pass: {
                  propertieValues: 'goodbike-cd338.appspot.com',
                },
              }),
              messagingSenderId: jsvals.j8({
                pass: {
                  propertieValues: '177294326394',
                },
              }),
              appId: jsvals.j8({
                pass: {
                  propertieValues: '1:177294326394:web:00671a5c16846c33e499c3',
                },
              }),
            },
          },
        }),
        colors: jsvals.j8({
          pass: {
            propertieValues: {
              red: jsvals.j8({
                pass: {
                  propertieValues: 'red',
                },
              }),
              dark: jsvals.j8({
                pass: {
                  propertieValues: 'black',
                },
              }),
              white: jsvals.j8({
                pass: {
                  propertieValues: 'white',
                },
              }),
              ice: jsvals.j8({
                pass: {
                  propertieValues: '#f5f5f5',
                },
              }),
              deepPurple: jsvals.j8({
                pass: {
                  propertieValues: '#7C2355',
                },
              }),
              grey: jsvals.j8({
                pass: {
                  propertieValues: '#999999',
                },
              }),
              primary: jsvals.j8({
                pass: {
                  propertieValues: '#7C2355',
                },
              }),
              grey2: jsvals.j8({
                pass: {
                  propertieValues: '#333333',
                },
              }),
              secondary: jsvals.j8({
                pass: {
                  propertieValues: '#B2D143',
                },
              }),
            },
          },
        }),
        textSizes: jsvals.j8({
          pass: {
            propertieValues: {
              small: jsvals.j8({
                pass: {
                  propertieValues: '12px',
                },
              }),
              medium: jsvals.j8({
                pass: {
                  propertieValues: '14px',
                },
              }),
              large: jsvals.j8({
                pass: {
                  propertieValues: '16px',
                },
              }),
              superLarge: jsvals.j8({
                pass: {
                  propertieValues: '18px',
                },
              }),
              smaller: jsvals.j8({
                pass: {
                  propertieValues: '10px',
                },
              }),
            },
          },
        }),
        user: jsvals.j8({
          pass: {
            propertieValues: {
              userImage: jsvals.j8({
                pass: {
                  propertieValues:
                    'https://lh3.googleusercontent.com/e6PBGAIgp4UBlNKZYqXl0LU1hM_j2YHgY-aJDHzvTMe0R7_dJ9WkwKYIymQNHlyQqhHgM0hY2cx8G7rXrYug6KFWAfEbFuvo6aI2he2HjTdok_4O87r0C4mGeAj3=e365-rj-l80-w260',
                },
              }),
              userName: jsvals.j8({
                pass: {
                  propertieValues: 'Paulo Antônio',
                },
              }),
              userEmail: jsvals.j8({
                pass: {
                  propertieValues: 'pauloantonio@gmail.com',
                },
              }),
            },
          },
        }),
        toggles: jsvals.j8({
          pass: {
            propertieValues: {
              sideMenu: jsvals.j8({
                pass: {
                  propertieValues: 'hide',
                },
              }),
              activeForm: jsvals.j8({
                pass: {
                  propertieValues: 'none',
                },
              }),
              rightOptions: jsvals.j8({
                pass: {
                  propertieValues: 'inactive',
                },
              }),
              radioButton: jsvals.j8({
                pass: {
                  propertieValues: 'unselected',
                },
              }),
              creditCard: jsvals.j8({
                pass: {
                  propertieValues: 'close',
                },
              }),
              pix: jsvals.j8({
                pass: {
                  propertieValues: 'close',
                },
              }),
              sideLeftMenu: jsvals.j8({
                pass: {
                  propertieValues: {
                    activeButton: jsvals.j8({
                      pass: {
                        propertieValues: {
                          myProfile: jsvals.j8({
                            pass: {
                              propertieValues: 'inactive',
                            },
                          }),
                          permissions: jsvals.j8({
                            pass: {
                              propertieValues: 'inactive',
                            },
                          }),
                          models: jsvals.j8({
                            pass: {
                              propertieValues: 'inactive',
                            },
                          }),
                          userList: jsvals.j8({
                            pass: {
                              propertieValues: 'inactive',
                            },
                          }),
                          locations: jsvals.j8({
                            pass: {
                              propertieValues: 'inactive',
                            },
                          }),
                          rents: jsvals.j8({
                            pass: {
                              propertieValues: 'inactive',
                            },
                          }),
                        },
                      },
                    }),
                  },
                },
              }),
              emptyField: jsvals.j8({
                pass: {
                  propertieValues: 'no',
                },
              }),
              radioCreditCard: jsvals.j8({
                pass: {
                  propertieValues: 'unselected',
                },
              }),
            },
          },
        }),
      },
    },
  }),
  sc: jsvals.j8({
    pass: {
      propertieValues: {
        A1: jsvals.j8({
          pass: {
            propertieValues: {
              titleName: jsvals.j8({
                pass: {
                  propertieValues: 'Aluguel de Bicicletas',
                },
              }),
              login: jsvals.j8({
                pass: {
                  propertieValues: 'LOGIN',
                },
              }),
              password: jsvals.j8({
                pass: {
                  propertieValues: 'SENHA',
                },
              }),
              loginUsing: jsvals.j8({
                pass: {
                  propertieValues: 'Entrar usando sua conta',
                },
              }),
              confirm: jsvals.j8({
                pass: {
                  propertieValues: 'Confirmar',
                },
              }),
              mockID: jsvals.j8({
                pass: {
                  propertieValues: 'AduXSCnqEOjhwPeplNnQ',
                },
              }),
              toggles: jsvals.j8({
                pass: {
                  propertieValues: {
                    msgError: jsvals.j8({
                      pass: {
                        propertieValues: 'no value',
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
        A2: jsvals.j8({
          pass: {
            propertieValues: {
              state: jsvals.j8({
                pass: {
                  propertieValues: 'inactive',
                },
              }),
              mainList: jsvals.j8({
                pass: {
                  propertieValues: {
                    show: jsvals.j8({
                      pass: {
                        propertieValues: 'false',
                      },
                    }),
                    'no name': jsvals.j8({
                      pass: {
                        propertieValues: [],
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
        A3: jsvals.j8({
          pass: {
            propertieValues: {
              state: jsvals.j8({
                pass: {
                  propertieValues: 'inactive',
                },
              }),
              forms: jsvals.j8({
                pass: {
                  propertieValues: {
                    iptsChanges: jsvals.j8({
                      pass: {
                        propertieValues: {},
                      },
                    }),
                    editData: jsvals.j8({
                      pass: {
                        propertieValues: {},
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
        A4: jsvals.j8({
          pass: {
            propertieValues: {
              state: jsvals.j8({
                pass: {
                  propertieValues: 'inactive',
                },
              }),
              mainList: jsvals.j8({
                pass: {
                  propertieValues: {
                    show: jsvals.j8({
                      pass: {
                        propertieValues: 'false',
                      },
                    }),
                    'no name': jsvals.j8({
                      pass: {
                        propertieValues: [],
                      },
                    }),
                  },
                },
              }),
              cleanSearch: jsvals.j8({
                pass: {
                  propertieValues: 'hidden',
                },
              }),
            },
          },
        }),
        A5: jsvals.j8({
          pass: {
            propertieValues: {
              state: jsvals.j8({
                pass: {
                  propertieValues: 'inactive',
                },
              }),
              forms: jsvals.j8({
                pass: {
                  propertieValues: {
                    iptsChanges: jsvals.j8({
                      pass: {
                        propertieValues: {},
                      },
                    }),
                    editData: jsvals.j8({
                      pass: {
                        propertieValues: {},
                      },
                    }),
                  },
                },
              }),
              male: jsvals.j8({
                pass: {
                  propertieValues: 'Masculino',
                },
              }),
              female: jsvals.j8({
                pass: {
                  propertieValues: 'Feminino',
                },
              }),
            },
          },
        }),
        A6: jsvals.j8({
          pass: {
            propertieValues: {
              currData: jsvals.j8({
                pass: {
                  propertieValues: {
                    locationsList: jsvals.j8({
                      pass: {
                        propertieValues: [],
                      },
                    }),
                  },
                },
              }),
              lists: jsvals.j8({
                pass: {
                  propertieValues: {
                    mainList: jsvals.j8({
                      pass: {
                        propertieValues: [],
                      },
                    }),
                  },
                },
              }),
              toggles: jsvals.j8({
                pass: {
                  propertieValues: {
                    show: jsvals.j8({
                      pass: {
                        propertieValues: 'false',
                      },
                    }),
                  },
                },
              }),
              mainList: jsvals.j8({
                pass: {
                  propertieValues: {
                    show: jsvals.j8({
                      pass: {
                        propertieValues: 'false',
                      },
                    }),
                    'no name': jsvals.j8({
                      pass: {
                        propertieValues: [],
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
        A7: jsvals.j8({
          pass: {
            propertieValues: {
              state: jsvals.j8({
                pass: {
                  propertieValues: 'inactive',
                },
              }),
              forms: jsvals.j8({
                pass: {
                  propertieValues: {
                    iptsChanges: jsvals.j8({
                      pass: {
                        propertieValues: {},
                      },
                    }),
                    editData: jsvals.j8({
                      pass: {
                        propertieValues: {},
                      },
                    }),
                  },
                },
              }),
              toggles: jsvals.j8({
                pass: {
                  propertieValues: {
                    btnEdit: jsvals.j8({
                      pass: {
                        propertieValues: 'hide',
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
        A8: jsvals.j8({
          pass: {
            propertieValues: {
              state: jsvals.j8({
                pass: {
                  propertieValues: 'inactive',
                },
              }),
              mainList: jsvals.j8({
                pass: {
                  propertieValues: {
                    show: jsvals.j8({
                      pass: {
                        propertieValues: 'false',
                      },
                    }),
                    'no name': jsvals.j8({
                      pass: {
                        propertieValues: [],
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
        A9: jsvals.j8({
          pass: {
            propertieValues: {
              state: jsvals.j8({
                pass: {
                  propertieValues: 'inactive',
                },
              }),
              forms: jsvals.j8({
                pass: {
                  propertieValues: {
                    iptsChanges: jsvals.j8({
                      pass: {
                        propertieValues: {},
                      },
                    }),
                    editData: jsvals.j8({
                      pass: {
                        propertieValues: {},
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
        A10: jsvals.j8({
          pass: {
            propertieValues: {
              state: jsvals.j8({
                pass: {
                  propertieValues: 'inactive',
                },
              }),
              mainList: jsvals.j8({
                pass: {
                  propertieValues: {
                    show: jsvals.j8({
                      pass: {
                        propertieValues: 'false',
                      },
                    }),
                    'no name': jsvals.j8({
                      pass: {
                        propertieValues: [],
                      },
                    }),
                  },
                },
              }),
              toggles: jsvals.j8({
                pass: {
                  propertieValues: {
                    sideRight: jsvals.j8({
                      pass: {
                        propertieValues: {
                          show: jsvals.j8({
                            pass: {
                              propertieValues: 'false',
                            },
                          }),
                        },
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
        A11: jsvals.j8({
          pass: {
            propertieValues: {
              state: jsvals.j8({
                pass: {
                  propertieValues: 'inactive',
                },
              }),
              forms: jsvals.j8({
                pass: {
                  propertieValues: {
                    iptsChanges: jsvals.j8({
                      pass: {
                        propertieValues: {},
                      },
                    }),
                    editData: jsvals.j8({
                      pass: {
                        propertieValues: {},
                      },
                    }),
                  },
                },
              }),
              currData: jsvals.j8({
                pass: {
                  propertieValues: {
                    itemID: jsvals.j8({
                      pass: {
                        propertieValues: 'null',
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
        A12: jsvals.j8({
          pass: {
            propertieValues: {
              state: jsvals.j8({
                pass: {
                  propertieValues: 'inactive',
                },
              }),
              mainList: jsvals.j8({
                pass: {
                  propertieValues: {
                    show: jsvals.j8({
                      pass: {
                        propertieValues: 'false',
                      },
                    }),
                    'no name': jsvals.j8({
                      pass: {
                        propertieValues: [],
                      },
                    }),
                  },
                },
              }),
              locationDocID: jsvals.j8({
                pass: {
                  propertieValues: 'no value',
                },
              }),
            },
          },
        }),
        A13: jsvals.j8({
          pass: {
            propertieValues: {
              state: jsvals.j8({
                pass: {
                  propertieValues: 'inactive',
                },
              }),
              mainList: jsvals.j8({
                pass: {
                  propertieValues: {
                    show: jsvals.j8({
                      pass: {
                        propertieValues: 'false',
                      },
                    }),
                    'no name': jsvals.j8({
                      pass: {
                        propertieValues: [],
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
        A14: jsvals.j8({
          pass: {
            propertieValues: {
              male: jsvals.j8({
                pass: {
                  propertieValues: 'Masculino',
                },
              }),
              female: jsvals.j8({
                pass: {
                  propertieValues: 'Feminino',
                },
              }),
            },
          },
        }),
        B2: jsvals.j8({
          pass: {
            propertieValues: {
              forms: jsvals.j8({
                pass: {
                  propertieValues: {
                    all: jsvals.j8({
                      pass: {
                        propertieValues: {
                          userData: jsvals.j8({
                            pass: {
                              propertieValues: {},
                            },
                          }),
                        },
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
        B3A: jsvals.j8({
          pass: {
            propertieValues: {
              state: jsvals.j8({
                pass: {
                  propertieValues: 'inactive',
                },
              }),
              currData: jsvals.j8({
                pass: {
                  propertieValues: {
                    mainList: jsvals.j8({
                      pass: {
                        propertieValues: [],
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
        B3B: jsvals.j8({
          pass: {
            propertieValues: {
              disabled: jsvals.j8({
                pass: {
                  propertieValues: 'false',
                },
              }),
            },
          },
        }),
        B4: jsvals.j8({
          pass: {
            propertieValues: {
              totalPurchase: jsvals.j8({
                pass: {
                  propertieValues: 'Total da Compra',
                },
              }),
              totalMoney: jsvals.j8({
                pass: {
                  propertieValues: 'R$40,00',
                },
              }),
              txtCielo: jsvals.j8({
                pass: {
                  propertieValues: 'Ambiente seguro por',
                },
              }),
            },
          },
        }),
        B5: jsvals.j8({
          pass: {
            propertieValues: {
              toggles: jsvals.j8({
                pass: {
                  propertieValues: {
                    timerRunning: jsvals.j8({
                      pass: {
                        propertieValues: 'false',
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
        B7: jsvals.j8({
          pass: {
            propertieValues: {
              male: jsvals.j8({
                pass: {
                  propertieValues: 'Masculino',
                },
              }),
              female: jsvals.j8({
                pass: {
                  propertieValues: 'Feminino',
                },
              }),
            },
          },
        }),
        B3C: jsvals.j8({
          pass: {
            propertieValues: {
              currData: jsvals.j8({
                pass: {
                  propertieValues: {
                    currPrice: jsvals.j8({
                      pass: {
                        propertieValues: '0',
                      },
                    }),
                    selecHours: jsvals.j8({
                      pass: {
                        propertieValues: '1',
                      },
                    }),
                  },
                },
              }),
            },
          },
        }),
      },
    },
  }),
  banner: jsvals.j8({
    pass: {
      propertieValues:
        'https://firebasestorage.googleapis.com/v0/b/flaxboll.appspot.com/o/banner_bg.png?alt=media&token=d1360df7-11c7-4172-89b7-ec8765061b1e',
    },
  }),
});

const initObj = initCt();

const arrInitFuncs = [
  async (...args) =>
    functions.firebase.fireInit({
      args,
      pass: {
        fbConfig: jsvals.varReader({
          pass: {
            path: jsvals.j8({
              pass: {
                propertieValues: 'all.firebaseConfig',
              },
            }),
          },
        }),
        arrFuncs: [
          async (...args) =>
            functions.setVar({
              args,
              pass: {
                keyPath: [
                  jsvals.j8({
                    pass: {
                      propertieValues: 'all.fireInit',
                    },
                  }),
                ],
                value: jsvals.argReader({
                  pass: {
                    argPath: jsvals.j8({
                      pass: {
                        propertieValues: '#0',
                      },
                    }),
                    args,
                  },
                }),
              },
            }),
        ],
      },
    }),
];

const isObject = item => {
  return item && typeof item === 'object' && !Array.isArray(item);
};

const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

const newMergedObj = mergeDeep(initObj, devVars);
export const useRoutes = create(() => ({ currRoute }));
export const useData = create(() => newMergedObj);
console.log({ newMergedObj });

// ---------- set Main Component
export const Router = () => {
  const typeAccount = useData(ct => ct?.all?.userData?.typeAccount);
  const userId = useData(ct => ct?.all?.userData?.docId);
  const fbInit = useData(ct => ct?.all?.fireInit);

  if (userId) {
    const isAdm = typeAccount === 'adm';
    const isLoc = typeAccount === 'loc';

    const condAdm = isAdm || isLoc;
    const condCollection = condAdm ? 'permissions' : 'users';

    const fireInit = getFirestore(fbInit, firestoreId);
    const refColl = doc(fireInit, condCollection, userId);

    // ---------- set Users Found if any
    onSnapshot(refColl, success => {
      let Doc: any;
      if (success.exists()) Doc = success.data();

      if (!!Doc) {
        setData({ path: 'all.userData', value: Doc });
      }
    });
  }

  return (
    <Project
      configData={{
        screens,
        initCt,
        arrInitFuncs,
      }}
    />
  );
};
