import { getCtData, setData } from '../../project';

type Tprops = {
  pass: { value: any[] };
  args?: any;
};

export const currencyString = (props: Tprops) => {
  const { value } = props.pass;

  // console.log({ value });
  // const formatter = new Intl.NumberFormat('pt-BR', {
  //   style: 'currency',
  //   currency: 'BRL',
  //   minimumFractionDigits: 2, // Define o número mínimo de casas decimais
  // });

  const xxx = value.join();
  // console.log({ xxx });
  const yyy = xxx.replace(/[^0-9]/g, ''); // remove tudo que não é número
  // console.log({ yyy });
  const numValue = Number(yyy);
  // console.log({ numValue });

  // const formatedValue1 = formatter.format(numValue);
  // console.log({ formatedValue1 });

  // const formatedValue2 = `R$ ${numValue.toFixed(2)}`;

  // console.log({ formatedValue1 });
  // console.log({ formatedValue2 });
  // const returnVarValue = getCtData(newPath);
  // console.log('%cvarReader', css2, { varPath: newPath, value: returnVarValue });

  // Converta 156398 centavos para reais
  const centavos = numValue;
  const reais = centavos / 100;

  // Formate o resultado como moeda
  const formattedReais = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(reais);

  // return formatedValue2;
  return formattedReais;
};
