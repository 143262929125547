// F9 - setThumbnail
type Tprops_F9 = { pass: { A: any[] } };
export const F9 =
  (props: Tprops_F9) =>
  async (...args: any) => {
    const { A } = props.pass;

    console.warn('SEt Thumbnail');

    for (const currFunc of A) await currFunc('', args);
  };
