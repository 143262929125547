import { baseUrl, secrets } from '../../../globalVars/data';
import { asyncFn } from './asyncFn';

import { getOrderData } from './helpers/getOrderData';
import { argSel, getCtData, goTo, setData } from '../../project';

import { createCustomer } from './createCustomer';
import { getCardHash } from './getCardHash';
import { errFnc } from '.';
import { setCtDataError } from './helpers/setCtDataError';
import { setDoc } from '../../functions/firebase/setDoc';
import { updateDoc } from '../../functions/firebase/updateDoc';

type Tprops = {
  args: any;
  pass: {
    ipt1: any[];
    ipt2: string;
    ipt3: any[];
  };
};

// --- Errors path
// all.apis.msgs.condShow
// all.apis.msgs.error

export const createOrder = async (props: Tprops) => {
  const customerId = getCtData('all.userData.customerId');
  console.log('pagarme', 'Gerando Pag. C.Crédito...', { customerId });
  setData({ path: 'all.toggles.loaderPurchase', value: 'show' });

  // -----------------------------------------------------
  // 1 ||||| --------- Check IF is A New Pagar.Me Customer
  // -----------------------------------------------------
  if (!customerId) {
    console.log('pagarme', 'New Customer');
    // const resCustomer = await createCustomer().catch(errFnc);
    const resCus = await createCustomer().catch(errFnc);
    console.log({ resCus });
    if (resCus !== 'follow') return;
  }

  // ------------------------------------------------------------------
  // 2 ||||| --------- Check IF is FIRST PURCHASE - Create Credit Card
  // ------------------------------------------------------------------
  const cardId = getCtData('all.userData.paymentData.id');
  const firstPurchase = !cardId;

  if (firstPurchase) {
    console.log('pagarme', 'First Purchase (Create Token)');
    const resCrdHash = await getCardHash();
    if (resCrdHash !== 'follow') return;

    setData({ path: 'all.userData.paymentData', value: resCrdHash });
  }

  // -------------------------------------------------------------------
  // 3 ||||| --------- Check IF MethodPay & Customer OK, Then Send Order
  // -------------------------------------------------------------------
  // ---------- Get CtData
  const processedData = getOrderData('ccard');

  // ---------- EndPoint
  const url = baseUrl + '/orders';

  // ---------- Headers
  const myHeaders = new Headers();
  myHeaders.append('accept', 'application/json');
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Basic ${secrets.sk}`);

  console.log('>>>>>>>>>>>>>>>>>>', { processedData });

  // ---------- Body
  const raw = JSON.stringify(processedData);

  // ---------- Request Config
  const reqConfig = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  // ---------------------------
  // ----------- SET CALL API --
  // ---------------------------
  const callRequest = await asyncFn(url, reqConfig).catch(errFnc);
  const res = await callRequest.json();
  const condStop = setCtDataError(res, callRequest.status);
  if (condStop !== 'follow') return;
  // setCtDataError(callRequest);

  // ---------------------------
  // ----- check IF ERROR / STOP
  // ---------------------------
  const condShow = getCtData('all.apis.msgs.condShow');
  const condError = condShow === 'true';

  if (condError)
    return console.log(
      'Depois do Fetch - createOrder - PROMISE',
      condError,
      callRequest,
    );

  // ----------------------
  // ----- check IF SUCCESS
  // ----------------------
  if (!condError) {
    const css1 =
      'color: #7c0046; background-color: #99df00; font-size: 11px; font-weight: bold; padding: 2px 6px; border-radius: 3px';
    // const res = await callRequest.json();
    setData({ path: 'sc.B4.currData.checkout.paymentData', value: res });

    // ------------------------------------
    // ------ CREATE NEW RENT DOCUMENT ----
    // -------------------------------------
    const fireInit = getCtData('all.fireInit');
    const passProps1 = {
      fbInit: [fireInit],
      arrRefStrings: ['rents'],
      arrPathData: ['sc.B4.currData.checkout'],
      arrFuncs: [
        async (...args) => {
          // ----------------------------------
          // ------ UPDATE USER WITH TOKEN ----
          // ----------------------------------
          const dataToUpdate = {
            ['activeRentData.rentId']: argSel(args, '#0.docId'),
          };
          setData({ path: 'all.tempData', value: dataToUpdate });

          // ----------------------------------
          // ---------- set Firestore TimerData
          // ----------------------------------
          const selectedUser = getCtData('all.userData.docId');
          const fireInit = getCtData('all.fireInit');
          const passProps2 = {
            fbInit: [fireInit],
            arrRefStrings: ['users', selectedUser],
            arrPathData: ['all.tempData'],
            arrFuncs: [
              () => {
                setData({ path: 'all.toggles.loaderPurchase', value: null });
              },
              () => {
                goTo('b5timer');
              },
            ],
          };

          await updateDoc({ args, pass: passProps2 });

          // clean temp data
          setData({ path: 'all.tempData', value: null });
        },
      ],
    };

    await setDoc({ args: {}, pass: passProps1 });

    console.log('%ccreateOrder - SUCCESS!!!', css1, res);

    return res;
  }
};
