export const pgrme = {
  // ------ Chaves de Produção
  '(default)': {
    // sk_1be56b02eb084c3d91a67b0ff56a86e5
    customerId: 'acc_GqpzEywI20CbzWj8',
    pk: 'pk_DJmXY36h1QfLkQMj',

    sk: 'c2tfMWJlNTZiMDJlYjA4NGMzZDkxYTY3YjBmZjU2YTg2ZTU6', // Cripto
  },

  // ------ Chaves de Testes
  // sk_test_b9f86f33b025497e9006e0d487038965
  dev1: {
    customerId: 'acc_X3kDz1qf4PfJZ5A0',
    pk: 'pk_test_poGE4AniPuAAgmqd',

    sk: 'c2tfdGVzdF9iOWY4NmYzM2IwMjU0OTdlOTAwNmUwZDQ4NzAzODk2NTo=',
  },
  dev2: {
    customerId: 'acc_X3kDz1qf4PfJZ5A0',
    pk: 'pk_test_poGE4AniPuAAgmqd',

    sk: 'c2tfdGVzdF9iOWY4NmYzM2IwMjU0OTdlOTAwNmUwZDQ4NzAzODk2NTo=',
  },
};
