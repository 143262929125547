import { firestoreId } from '../../../globalVars/data';
import { getDocs } from '../../functions/firebase/getDocs';
import { where } from '../../functions/firebase/where';
import { whereConds } from '../../functions/firebase/whereConds';
import { getCtData, setData } from '../../project';

type Tprops = {
  pass: {
    ipt1: any[];
    ipt2: string;
    ipt3: any[];
  };
  args?: any;
};

export const pikerAllBikes = async (props: Tprops) => {
  const { ipt1, ipt2 } = props.pass;

  // ---------- set Local Imports
  const { documentId } = await import('@firebase/firestore');
  //   const fireInit: any = getFirestore(fbInit[0]);

  const fbInit = getCtData('all.fireInit');
  const arrData =
    props?.args[0].map(x => {
      console.log({ x });
      // return x?.modelId;
      return x?.docId;
    }) ?? [];
  console.log({ fbInit });
  console.log('ALL BIKES %1', { props });
  console.log({ arrData });
  console.log({ ipt2 });

  if (arrData.length > 0) {
    console.log('custom mergeBikes GET DOCS');
    const { getFirestore, getDocs, collection, onSnapshot } = await import(
      '@firebase/firestore'
    );

    // -----------------------------
    // ---------- set Firestore Call
    // -----------------------------
    // console.log('xxxxddd', { fbInit });
    const fireInit = getFirestore(fbInit, firestoreId);
    const refColl = collection(fireInit, 'bikeModels');
    const unsub = onSnapshot(refColl, success => {
      const arrDocs = [];
      success.forEach((doc: any) => {
        arrDocs.push(doc.data());
      });

      // ---------- set Get Value Functions
      console.log('Picker AllModelBikes', { arrDocs });

      const newArray = [];

      for (const key in arrDocs) {
        const item = arrDocs[key];

        newArray.push({ label: item.modelName, id: item.docId });
      }

      // const pathPicker = ipt1?.join() ?? '';
      const pathPicker = 'sc.A10.currData.allBikes';
      setData({ path: pathPicker, value: newArray });
      console.log('Picker AllModelBikes', { pathPicker, newArray });
    });
  }
};
