import MapView, { Marker } from 'react-native-maps';
import { useData } from '../../..';
import { pathSel } from '../../project';
import { Image } from 'react-native';
const srcPin = require('../../../../assets/pin.png');

type Tprops = {
  pass: {
    keyPlaces: string;
    pathInitMap: string[];
    arrPins: string[];
    arrFuncs: any[];
  };
  args?: any;
};

export const CustomMarkers = (props: Tprops) => {
  const { arrPins, arrFuncs } = props.pass;

  const arrMarkers = useData(ct => pathSel(ct, arrPins.join()));

  console.log({ arrPins });
  console.log({ arrMarkers1: arrMarkers });
  console.log({ arrMarkers2: arrMarkers?.[0]?.localAddress });
  console.log({ arrMarkers3: arrMarkers?.[0]?.localAddress?.geometry });
  console.log({
    arrMarkers4: arrMarkers?.[0]?.localAddress?.geometry?.location,
  });

  const LoopMarkers =
    arrMarkers &&
    arrMarkers?.map((marker, index) => {
      const btnSetItem = async () => {
        for (const currFunc of arrFuncs) await currFunc(marker);
      };

      const validMarker = marker && typeof marker?.localAddress === 'object';
      const numLat = Number(marker.localAddress?.geometry?.location.lat);
      const numLong = Number(marker.localAddress?.geometry?.location.lng);

      return validMarker ? (
        <Marker
          key={index}
          coordinate={{ latitude: numLat, longitude: numLong }}
          title={marker?.localName}
          onPress={btnSetItem}
          // image={srcPin}
          style={{
            width: 38,
            height: 40,
          }}
        >
          <Image
            source={srcPin}
            style={{
              width: 30,
              height: 36,
              resizeMode: 'cover',
            }}
          />
        </Marker>
      ) : (
        <></>
      );
    });
  return <>{LoopMarkers}</>;
};
