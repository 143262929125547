import React from 'react';

import * as SvgObj from 'react-native-svg';
import Svg from 'react-native-svg';

type Tprops = {
  pass: {
    componentSvg: any;
    svgOriginal: any;
    altura: any;
    largura: any;
    preenchimento: string[];
    args: any;
  };
};

// SvgView
export const SvgView1 = (props: Tprops) => {
  // ---------- set Caps Inputs
  const { componentSvg, altura, largura, preenchimento, args } = props.pass;

  const [SttComponent, setComponent] = React.useState(<></>);
  console.log({ preenchimento });
  const setNewComp = async () => componentSvg(Svg, SvgObj);

  // ---------- set Arguments and Variables (If Exists)
  const iptFill = preenchimento.reduce(
    (prev: any, curr: any) => prev + curr,
    '',
  );

  const newComp = (Comp: any) => (
    <Comp height={altura} width={largura} fill={iptFill} />
  );

  React.useEffect(() => {
    setNewComp().then(Comp => setComponent(newComp(Comp)));
  }, []);

  // #ATTENTION: #TEMP SVG mobile expo go error
  // const condWeb = Platform.OS === 'web';
  // const condDev = process.env.NODE_ENV === 'development';
  // const checkMobDev = condDev && !condWeb;

  // let TempComponent = () => <>{SttComponent}</>;
  // if (checkMobDev) TempComponent = () => <Text>ICON X3</Text>;

  // return <TempComponent />;
  console.log({ iptFill });
  return <>{SttComponent}</>;
  // return <></>;
};
