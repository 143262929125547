import * as functions from './functions';
import * as Elements from './Elements';
import * as project from './project';
import * as customs from './customs';
import * as jsvals from './jsvals';
import * as props from './props';
import * as stls from './stls';

export const tools = {
  ...project,
  functions,
  Elements,
  customs,

  jsvals,

  stls,
  props,
};
