import React from 'react';
import { View, ViewStyle } from 'react-native';

import { getStlValues, mapElements } from '../project';
import { useRoutes } from '../..';

// Screen1
type Tprops = { pass: { A: string; B: any; C: any; D: any; args: any } };

export const Screen1 = (props: Tprops) => {
  const { A } = props.pass;
  const currRoute = useRoutes(ct => ct.currRoute);
  const condShow = A === currRoute;

  return <>{condShow && <Screen1Render pass={props.pass} />}</>;
};
function Screen1Render(props: Tprops) {
  const { A, B, C, D, args } = props.pass;

  // if (A === 'codeReader') {
  //   const XXX___XXX___XXX = getCtData('toggles2.A7.validation');
  //   console.warn({ XXX___XXX___XXX });
  // }
  // ---------- set Caps Inputs
  // const arrStls = [B];
  // const children = [C];
  // const initFunctions = [D];
  React.useEffect(() => {
    D.forEach(i => i(args));
  }, []);

  // ---------- set Vars (If Exists)
  const stl = getStlValues(B);

  // ---------- set Caps Default
  const stlDf: ViewStyle = {
    backgroundColor: '#101',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    flex: 1,
  };

  // ---------- set Return
  return <View style={[stlDf, stl]}>{mapElements(C, args)}</View>;
}
