import React from 'react';
import { TextInput } from 'react-native';
import { getStlValues } from '../project';

type Tprops = {
  pass: {
    propsArray: any;
    stylesArray: any;
    funcsArray: any;
    args: any;
  };
};

// IptTxt2 - Entrada de Texto (fromDB_var)
export const IptTxt2 = (props: Tprops) => {
  // ------- set IptTxt Inputs
  const { propsArray, stylesArray, funcsArray, args } = props.pass;

  const getTxt = async (val: string) => {
    for (const currFunc of funcsArray) await currFunc(val, args);
  };
  console.log({ propsArray });

  // ---------- Styles
  const stlsUser = getStlValues(stylesArray);

  // ------- set User Props
  let userElProps = {};

  for (const object of propsArray) {
    const isFnc = typeof object === 'function';
    let newObj = {};
    if (isFnc) {
      console.log('IS A FUNCTION !!!!!!!!!!');
      newObj = object();
      userElProps = { ...userElProps, ...newObj };
    }

    if (!isFnc) {
      console.log('NOT FUNCTION');
      console.log({ object });

      for (const keyProp in object) {
        const valueProp = object[keyProp];
        userElProps[keyProp] = valueProp;
      }
    }
  }

  const allProps = {
    style: [stlsUser],
    onChangeText: getTxt,
    placeholderTextColor: '#ccc',
    placeholder: 'Escreva...',

    ...userElProps,
  };

  // console.log({ userElProps });
  // console.log({ allProps });

  return <TextInput {...allProps} />;
};
