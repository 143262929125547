// editable
type Tprops_editable = {
  pass: { condition: any };
};
export const editable = (props: Tprops_editable) => {
  const { condition } = props.pass;

  console.log({ condition });

  // return; aqui fica comentado por enquanto
  return condition;
};

// placeholder
type Tprops_placeholder = {
  pass: { A: any };
};
export const placeholder = (props: Tprops_placeholder) => {
  const { A } = props.pass;

  return { placeholder: A.join() };
};

// placeholderTextColor
type Tprops_placeholderTextColor = {
  pass: { A: any; B: any; C: any; D: any; args: any };
};
export const placeholderTextColor = (props: Tprops_placeholderTextColor) => {
  const { A, B, C, D, args } = props.pass;

  // console.log({ A });

  // return; aqui fica comentado por enquanto
};

// onFocus
type Tprops_onFocus = { pass: { A: any; B: any; C: any; D: any; args: any } };
export const onFocus = (props: Tprops_onFocus) => {
  const { A, B, C, D, args } = props.pass;

  // console.log({ A });

  // return; aqui fica comentado por enquanto
};

// onBlur
type Tprops_onBlur = { pass: { A: any; B: any; C: any; D: any; args: any } };
export const onBlur = (props: Tprops_onBlur) => {
  const { A, B, C, D, args } = props.pass;

  // console.log({ A });

  // return; aqui fica comentado por enquanto
};

// --------------------------------------
// --------------------------------------
// --------------------------------------
