import { setCurrPrice } from '../BtnUpdateAmount/setCurrPrice';
import { toDecimal } from '../BtnUpdateAmount/toDecimal';
import { currencyString } from '../currencyString';
import { getHourAndMin } from './getHourAndMin';

export const calcPriceOver = ({ initStamp, selectedHours, bikesList }) => {
  const timeNow = new Date().getTime();
  const diffTime = timeNow - initStamp;
  //   console.warn('PriceOver >>>>', { timeNow });
  console.log('PriceOver >>>>', { initStamp });

  // Convertendo Diferença para segundos
  const elapsedSeconds = diffTime / 1000;
  //   console.warn('PriceOver >>>>', { elapsedSeconds });

  // ----------------------------------
  // ----------------------------------
  // ----------------------------------
  const checkString = String(selectedHours);
  const splitNum = checkString.split('.'); // valor ex: 1.30
  const selHours = splitNum[0] ?? '0';
  const selMinutes = splitNum[1] ?? '0';

  // ----------------------------------
  // ----------------------------------
  // ----------------------------------
  const selHoursToSeconds = Number(selHours) * 3600 + Number(selMinutes) * 60;
  const overSeconds = elapsedSeconds - selHoursToSeconds;

  // ----------------------------------
  // ----------------------------------
  // ----------------------------------
  const { hours, minutes } = getHourAndMin(overSeconds);
  const overHours = `${hours}.${minutes}`;
  // console.warn('PriceOver >>>>', `excedeu um total de ${hours}h${minutes}`);

  const calcPriceOver = setCurrPrice(bikesList, overHours, '');
  //   const doidera = parseInt(String(calcPriceOver).replace(/(\D+)/g, ''));
  const doidera = String(calcPriceOver)?.split('.')[0] ?? calcPriceOver;
  //   console.log({ doidera });

  const currencyVal = currencyString({
    pass: { value: [doidera] },
  });

  const condOver = overSeconds > 0; // Começou o excedente
  console.log('OverPrice >>>>', { overSeconds, condOver });

  return { currencyVal, condOver };
};
