// file: B2_Register.tsx

// ---------- import Local Tools
import { mapElements } from '../tools/project/mapElements';
import * as functions from '../tools/functions';
import * as Elements from '../tools/Elements';
import * as customs from '../tools/customs';
import { Project } from '../tools/project/';
import * as jsvals from '../tools/jsvals';
import { props } from '../tools/props';
import * as stls from '../tools/stls';
import { tools } from '../tools';

// ---------- set Temp Dev DataBase
import * as devDB from '../devDB';
import { mock } from '../devDB';

export const screen = (...args: any) => (
  <Elements.Screen3
    pass={{
      pathScreen: 'b2register',

      styles: [
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.flex({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '1px',
                },
              }),
            ],
          },
        }),
        stls.padding({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '15px',
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.white',
                    },
                  }),
                },
              }),
            ],
          },
        }),
      ],

      screenElements: [
        (...args: any) => (
          <Elements.ScrollBar
            pass={{
              styles: [],
              arrProps: [],
              arrItems: [
                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.white',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.paddingHorizontal({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '20px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.paddingVertical({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '20px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.borderRadius({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '15px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadowRadius({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '5px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadowOpacity({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '5px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadows({
                          pass: {
                            shadowColor: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '#000000',
                                },
                              }),
                            ],
                            shadowOffset: ['{width: 0, height: 0}'],
                            shadowOpacity: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '0.2',
                                },
                              }),
                            ],
                            shadowRadius: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.alignItems({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'center',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.varReader({
                          pass: {
                            path: 'true',
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'true',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.ImageBox
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.borderRadius({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 75,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'white',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '120px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '120px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignSelf({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              URIvariablePath: [
                                jsvals.varReader({
                                  pass: {
                                    path: jsvals.j8({
                                      pass: {
                                        propertieValues:
                                          'all.userData.userImage',
                                      },
                                    }),
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [
                                stls.color({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.grey2',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              children: [
                                jsvals.varReader({
                                  pass: {
                                    path: jsvals.j8({
                                      pass: {
                                        propertieValues:
                                          'all.userData.userEmail',
                                      },
                                    }),
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Text
                                    pass={{
                                      arrProps: [{}],

                                      arrStyles: [
                                        stls.fontSize({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.textSizes.small',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.fontWeight({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'bold',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.color({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.grey2',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      children: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'Nome',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.IptTxtEdit
                                    pass={{
                                      propsArray: [{}],

                                      stylesArray: [
                                        stls.borderBottomWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderBottomColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.grey',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.color({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.grey2',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.fontSize({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.textSizes.small',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      editPath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'sc.B2.forms.editData.userName',
                                          },
                                        }),
                                      ],

                                      funcsArray: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.B2.forms.editData.userName',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.argReader({
                                                pass: {
                                                  argPath: jsvals.j8({
                                                    pass: {
                                                      propertieValues: '#0',
                                                    },
                                                  }),
                                                  args,
                                                },
                                              }),
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Text
                                    pass={{
                                      arrProps: [{}],

                                      arrStyles: [
                                        stls.fontSize({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.textSizes.small',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.fontWeight({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'bold',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.color({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.grey2',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      children: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'CPF',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.IptTxtMaskCPF
                                    pass={{
                                      propsArray: [{}],

                                      stylesArray: [
                                        stls.borderBottomWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderBottomColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.grey',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.color({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.grey2',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.fontSize({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.textSizes.small',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      editPath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'sc.B2.forms.editData.userCPF',
                                          },
                                        }),
                                      ],

                                      funcsArray: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.B2.forms.editData.userCPF',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.argReader({
                                                pass: {
                                                  argPath: jsvals.j8({
                                                    pass: {
                                                      propertieValues: '#0',
                                                    },
                                                  }),
                                                  args,
                                                },
                                              }),
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Text
                                    pass={{
                                      arrProps: [{}],

                                      arrStyles: [
                                        stls.fontSize({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.textSizes.small',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.fontWeight({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'bold',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.color({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.grey2',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      children: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'RG',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.IptTxtMaskRG
                                    pass={{
                                      propsArray: [{}],

                                      stylesArray: [
                                        stls.borderBottomWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderBottomColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.grey',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.color({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.grey2',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.fontSize({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.textSizes.small',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      editPath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'sc.B2.forms.editData.userRG',
                                          },
                                        }),
                                      ],

                                      funcsArray: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.B2.forms.editData.userRG',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.argReader({
                                                pass: {
                                                  argPath: jsvals.j8({
                                                    pass: {
                                                      propertieValues: '#0',
                                                    },
                                                  }),
                                                  args,
                                                },
                                              }),
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Text
                                    pass={{
                                      arrProps: [{}],

                                      arrStyles: [
                                        stls.fontSize({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.textSizes.small',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.fontWeight({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'bold',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.color({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.grey2',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      children: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'Telefone',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.IptTxtMaskPhone
                                    pass={{
                                      propsArray: [{}],

                                      stylesArray: [
                                        stls.borderBottomWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderBottomColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.grey',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.color({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.grey2',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.fontSize({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.textSizes.small',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      editPath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'sc.B2.forms.editData.userPhone',
                                          },
                                        }),
                                      ],

                                      funcsArray: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.B2.forms.editData.userPhone',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.argReader({
                                                pass: {
                                                  argPath: jsvals.j8({
                                                    pass: {
                                                      propertieValues: '#0',
                                                    },
                                                  }),
                                                  args,
                                                },
                                              }),
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Text
                                    pass={{
                                      arrProps: [{}],

                                      arrStyles: [
                                        stls.fontSize({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.textSizes.small',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.fontWeight({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'bold',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.color({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.grey2',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      children: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'Endereço',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.AutocompleteGooglePlaces
                                    pass={{
                                      apiKey:
                                        'AIzaSyBJ1MNdqlCOmPXK3taPtmuEJbsrmtiUDxU',
                                      pathList: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'sc.B2.forms.editData.userAddress',
                                          },
                                        }),
                                      ],
                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.position({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'Absolute',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.bottom({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.left({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '20px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues:
                                      'all.toggles.places.loader',
                                  },
                                }),
                              ],

                              expectedVal: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'true',
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Loader
                                    pass={{
                                      size: 'small',
                                      color: jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.primary',
                                            },
                                          }),
                                        },
                                      }),
                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '16px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.alignSelf({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'flex-start',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.flexDirection({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'row',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'all.toggles.radioButton',
                                          },
                                        }),
                                      ],

                                      expectedVal: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'unselected',
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.flexDirection({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'row',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '85px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '18px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '18px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderWidth({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '1px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderRadius({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.backgroundColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.ice',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '8px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.fontSize({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.textSizes.small',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.fontWeight({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'bold',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey2',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.B7.male',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.radioButton',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'male',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B7.forms.iptsChanges.userGender',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'sc.B7.male',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B2.forms.editData.userGender',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'Masculino',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '25px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.flexDirection({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'row',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '85px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '18px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '18px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderWidth({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '1px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderRadius({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.backgroundColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.ice',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '8px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.fontSize({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.textSizes.small',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.fontWeight({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'bold',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey2',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.B7.female',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.radioButton',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'female',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B7.forms.iptsChanges.userGender',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'sc.B7.female',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B2.forms.editData.userGender',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'Feminino',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.flexDirection({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'row',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'all.toggles.radioButton',
                                          },
                                        }),
                                      ],

                                      expectedVal: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'male',
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.flexDirection({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'row',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '85px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.backgroundColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.white',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '18px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '18px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.primary',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderWidth({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '1px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderRadius({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.alignItems({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'center',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.justifyContent({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'center',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.backgroundColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.primary',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '12px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '12px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.borderRadius(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '10px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '8px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.fontSize({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.textSizes.small',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.fontWeight({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'bold',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey2',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.B7.male',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                () =>
                                                  console.log('Você Clicou!'),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '25px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.flexDirection({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'row',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '85px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '18px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '18px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderWidth({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '1px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderRadius({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.backgroundColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.ice',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '8px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.fontSize({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.textSizes.small',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.fontWeight({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'bold',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey2',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.B7.female',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.radioButton',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'female',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B7.forms.iptsChanges.userGender',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'sc.B7.female',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B2.forms.editData.userGender',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'Feminino',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.flexDirection({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'row',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'all.toggles.radioButton',
                                          },
                                        }),
                                      ],

                                      expectedVal: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'female',
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.flexDirection({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'row',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '85px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '18px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '18px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderWidth({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '1px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderRadius({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.backgroundColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.ice',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '8px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.fontSize({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.textSizes.small',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.fontWeight({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'bold',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey2',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.B7.male',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.radioButton',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'male',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B7.forms.iptsChanges.userGender',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'sc.B7.male',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B2.forms.editData.userGender',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'Masculino',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '25px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.flexDirection({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'row',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '85px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.backgroundColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.white',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '18px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '18px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.primary',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderWidth({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '1px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderRadius({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.alignItems({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'center',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.justifyContent({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'center',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.backgroundColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.primary',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '12px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '12px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.borderRadius(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '10px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '8px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.fontSize({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.textSizes.small',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.fontWeight({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'bold',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey2',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.B7.female',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                () =>
                                                  console.log('Você Clicou!'),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '#F5D3D3',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingHorizontal({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingVertical({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '3px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderRadius({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '25px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'all.toggles.emptyField',
                                  },
                                }),
                              ],

                              expectedVal: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'yes',
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Text
                                    pass={{
                                      arrProps: [{}],

                                      arrStyles: [
                                        stls.color({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'red',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.textAlign({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.fontSize({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '11px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      children: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'Preencha todos os campos para prosseguir',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),
                        (...args: any) => (
                          <Elements.Pressable3
                            pass={{
                              elementProperties: [{}],

                              styles: [
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 40,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.padding({
                                  pass: { arrayValue: ['10px'] },
                                }),
                                stls.borderRadius({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '15px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '120px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderRadius({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '25px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.primary',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.shadows({
                                  pass: {
                                    shadowColor: ['#000'],
                                    shadowOffset: ['{width: 0, height: 0}'],
                                    shadowOpacity: ['0.4px'],
                                    shadowRadius: ['20px'],
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Text
                                    pass={{
                                      arrProps: [{}],

                                      arrStyles: [
                                        stls.color({
                                          pass: { arrayValue: ['white'] },
                                        }),
                                      ],

                                      children: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'AVANÇAR',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              pressableFunctions: [
                                async (...args) =>
                                  functions.funcGroup({
                                    args,
                                    pass: {
                                      arrFunctions: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.B2.forms.editData.fullRegister',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'true',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.B2.forms.editData.userStatus',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'pendente',
                                                },
                                              }),
                                            },
                                          }),
                                      ],
                                    },
                                  }),
                                async (...args) =>
                                  functions.condition({
                                    args,
                                    pass: {
                                      conditionPath: [
                                        jsvals.varReader({
                                          pass: {
                                            path: jsvals.j8({
                                              pass: {
                                                propertieValues:
                                                  'sc.B2.forms.editData.userName',
                                              },
                                            }),
                                          },
                                        }),
                                      ],
                                      expectedValue: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: '',
                                          },
                                        }),
                                      ],
                                      functionsTrue: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'all.toggles.emptyField',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'yes',
                                                },
                                              }),
                                            },
                                          }),
                                      ],
                                      functionsFalse: [
                                        async (...args) =>
                                          functions.condition({
                                            args,
                                            pass: {
                                              conditionPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'sc.B2.forms.editData.userCPF',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              expectedValue: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: '',
                                                  },
                                                }),
                                              ],
                                              functionsTrue: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'yes',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                              functionsFalse: [
                                                async (...args) =>
                                                  functions.condition({
                                                    args,
                                                    pass: {
                                                      conditionPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.B2.forms.editData.userRG',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      expectedValue: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues: '',
                                                          },
                                                        }),
                                                      ],
                                                      functionsTrue: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'all.toggles.emptyField',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'yes',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                      functionsFalse: [
                                                        async (...args) =>
                                                          functions.condition({
                                                            args,
                                                            pass: {
                                                              conditionPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'sc.B2.forms.editData.userPhone',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              expectedValue: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      '',
                                                                  },
                                                                }),
                                                              ],
                                                              functionsTrue: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'all.toggles.emptyField',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'yes',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              functionsFalse: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.condition(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        conditionPath:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'sc.B2.forms.editData.userAddress',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        expectedValue:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    '',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        functionsTrue:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                'all.toggles.emptyField',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'yes',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                        functionsFalse:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.condition(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    conditionPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'sc.B7.forms.iptsChanges.userGender',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    expectedValue:
                                                                                      [
                                                                                        jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                '',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    functionsTrue:
                                                                                      [
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'all.toggles.emptyField',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'yes',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                      ],
                                                                                    functionsFalse:
                                                                                      [
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.B2.forms.editData.userStatus',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'Pendente',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.firebase.updateDoc(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                fbInit:
                                                                                                  [
                                                                                                    jsvals.varReader(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          path: jsvals.j8(
                                                                                                            {
                                                                                                              pass: {
                                                                                                                propertieValues:
                                                                                                                  'all.fireInit',
                                                                                                              },
                                                                                                            },
                                                                                                          ),
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                arrRefStrings:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'users',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                    jsvals.varReader(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          path: jsvals.j8(
                                                                                                            {
                                                                                                              pass: {
                                                                                                                propertieValues:
                                                                                                                  'all.userData.docId',
                                                                                                              },
                                                                                                            },
                                                                                                          ),
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                arrPathData:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.B2.forms.editData',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                arrFuncs:
                                                                                                  [
                                                                                                    async (
                                                                                                      ...args
                                                                                                    ) =>
                                                                                                      functions.setVar(
                                                                                                        {
                                                                                                          args,
                                                                                                          pass: {
                                                                                                            keyPath:
                                                                                                              [
                                                                                                                jsvals.j8(
                                                                                                                  {
                                                                                                                    pass: {
                                                                                                                      propertieValues:
                                                                                                                        'all.toggles.emptyField',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ),
                                                                                                              ],
                                                                                                            value:
                                                                                                              jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'no',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                          },
                                                                                                        },
                                                                                                      ),
                                                                                                  ],
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        (
                                                                                          ...args
                                                                                        ) => {
                                                                                          // ---------- get Function from A_Project Scope
                                                                                          return tools.goTo(
                                                                                            'b3bikemap',
                                                                                          );
                                                                                        },
                                                                                      ],
                                                                                    args,
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                        args,
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              args,
                                                            },
                                                          }),
                                                      ],
                                                      args,
                                                    },
                                                  }),
                                              ],
                                              args,
                                            },
                                          }),
                                      ],
                                      args,
                                    },
                                  }),
                                async (...args) =>
                                  functions.setVar({
                                    args,
                                    pass: {
                                      keyPath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'all.toggles.sideMenu',
                                          },
                                        }),
                                      ],
                                      value: jsvals.j8({
                                        pass: {
                                          propertieValues: 'hide',
                                        },
                                      }),
                                    },
                                  }),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),
              ],
              args,
            }}
          />
        ),
      ],

      startFunctions: [
        async (...args) =>
          functions.firebase.getDoc({
            args,
            pass: {
              fbInit: [
                jsvals.varReader({
                  pass: {
                    path: jsvals.j8({
                      pass: {
                        propertieValues: 'all.fireInit',
                      },
                    }),
                  },
                }),
              ],
              arrRefStrings: [
                jsvals.j8({
                  pass: {
                    propertieValues: 'users',
                  },
                }),
                jsvals.varReader({
                  pass: {
                    path: jsvals.j8({
                      pass: {
                        propertieValues: 'all.userData.docId',
                      },
                    }),
                  },
                }),
              ],
              arrFuncs: [
                async (...args) =>
                  functions.setVar({
                    args,
                    pass: {
                      keyPath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'all.userData',
                          },
                        }),
                      ],
                      value: jsvals.argReader({
                        pass: {
                          argPath: jsvals.j8({
                            pass: {
                              propertieValues: '#1',
                            },
                          }),
                          args,
                        },
                      }),
                    },
                  }),
              ],
            },
          }),
      ],

      args,
    }}
  />
);
