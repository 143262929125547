// file: X1_Carlos.tsx

// ---------- import Local Tools
import { mapElements } from '../tools/project/mapElements';
import * as functions from '../tools/functions';
import * as Elements from '../tools/Elements';
import * as customs from '../tools/customs';
import { Project } from '../tools/project/';
import * as jsvals from '../tools/jsvals';
import { props } from '../tools/props';
import * as stls from '../tools/stls';
import { tools } from '../tools';

// ---------- set Temp Dev DataBase
import * as devDB from '../devDB';
import { mock } from '../devDB';

export const screen = (...args: any) => (
  <Elements.Screen3
    pass={{
      pathScreen: 'carlos',

      styles: [
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.flex({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '1px',
                },
              }),
            ],
          },
        }),

        stls.alignItems({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.justifyContent({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.white',
                    },
                  }),
                },
              }),
            ],
          },
        }),
      ],

      screenElements: [
        (...args: any) => (
          <Elements.FlatList2
            pass={{
              elementProperties: [{}],

              pData: jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'users',
                    },
                  }),
                },
              }),

              itemElements: [
                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.flexDirection({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'row',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [{ color: 'black', fontSize: 12 }],

                              children: [
                                jsvals.argReader({
                                  pass: {
                                    argPath: jsvals.j8({
                                      pass: {
                                        propertieValues: '#0.0.0.item.userName',
                                      },
                                    }),
                                    args,
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [{ color: 'black', fontSize: 12 }],

                              children: [
                                jsvals.argReader({
                                  pass: {
                                    argPath: jsvals.j8({
                                      pass: {
                                        propertieValues:
                                          '#0.0.0.item.userEmail',
                                      },
                                    }),
                                    args,
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),
              ],

              args,
            }}
          />
        ),
        (...args: any) => (
          <Elements.Text
            pass={{
              arrProps: [{}],

              arrStyles: [
                stls.color({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'blue',
                        },
                      }),
                    ],
                  },
                }),
              ],

              children: [
                jsvals.j8({
                  pass: {
                    propertieValues: 'red',
                  },
                }),
              ],

              args,
            }}
          />
        ),
        (...args: any) => (
          <Elements.Text
            pass={{
              arrProps: [{}],

              arrStyles: [
                stls.color({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'red',
                        },
                      }),
                    ],
                  },
                }),
              ],

              children: [
                jsvals.j8({
                  pass: {
                    propertieValues: 'red',
                  },
                }),
              ],

              args,
            }}
          />
        ),
      ],

      startFunctions: [() => {}],

      args,
    }}
  />
);
