// F7 - loop (newBase)
type Tprops_F7 = { pass: { A: any; B: any } };
export const F7 =
  (props: Tprops_F7) =>
  async (...args: any) => {
    const { A, B } = props.pass;

    // console.log({ A });
    // console.log({ B });
    // const iterator = getCtData('apiData2.A9.carousel');
    // console.log({ iterator });
    // -------------- Major Loop
    // for (const currItem of iterator) {
    // Arrumar
    // console.log(currItem);
    // const arrayImgs = getCtData('apiData2.A9.carousel');
    // const cookie = getCtData('');
    // console.log({ arrayImgs });
    // if (currItem.url) {
    // const proxy = getCtData('all.api.proxy');
    // const host = getCtData('all.api.host');
    // const proxy = 'http://localhost:5001/flaxboll/us-central1/proxyCall';
    // const cookie = getCtData('all.api.auth.cookie');
    // const preUrl = proxy + '/?url=' + host;
    // with proxy
    // const url = `${preUrl}/setup/hal/public/arquivos`;
    // without proxy
    // const url = `${host}/setup/hal/public/arquivos`;
    // console.log({ url });
    // const headers = new Headers();
    // headers.append('Content-Type', 'multipart/form-data');
    // headers.append('Access-Control-Allow-Origin', '*');
    // headers.append('Content-Type', 'image/jpeg');
    // const getBlob = await fetch(currItem.url);
    // const blob = await getBlob.blob();
    // const blob2 = new Blob();
    // console.log({ image: currItem.url });
    // console.log({ blob });
    // const formdata = new FormData();
    // formdata.set('file', blob2);
    // formdata.set('repository', 'repo1:patrimonio-mobiliario');
    // formdata.set('thumbnail', 'false');
    // formdata.append('file', blob, './img1.jpg');
    // formdata.append('file', blob, '[PROXY]');
    // const configCall: any = {
    //   method: 'POST',
    //   mode: 'cors',
    //   headers,
    //   body: formdata,
    //   redirect: 'follow',
    // };
    // const res = await fetch(url, configCall);
    // .catch(err => {
    //   console.log(err);
    // });
    // console.log({ res });
    // const response = await res.json();
    // console.log({ response });
    // }
    // }
  };
