import { pathSel } from './pathSel';

// ----------- set Arguments Selection

export const argSel = (args: any, inputStr: any) => {
  // const arrTerms = inputStr.split('.')[0];
  // const argsIdx = arrTerms.replace('#', '');
  // const valueArg = args[argsIdx];
  // const path = inputStr.replace(arrTerms + ".","");

  const path = inputStr.replace('#', '');
  const value = pathSel(args, path);
  return value;
};
