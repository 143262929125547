import { firestoreId } from '../../../globalVars/data';

type Tprops = {
  args: any;
  pass: {
    fbInit: any;
    arrRefStrings: string[];
    arrWhere: any;
    arrFuncs: any[];
  };
};

export const css1 =
  'color: green; background-color: black; font-size: 11px; padding: 2px 6px; border-radius: 3px';
export const css2 =
  'color: yellow; background-color: green; font-size: 10px; padding: 2px 6px; border-radius: 3px';

export const where = async (props: Tprops) => {
  // ---------- set Props
  const { args, pass } = props;
  const { fbInit, arrRefStrings, arrWhere, arrFuncs } = pass;

  // ---------- set Local Imports
  const { getFirestore, getDocs, collection, where, query } = await import(
    '@firebase/firestore'
  );

  // ---------- set Caps Inputs
  // let pathInitFB = "!#!firestore init path!#!";
  // let arrRefStrings = [!#!array firestore reference!#!];
  // const arrWhere = [!#!where params!#!];
  // const arrFuncs = [!#!functions return!#!];

  // ---------- set Short Str
  const fbErrMsg1 = 'Alguma entrada where foi preenchida errado.';
  const par1 = 'noComponent';

  // -----------------------------
  // ---------- set Init Firestore
  // -----------------------------
  if (!fbInit) return console.log(fbErrMsg1, { fbInit });
  const fireInit: any = getFirestore(fbInit[0], firestoreId);

  const arrConds = [];
  const newArrWh = () => {
    const promiseArray = arrWhere.map((capsCond: any) => {
      const resolve = capsCond();

      arrConds.push(resolve);

      return getDocs(
        query(refColl, where(resolve.field, resolve.operator, resolve.value)),
      );
    });

    return promiseArray;
  };

  // console.log({ newArrWh });
  const refColl = collection(fireInit, ...arrRefStrings);
  const resolvePromise = await Promise.all(newArrWh());

  const arrDocs = [];
  resolvePromise &&
    resolvePromise.forEach((snaps: any) => {
      snaps.forEach((doc: any) => {
        arrDocs.push(doc.data());
      });
    });

  console.log('%cWhere Cond', css1, { arrConds });
  console.log('%cWhere Docs Found', css2, { arrDocs });

  for (const currFunc of arrFuncs) currFunc(arrDocs, args);

  return arrDocs;
  // ------------------------------
  // ----- set Firestore onSnapShot
  //-------------------------------
  // const unsub = onSnapshot(newRefQuerys, newDocData => {
  //   const arrDocs = [];
  //   newDocData.forEach((doc: any) => {
  //     arrDocs.push(doc.data());
  //   });

  //   const arrWhereDocs = [];
  //   // EXEMPLO DE COMO MONITORAR MUDANÇAS ESPECÍFICAS
  //   newDocData.docChanges().forEach((currDoc: any) => {
  //     // newDocData.forEach((currDoc: any) => {
  //     if (currDoc.type === 'added') {
  //       console.log('Docs adicionadas: ', currDoc.doc.data());
  //       arrWhereDocs.push(currDoc.data());
  //     }
  //     if (currDoc.type === 'modified') {
  //       console.log('Docs modificadas: ', currDoc.doc.data());
  //       arrWhereDocs.push(currDoc.data());
  //     }
  //     if (currDoc.type === 'removed') {
  //       arrWhereDocs.push(currDoc.data());

  //       console.log('Docs removidas: ', currDoc.doc.data());
  //     }
  //     arrWhereDocs.push(currDoc.data());
  //   });

  //   // ---------- set Get Value Functions
  //   console.log({ arrDocs });
  //   console.log({ arrWhereDocs });
  //   // setData({ path: 'sc.A6.currData.locationsList', value: arrDocs });
  //   // for (const currFunc of arrFuncs) currFunc(arrDocs, args);
  // });
};
