import { orderBy, query } from 'firebase/firestore';
import { setData } from '../../project';
import { firestoreId } from '../../../globalVars/data';

export const css1 =
  'color: blueviolet; background-color: white; font-size: 11px; padding: 2px 6px; border-radius: 3px';
export const css2 =
  'color: cornflowerblue; background-color: white; font-size: 10px; padding: 2px 6px; border-radius: 3px';

type Tprops = {
  args: any;
  pass: { fbInit: any; arrRefStrings: string[]; arrFuncs: any[] };
};

export const getDocs = async (props: Tprops) => {
  // ---------- set Props
  const { args, pass } = props;
  const { fbInit, arrRefStrings, arrFuncs } = pass;

  // ---------- set Caps Inputs
  // const fbInit = [!#!firestore init path!#!];
  // const arrRefStrings = [!#!array firestore reference!#!];
  // const arrFuncs = [!#!functions return!#!];

  // ---------- set Local Imports
  const { getFirestore, getDocs, collection, onSnapshot } = await import(
    '@firebase/firestore'
  );

  // -----------------------------
  // ---------- set Firestore Call
  // -----------------------------
  console.log({ fbInit });
  console.log({ arrRefStrings });
  const fireInit = getFirestore(fbInit[0], firestoreId);
  const refColl = collection(fireInit, ...arrRefStrings);
  const q = query(refColl, orderBy('createdAt', 'desc'));

  const unsub = onSnapshot(q, success => {
    const arrDocs = [];
    success.forEach((doc: any) => {
      arrDocs.push(doc.data());
    });

    // ---------- set Get Value Functions
    console.log({ arrDocs });
    console.log('%cgetDocs', css1, { arrRefStrings });
    console.log('%cgetDocs Found', css2, { arrDocs });

    // setData({ path: 'sc.A6.currData.locationsList', value: arrDocs });
    for (const currFunc of arrFuncs) {
      console.log({ currFunc });
      currFunc(arrDocs, args);
    }
  });

  // Criar 2 novas entradas num novo FlatList
  // Funções de Início e Funções de Saída
  // E no getDocs criar uma nova entrada chamada de Caminho p/ desativar
  // setData({ path: 'sc.A6.currData.listener', value: unsub });

  // const success = await getDocs(refColl).catch(err =>
  //   console.log('Erro do getDocs', { err }),
  // );
  // console.log({ success });

  // success &&
  //   success.forEach((doc: any) => {
  //     console.log({ doc });
  //     arrDocs.push(doc.data());
  //   });

  // console.log({ arrDocs });

  // ---------- set Get Value Functions
  // for (const currFunc of arrFuncs) await currFunc(arrDocs, args);
};
