import { Pressable, Text, View } from 'react-native';
import { useState } from 'react';
import { stls } from './styles';
import { SvgMinus } from './SvgMinus';
import { SvgPlus } from './SvgPlus';

export type Tprops2 = {
  initValue: number;
  cbFunc: (count: number, mode: 'inc' | 'dec') => void;
  isDisabled: boolean;
};

export const BtnCounter = ({ initValue, cbFunc, isDisabled }: Tprops2) => {
  const [sttCount, setCount] = useState(initValue);

  // ---------- set Conditional Action
  const condStop = sttCount > 0;

  const btnInc = () => {
    // ---------- set Increment Amount
    const newCount = sttCount + 1;
    setCount(newCount);
    cbFunc(newCount, 'inc');
  };

  const btnDec = () => {
    // ---------- set Decrement Amount
    const newCount = sttCount - 1;
    if (condStop) setCount(newCount); // impede que subtraia de zero (parar)
    cbFunc(newCount, 'dec');
  };

  return (
    <View style={stls.container}>
      <Pressable style={stls.btn1} onPress={btnDec} disabled={isDisabled}>
        <SvgMinus />
      </Pressable>

      <Text style={{ marginHorizontal: 6 }}>{sttCount}</Text>

      <Pressable style={stls.btn1} onPress={btnInc} disabled={isDisabled}>
        <SvgPlus />
      </Pressable>
    </View>
  );
};
