import { setData } from '../../project';
import { multiplyUnits } from './multiplyUnits';
import { toDecimal } from './toDecimal';

export const setCurrPrice = (arrPricesCalc, selectedHours, condInit) => {
  const firstTime = condInit === 'firstTime';
  // console.log({ condInit });
  if (firstTime) {
    console.log('setCurrPrice', condInit);
    console.log({ arrPricesCalc });

    const initPrice = Number(arrPricesCalc.modelPrice);
    setData({ path: 'sc.B3C.currData.currPrice', value: initPrice });

    console.log({ path: 'sc.B3C.currData.currPrice' });
    console.log({ initPrice });

    return;
  }

  if (!!arrPricesCalc) {
    const arrAllMultiplied = multiplyUnits(arrPricesCalc);
    console.log({ arrAllMultiplied });

    const sumTotal = arrAllMultiplied.reduce((prev, curr) => {
      const increment = prev + curr;
      return increment;
    }, 0);

    console.log({ sumTotal });

    const multiplyHours = toDecimal(selectedHours);
    console.log({ multiplyHours });

    const calcValue = multiplyHours * sumTotal;

    setData({ path: 'sc.B3C.currData.currPrice', value: calcValue });
    console.log({ path: 'sc.B3C.currData.currPrice', calcValue });

    return calcValue;
  }
};
