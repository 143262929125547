import { updateDoc } from '../../functions/firebase/updateDoc';
import { baseUrl, secrets } from '../../../globalVars/data';
import { getCtData, setData } from '../../project';
import { asyncFn } from './asyncFn';
import { cardNumPath } from './helpers/paths';
import { errFnc } from '.';
import { setCtDataError, setErrorTrue } from './helpers/setCtDataError';

// type condError = boolean;
// type Tprops = () => Promise<Response>;

// --------- MUDAR NOME PARA DELETE CARD
// --------- MUDAR NOME PARA DELETE CARD
// --------- MUDAR NOME PARA DELETE CARD
export const editCCard: any = async () => {
  console.log('Gerando Card Hash...');
  const customerId = getCtData('all.userData.customerId');
  const cardId = getCtData('all.userData.paymentData.id');

  // ---------- EndPoint
  // https://api.pagar.me/core/v5/customers/:customer_id/cards/:card_id
  const url = baseUrl + `/customers/${customerId}/cards/${cardId}`;
  console.log({ url });

  // ---------- Headers
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Basic ${secrets.sk}`);

  const bodyData = {};

  console.log({ bodyData });
  // ---------- Body
  const raw = JSON.stringify(bodyData);

  console.log({ raw });
  // ---------- Request Config
  const reqConfig = {
    method: 'DELETE',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  // ---------------------------
  // ----------- SET CALL API --
  // ---------------------------
  const callRequest = await asyncFn(url, reqConfig).catch(errFnc);
  console.log({ callRequest });

  const res = await callRequest.json();
  const condStop = setCtDataError(res, callRequest.status);
  if (condStop !== 'follow') return condStop;

  console.log({ callRequest });

  // const res = await callRequest.json();
  console.log({ res });

  // ----------------------------------
  // ------ UPDATE USER WITH TOKEN ----
  // ----------------------------------
  const dataToUpdate = {
    paymentData: {},
  };
  setData({ path: 'all.tempData', value: dataToUpdate });
  setData({ path: 'all.userData.paymentData', value: {} });

  const args = {};

  // ----------------------------------
  // ---------- set Firestore TimerData
  // ----------------------------------
  //   const selectedUser = 'lbakZVRqA6MHcEtTMwVO'; // Test
  const selectedUser = getCtData('all.userData.docId');
  const fireInit = getCtData('all.fireInit');
  const passProps = {
    fbInit: [fireInit],
    arrRefStrings: ['users', selectedUser],
    arrPathData: ['all.tempData'],
    arrFuncs: [() => {}],
  };

  await updateDoc({ args, pass: passProps });

  // clean temp data
  setData({ path: 'all.tempData', value: null });

  return 'follow';
};
