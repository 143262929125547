// file: B8_BlockScreen.tsx

// ---------- import Local Tools
import { mapElements } from '../tools/project/mapElements';
import * as functions from '../tools/functions';
import * as Elements from '../tools/Elements';
import * as customs from '../tools/customs';
import { Project } from '../tools/project/';
import * as jsvals from '../tools/jsvals';
import { props } from '../tools/props';
import * as stls from '../tools/stls';
import { tools } from '../tools';

// ---------- set Temp Dev DataBase
import * as devDB from '../devDB';
import { mock } from '../devDB';

export const screen = (...args: any) => (
  <Elements.Screen3
    pass={{
      pathScreen: 'b8lockscreen',

      styles: [
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.flex({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '1px',
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.ice',
                    },
                  }),
                },
              }),
            ],
          },
        }),
        stls.alignItems({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.paddingHorizontal({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '30px',
                },
              }),
            ],
          },
        }),
        stls.paddingTop({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100px',
                },
              }),
            ],
          },
        }),
      ],

      screenElements: [
        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.width({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '100%',
                        },
                      }),
                    ],
                  },
                }),
                stls.backgroundColor({
                  pass: {
                    arrayValue: [
                      jsvals.varReader({
                        pass: {
                          path: jsvals.j8({
                            pass: {
                              propertieValues: 'all.colors.white',
                            },
                          }),
                        },
                      }),
                    ],
                  },
                }),
                stls.borderRadius({ pass: { arrayValue: [10] } }),
                stls.minHeight({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '100px',
                        },
                      }),
                    ],
                  },
                }),

                stls.alignItems({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'center',
                        },
                      }),
                    ],
                  },
                }),
                stls.justifyContent({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'center',
                        },
                      }),
                    ],
                  },
                }),
                stls.padding({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '15px',
                        },
                      }),
                    ],
                  },
                }),
                stls.shadowRadius({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '1px',
                        },
                      }),
                    ],
                  },
                }),
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [
                (...args: any) => (
                  <Elements.Text
                    pass={{
                      arrProps: [{}],

                      arrStyles: [
                        stls.fontSize({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.textSizes.medium',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.color({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.primary',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.fontWeight({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'bold',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      children: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'Aviso',
                          },
                        }),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.Text
                    pass={{
                      arrProps: [{}],

                      arrStyles: [
                        stls.fontSize({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.textSizes.large',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.fontWeight({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'bold',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.color({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'red',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      children: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'Usuário Bloqueado',
                          },
                        }),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.Text
                    pass={{
                      arrProps: [{}],

                      arrStyles: [
                        stls.textAlign({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'center',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.fontSize({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.textSizes.small',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      children: [
                        jsvals.j8({
                          pass: {
                            propertieValues:
                              'Lamentamos informar que sua conta foi bloqueada devido à violação dos princípios do aplicativo. Essa ação é necessária para manter um ambiente seguro e respeitoso para todos os usuários. ',
                          },
                        }),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),
              ],

              args,
            }}
          />
        ),
      ],

      startFunctions: [() => {}],

      args,
    }}
  />
);
