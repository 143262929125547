import { toDecimal } from '../BtnUpdateAmount/toDecimal';
import { getCtData, setData } from '../../project';
import { setCurrPrice } from '../BtnUpdateAmount/setCurrPrice';

export const css =
  'color: #009966; background-color: #fff; font-size: 11px; padding: 2px 6px; border-radius: 3px';

type Tprops = {
  pass: {};
  args?: any;
};

// ---------- Quem aciona essa função é
// ---------o picker Selecione as Horas
// --------------------- no checkout B3C
export const processCheckout = async (props: Tprops) => {
  const { args } = props;
  const selectedHours = args?.[1];
  // const selecHoursNumber = Number(args[1]);
  console.log('PROCESSCHECKOUT', selectedHours);

  // ---- Watch Data List B3C (mainList)
  const updatedList = args?.[0]?.[0]?.watch; // Recebe do watcher (pai que é um Listener)
  console.log('PROCESSCHECKOUT', updatedList);

  const condList = updatedList ?? getCtData('sc.B3C.currData.mainList');
  const condHours = selectedHours ?? '1';

  const calcValue = setCurrPrice(condList, condHours, 'manyTimes');
  // const calcValue = setCurrPrice(updatedList, selectedHours, 'manyTimes');

  console.log({ selectedHours });
  console.log({ condHours });
  const getFHour = formattedHours(condHours);
  console.log({ getFHour });

  setData({ path: 'sc.B3C.currData.currPrice', value: calcValue });
  setData({ path: 'sc.B3C.currData.selecHours', value: condHours });
  setData({ path: 'sc.B4.currData.checkout.formattedHours', value: getFHour });
};

export const formattedHours = (selectedHours: string) => {
  let setH: string;
  const haveDot = selectedHours?.includes('.');

  if (haveDot) setH = selectedHours?.replace('.', 'h');
  if (!haveDot) setH = selectedHours + 'h';

  console.log({ setH });
  return setH;
};
