import { firestoreId } from '../../../globalVars/data';
import { getCtData } from '../../project';

export const css1 =
  'color: red; background-color: black; font-size: 11px; padding: 2px 6px; border-radius: 3px';
export const css2 =
  'color: yellow; background-color: red; font-size: 10px; padding: 2px 6px; border-radius: 3px';

type Tprops = {
  args: any;
  pass: {
    fbInit: any;
    arrRefStrings: string[];
    arrPathData: string[];
    arrFuncs: any[];
  };
};

export const deleteDoc = async (props: Tprops) => {
  // ---------- set Props
  const { args, pass } = props;
  const { fbInit, arrRefStrings, arrFuncs } = pass;
  // ---------- set Local Imports
  const { getFirestore, doc, deleteDoc } = await import('@firebase/firestore');
  //   const { getStorage, deleteObject, ref } = await import('@firebase/storage');

  // ---------- set Caps Inputs
  //   let pathInitFB = "!#!firestore init path!#!";
  //   let arrRefStrings = [!#!array firestore reference!#!];
  //   const arrFuncs = [!#!functions return!#!];

  // ---------- set Short Str
  //   const fbErrMsg1 = 'Alguma entrada delete foi preenchida errado.';

  // -----------------------------
  // -------- set Firestore Call 1
  // -----------------------------
  const fireInit: any = getFirestore(fbInit[0], firestoreId);
  const refColl = doc(fireInit, ...arrRefStrings);
  await deleteDoc(refColl);

  // -----------------------------
  // -------- set Firestore Call 2
  // -----------------------------
  //   const storage = getStorage(fbInit);
  //   console.log({ newArrStrg });
  //   const condStorage = newArrStrg?.length > 0 && newArrStrg[0] !== '';
  //   if (condStorage) {
  //     for (const currStrgRef of newArrStrg) {
  //       const strRefFile = ref(storage, currStrgRef);
  //       await deleteObject(strRefFile);
  //     }
  //   } else {
  //     console.log('NO REF STORAGE');
  //   }

  // ---------- set Get Value Functions
  for (const currFunc of arrFuncs) await currFunc(arrRefStrings, args);

  console.log('%cdeleteDoc ok', css1);
  console.log('%cReferencia da Exclusão', css2, arrRefStrings);
};
