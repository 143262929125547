import React from 'react';
import { ScrollView } from 'react-native';
import { getStlValues, mapElements } from '../project';

type Tprops_ScrollBar = {
  pass: { styles: any[]; arrProps: any[]; arrItems: any; args: any };
};
export const ScrollBar = (props: Tprops_ScrollBar) => {
  const { styles, arrItems, arrProps, args } = props.pass;

  // ---------- set Variables Styles (If Exists)
  const stl = getStlValues(styles);

  // ------- Props
  const userEnteredProps = {};

  for (const object of arrProps) {
    for (const keyProp in object) {
      const valueProp = object[keyProp];
      userEnteredProps[keyProp] = valueProp;
    }
  }

  const allProps = {
    style: [stl],
    ...userEnteredProps,
  };

  return <ScrollView>{mapElements(arrItems, args)}</ScrollView>;
};
