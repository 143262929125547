// file: X2_Juan.tsx

// ---------- import Local Tools
import { mapElements } from '../tools/project/mapElements';
import * as functions from '../tools/functions';
import * as Elements from '../tools/Elements';
import * as customs from '../tools/customs';
import { Project } from '../tools/project/';
import * as jsvals from '../tools/jsvals';
import { props } from '../tools/props';
import * as stls from '../tools/stls';
import { tools } from '../tools';

// ---------- set Temp Dev DataBase
import * as devDB from '../devDB';
import { mock } from '../devDB';

export const screen = (...args: any) => (
  <Elements.Screen3
    pass={{
      pathScreen: 'juan',

      styles: [
        {
          backgroundColor: '#101',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        },
      ],

      screenElements: [
        (...args: any) => (
          <Elements.Text
            pass={{
              arrProps: [{}],

              arrStyles: [
                stls.fontSize({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '40px',
                        },
                      }),
                    ],
                  },
                }),
                stls.color({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '#FFF1',
                        },
                      }),
                    ],
                  },
                }),
              ],

              children: [
                jsvals.j8({
                  pass: {
                    propertieValues: 'Tela do Juan',
                  },
                }),
              ],

              args,
            }}
          />
        ),
        (...args: any) => (
          <Elements.Pressable3
            pass={{
              elementProperties: [{}],

              styles: [
                {
                  backgroundColor: 'blue',
                  borderRadius: 20,
                  paddingVertical: 5,
                  paddingHorizontal: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              ],

              childrenItems: [() => <></>],

              pressableFunctions: [() => console.log('Você Clicou!')],

              args,
            }}
          />
        ),
      ],

      startFunctions: [() => {}],

      args,
    }}
  />
);
