import { updateDoc } from '../../functions/firebase/updateDoc';
import { getCtData, setData } from '../../project';

type Tprops = { pass: {} };

// ------------ Esta Cápsula Custom atualiza no firestore
// ---------- na collection rents o documento selecionado
// ------------- atualiza o status isRunnig na prop timer
// -------- conforme os botões START / STOP do cronômetro
// ----------  são pressionados. Também atualiza no mesmo
// ------------  objeto os carimbos de início e de fim da
// ---------------  contagem do cronômetro conforme o adm
//---------------------------- controlar. (START ou STOP)

// update timestmap
export const custom04 = async (props: Tprops) => {
  // -----------------------------
  // ---------- prepare Timer Data
  // -----------------------------
  const selectedRent = getCtData('sc.A12.currData.docId');
  const isRunning = getCtData('sc.B5.toggles.timerRunning');
  const fireInit = getCtData('all.fireInit');

  console.log({ isRunning });
  const args = {};
  const nowTime = Date.now();
  const isTrue = isRunning === 'true';
  const isWaiting = isRunning === 'waiting';
  // const isFalse = isRunning === 'false';
  // const cond4 = isTrue || isWaiting;

  let condRunning = 'null';
  let condUpdateName = 'null';

  if (isWaiting) {
    condRunning = 'true';
    condUpdateName = 'timer.initTimeStamp';
  }

  if (isTrue) {
    condRunning = 'false';
    condUpdateName = 'timer.endTimeStamp';
  }

  const dataToUpdate = {
    [condUpdateName]: nowTime,
    ['timer.isRunning']: String(condRunning),
  };
  setData({ path: 'sc.A12.currData.timer', value: dataToUpdate });

  // ----------------------------------
  // ---------- set Firestore TimerData
  // ----------------------------------
  const passProps = {
    fbInit: [fireInit],
    arrRefStrings: ['rents', selectedRent],
    arrPathData: ['sc.A12.currData.timer'],
    arrFuncs: [() => {}],
  };

  await updateDoc({ args, pass: passProps });

  // ----------------------------------
  // ---------- set Firestore Rent ID
  // ----------------------------------
  if (isTrue) {
    const tempRentPath = 'sc.A12.currData.tempDataRent';
    const selectedUser = getCtData('sc.A14.forms.editData.userId'); // user owner rent
    setData({
      path: tempRentPath,
      value: { ['activeRentData.rentId']: 'no rent active' },
    });

    const passProps2 = {
      fbInit: [fireInit],
      arrRefStrings: ['users', selectedUser],
      arrPathData: [tempRentPath],
      arrFuncs: [() => {}],
    };

    console.log('UPDATE USER XX', { passProps2 });

    await updateDoc({ args, pass: passProps2 });

    setData({ path: tempRentPath, value: null });
  }
};
