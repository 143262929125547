import { updateDoc } from '../../functions/firebase/updateDoc';
import { baseUrl, secrets } from '../../../globalVars/data';
import { getCtData, setData } from '../../project';
import { asyncFn } from './asyncFn';
import { cardNumPath, userDataPath } from './helpers/paths';
import { errFnc } from '.';
import { setCtDataError, setErrorTrue } from './helpers/setCtDataError';
import { getBillingAddress } from './helpers/getOrderData';

// type condError = boolean;
// type Tprops = () => Promise<Response>;

export const getCardHash: any = async () => {
  console.log('Gerando Card Hash...');
  const customerId = getCtData('all.userData.customerId');
  let mockCardNumber = getCtData(cardNumPath); // Dados do Cartão
  const card1 = mockCardNumber?.credit_card?.card;

  if (!card1) return setErrorTrue('Preencha todos os campos do cartão.');
  if (!card1?.billing_address) {
    const userAddress = getCtData(userDataPath + '.userAddress');

    console.log({ userAddress });

    const arrayAd = userAddress?.address_components;
    const form_billing_address = getBillingAddress(arrayAd);
    console.log({ form_billing_address });

    card1.billing_address = form_billing_address;
  }
  console.log({ card1 });
  const arrCardValues = Object.values(card1);

  console.log({ arrCardValues });
  const condCardFilled = arrCardValues.every(i => i);
  console.log({ condCardFilled });
  if (!condCardFilled)
    return setErrorTrue('Preencha todos os campos do cartão.');

  mockCardNumber.credit_card.card.number =
    mockCardNumber?.credit_card?.card?.number?.replace(/[^0-9]/g, '');

  // ---------- EndPoint
  const url = baseUrl + `/customers/${customerId}/cards`;
  console.log({ url });

  // ---------- Headers
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Basic ${secrets.sk}`);

  const bodyData = {
    ...card1,
  };

  console.log({ bodyData });
  // ---------- Body
  const raw = JSON.stringify(bodyData);

  console.log({ raw });
  // ---------- Request Config
  const reqConfig = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  // ---------------------------
  // ----------- SET CALL API --
  // ---------------------------
  const callRequest = await asyncFn(url, reqConfig).catch(errFnc);
  console.log({ callRequest });

  // if (!callRequest) {
  //   console.log({ callRequest });
  //   return;
  // }

  // ----- check IF Stop Process
  // setCtDataError(callRequest); // não quebra a função (simplesmente testa resposta e retorna no ctData se pegou algum erro)

  const res = await callRequest.json();
  const condStop = setCtDataError(res, callRequest.status);
  if (condStop !== 'follow') return condStop;

  // const condError = setCtDataError(callRequest);
  // if (condError) return condError;

  console.log({ callRequest });

  // const res = await callRequest.json();
  console.log({ res });

  // ----------------------------------
  // ------ UPDATE USER WITH TOKEN ----
  // ----------------------------------
  const dataToUpdate = {
    paymentData: res,
  };
  setData({ path: 'all.tempData', value: dataToUpdate });
  setData({ path: 'all.userData.paymentData', value: res });

  const args = {};

  // ----------------------------------
  // ---------- set Firestore TimerData
  // ----------------------------------
  //   const selectedUser = 'lbakZVRqA6MHcEtTMwVO'; // Test
  const selectedUser = getCtData('all.userData.docId');
  const fireInit = getCtData('all.fireInit');
  const passProps = {
    fbInit: [fireInit],
    arrRefStrings: ['users', selectedUser],
    arrPathData: ['all.tempData'],
    arrFuncs: [() => {}],
  };

  await updateDoc({ args, pass: passProps });

  // clean temp data
  setData({ path: 'all.tempData', value: null });

  return 'follow';
};
