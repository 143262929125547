import { Linking } from 'react-native';
import { getDocs } from '../../functions/firebase/getDocs';
import { where } from '../../functions/firebase/where';
import { whereConds } from '../../functions/firebase/whereConds';
import { getCtData, setData } from '../../project';

type Tprops = {
  pass: {
    ipt1: any[];
    ipt2: string;
    ipt3: any[];
  };
  args?: any;
};

export const urlRoute = async (props: Tprops) => {
  console.log('URL Route');

  const currData = getCtData('sc.B3A.currData.currLocation.data');
  const coords = currData.localAddress.geometry.location;

  const currInitMap = getCtData('sc.B3A.currData.currUserLocation');
  const { latitude, longitude } = currInitMap?.coords;

  const { lat, lng } = coords;
  console.log({ lat, lng });

  const initPoint = `${latitude},${longitude}`; // Localização do usuário
  const finalPoint = lat + ',' + lng;
  const url = `https://www.google.com.br/maps/dir/${initPoint}/${finalPoint}`;
  console.log({ url });
  //   const supported = await Linking.canOpenURL(url);
  await Linking.openURL(url);
};
