// file: A0_1_Biblioteca.tsx

// ---------- import Local Tools
import { mapElements } from '../tools/project/mapElements';
import * as functions from '../tools/functions';
import * as Elements from '../tools/Elements';
import * as customs from '../tools/customs';
import { Project } from '../tools/project/';
import * as jsvals from '../tools/jsvals';
import { props } from '../tools/props';
import * as stls from '../tools/stls';
import { tools } from '../tools';

// ---------- set Temp Dev DataBase
import * as devDB from '../devDB';
import { mock } from '../devDB';

export const screen = (...args: any) => (
  <Elements.Screen3
    pass={{
      pathScreen: '--------1',

      styles: [
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.white',
                    },
                  }),
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.ice',
                    },
                  }),
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.primary',
                    },
                  }),
                },
              }),
            ],
          },
        }),
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '300px',
                },
              }),
            ],
          },
        }),
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 40,
                },
              }),
            ],
          },
        }),
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '80%',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '310px',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 40,
                },
              }),
            ],
          },
        }),
        stls.alignItems({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.justifyContent({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.justifyContent({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'space-between',
                },
              }),
            ],
          },
        }),
        stls.justifyContent({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'start',
                },
              }),
            ],
          },
        }),
        stls.justifyContent({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'space-around',
                },
              }),
            ],
          },
        }),
        stls.flexDirection({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'row',
                },
              }),
            ],
          },
        }),
        stls.flex({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '1px',
                },
              }),
            ],
          },
        }),
        stls.borderRadius({ pass: { arrayValue: [10] } }),
        stls.color({ pass: { arrayValue: ['white'] } }),
        stls.color({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.primary',
                    },
                  }),
                },
              }),
            ],
          },
        }),
        stls.position({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'absolute',
                },
              }),
            ],
          },
        }),
        stls.top({ pass: { arrayValue: ['1px'] } }),
        stls.padding({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '15px',
                },
              }),
            ],
          },
        }),
        stls.paddingHorizontal({ pass: { arrayValue: ['10px'] } }),
        stls.paddingLeft({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '5px',
                },
              }),
            ],
          },
        }),
        stls.paddingVertical({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '4px',
                },
              }),
            ],
          },
        }),
        stls.alignSelf({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.padding({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '10px',
                },
              }),
            ],
          },
        }),
        stls.borderWidth({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '1px',
                },
              }),
            ],
          },
        }),
        stls.right({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '-10px',
                },
              }),
            ],
          },
        }),

        stls.alignItems({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.justifyContent({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),

        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.flex({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '1px',
                },
              }),
            ],
          },
        }),

        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '300px',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '310px',
                },
              }),
            ],
          },
        }),
        stls.padding({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '15px',
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.white',
                    },
                  }),
                },
              }),
            ],
          },
        }),
        stls.borderRadius({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '18px',
                },
              }),
            ],
          },
        }),

        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 40,
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 40,
                },
              }),
            ],
          },
        }),
        stls.alignItems({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.justifyContent({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),

        stls.alignItems({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.justifyContent({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'start',
                },
              }),
            ],
          },
        }),
        stls.paddingHorizontal({ pass: { arrayValue: ['10px'] } }),

        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.white',
                    },
                  }),
                },
              }),
            ],
          },
        }),
        stls.borderRadius({ pass: { arrayValue: [10] } }),

        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.ice',
                    },
                  }),
                },
              }),
            ],
          },
        }),

        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.flex({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '1px',
                },
              }),
            ],
          },
        }),
        stls.paddingHorizontal({ pass: { arrayValue: ['10px'] } }),

        stls.padding({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '10px',
                },
              }),
            ],
          },
        }),
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.white',
                    },
                  }),
                },
              }),
            ],
          },
        }),
        stls.borderRadius({ pass: { arrayValue: [10] } }),
        stls.flexDirection({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'row',
                },
              }),
            ],
          },
        }),

        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.primary',
                    },
                  }),
                },
              }),
            ],
          },
        }),
        stls.justifyContent({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'space-between',
                },
              }),
            ],
          },
        }),
        stls.flexDirection({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'row',
                },
              }),
            ],
          },
        }),
        stls.alignItems({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '60px',
                },
              }),
            ],
          },
        }),
        stls.paddingHorizontal({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 10,
                },
              }),
            ],
          },
        }),

        stls.flexDirection({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'row',
                },
              }),
            ],
          },
        }),
        stls.alignItems({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.justifyContent({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'space-around',
                },
              }),
            ],
          },
        }),
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '55px',
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.grey2',
                    },
                  }),
                },
              }),
            ],
          },
        }),

        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '#dddddd',
                },
              }),
            ],
          },
        }),
        stls.borderRadius({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '15px',
                },
              }),
            ],
          },
        }),
        stls.padding({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '5px',
                },
              }),
            ],
          },
        }),

        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.white',
                    },
                  }),
                },
              }),
            ],
          },
        }),
        stls.borderBottomWidth({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '1px',
                },
              }),
            ],
          },
        }),
        stls.borderBottomColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.grey',
                    },
                  }),
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '0000.2',
                },
              }),
            ],
          },
        }),
        stls.position({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'absolute',
                },
              }),
            ],
          },
        }),
        stls.top({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '0',
                },
              }),
            ],
          },
        }),
        stls.left({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '0',
                },
              }),
            ],
          },
        }),
        stls.flex({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '1',
                },
              }),
            ],
          },
        }),
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
      ],

      screenElements: [
        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.width({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '300px',
                        },
                      }),
                    ],
                  },
                }),
                stls.height({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '107%',
                        },
                      }),
                    ],
                  },
                }),
                stls.position({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'absolute',
                        },
                      }),
                    ],
                  },
                }),
                stls.top({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '-58px',
                        },
                      }),
                    ],
                  },
                }),
                stls.right({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '0px',
                        },
                      }),
                    ],
                  },
                }),
                stls.backgroundColor({
                  pass: {
                    arrayValue: [
                      jsvals.varReader({
                        pass: {
                          path: jsvals.j8({
                            pass: {
                              propertieValues: 'all.colors.ice',
                            },
                          }),
                        },
                      }),
                    ],
                  },
                }),
              ],

              variablePath: [
                jsvals.varReader({
                  pass: {
                    path: jsvals.j8({
                      pass: {
                        propertieValues: 'all.toggles.sideMenu',
                      },
                    }),
                  },
                }),
              ],

              expectedVal: [
                jsvals.j8({
                  pass: {
                    propertieValues: 'show',
                  },
                }),
              ],

              childrenItems: [
                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.flex({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '1px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.ice',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadows({
                          pass: {
                            shadowColor: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '#000000',
                                },
                              }),
                            ],
                            shadowOffset: ['{width: 0, height: 0}'],
                            shadowOpacity: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '0.2',
                                },
                              }),
                            ],
                            shadowRadius: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'all.toggles.activeForm',
                              },
                            }),
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'a3permission',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'space-between',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flexDirection({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'row',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '60px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingHorizontal({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 10,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '2px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'lightgrey',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.SvgView1
                                            pass={{
                                              componentSvg: (
                                                Svg: any,
                                                SvgObj: any,
                                              ) => {
                                                const {
                                                  Defs,
                                                  Stop,
                                                  Path,
                                                  LinearGradient,
                                                  G,
                                                  Circle,
                                                  Rect,
                                                  Mask,
                                                } = SvgObj;
                                                return (props: any) => (
                                                  <Svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={14}
                                                    viewBox="0 0 20 14"
                                                    {...props}
                                                  >
                                                    <Path
                                                      fillRule="evenodd"
                                                      d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                                                      clipRule="evenodd"
                                                      {...props}
                                                    />
                                                  </Svg>
                                                );
                                              },

                                              svgOriginal: `
        <svg></svg>
      `,

                                              altura: '16px',

                                              largura: '16px',

                                              preenchimento: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.colors.primary',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A5.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.editData',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.toogles.newBikeImg',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'false',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [[]],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.maxWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Formulário de',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'REGISTRO DE PERMISSÃO',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '1px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.padding({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '20px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flex({ pass: { arrayValue: ['1'] } }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [
                                jsvals.varReader({
                                  pass: {
                                    path: 'true',
                                  },
                                }),
                              ],

                              expectedVal: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'true',
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.white',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowOpacity({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#000000',
                                                },
                                              }),
                                            ],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '0.2',
                                                },
                                              }),
                                            ],
                                            shadowRadius: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Nome Completo',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxt2
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges.userName',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'E-mail',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxt2
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges.userEmail',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Senha',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxt2
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges.userPassword',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Ponto de Retirada',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <customs.IptPicker
                                                    pass={{
                                                      pathList: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.lists.pickerList1',
                                                          },
                                                        }),
                                                      ],
                                                      pathEdit: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.lists.locationsList.localName',
                                                          },
                                                        }),
                                                      ],
                                                      arrFuncs: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A3.forms.iptsChanges.locationId',
                                                                  },
                                                                }),
                                                              ],
                                                              value:
                                                                jsvals.argReader(
                                                                  {
                                                                    pass: {
                                                                      argPath:
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '#1',
                                                                            },
                                                                          },
                                                                        ),
                                                                      args,
                                                                    },
                                                                  },
                                                                ),
                                                            },
                                                          }),
                                                        (...args) =>
                                                          customs.custom03({
                                                            args,
                                                            pass: {
                                                              ipt1: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A3.lists.pickerList1',
                                                                  },
                                                                }),
                                                              ],
                                                              ipt2: 'id',
                                                              ipt3: [
                                                                jsvals.argReader(
                                                                  {
                                                                    pass: {
                                                                      argPath:
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '#1',
                                                                            },
                                                                          },
                                                                        ),
                                                                      args,
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              ipt4: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A3.forms.iptsChanges.locationName',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.argReader(
                                                                            {
                                                                              pass: {
                                                                                argPath:
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '#1.label',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                args,
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                            },
                                                          }),
                                                      ],
                                                      pathReturn: [''],
                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#F5D3D3',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '3px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'all.toggles.emptyField',
                                          },
                                        }),
                                      ],

                                      expectedVal: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'yes',
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'red',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '11px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Preencha todos os campos para prosseguir',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: ['#000'],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: ['0.4px'],
                                            shadowRadius: ['20px'],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: ['white'],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Salvar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A3.forms.iptsChanges.typeAccount',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'loc',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.condition({
                                            args,
                                            pass: {
                                              conditionPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'sc.A3.forms.iptsChanges.userName',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              expectedValue: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: '',
                                                  },
                                                }),
                                              ],
                                              functionsTrue: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'yes',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                              functionsFalse: [
                                                async (...args) =>
                                                  functions.condition({
                                                    args,
                                                    pass: {
                                                      conditionPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.A3.forms.iptsChanges.userEmail',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      expectedValue: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues: '',
                                                          },
                                                        }),
                                                      ],
                                                      functionsTrue: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'all.toggles.emptyField',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'yes',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                      functionsFalse: [
                                                        async (...args) =>
                                                          functions.condition({
                                                            args,
                                                            pass: {
                                                              conditionPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'sc.A3.forms.iptsChanges.userPassword',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              expectedValue: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      '',
                                                                  },
                                                                }),
                                                              ],
                                                              functionsTrue: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'all.toggles.emptyField',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'yes',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              functionsFalse: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.firebase.setDoc(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        fbInit:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.fireInit',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrRefStrings:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'permissions',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrPathData:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A3.forms.iptsChanges',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrFuncs:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                'all.toggles.emptyField',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'no',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                      },
                                                                    },
                                                                  ),
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.toggles.sideMenu',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'hide',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.toggles.activeForm',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'none',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A3.forms.iptsChanges',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              args,
                                                            },
                                                          }),
                                                      ],
                                                      args,
                                                    },
                                                  }),
                                              ],
                                              args,
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Cancelar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A3.forms.iptsChanges',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: '',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.flex({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '1px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.ice',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadows({
                          pass: {
                            shadowColor: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '#000000',
                                },
                              }),
                            ],
                            shadowOffset: ['{width: 0, height: 0}'],
                            shadowOpacity: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '0.2',
                                },
                              }),
                            ],
                            shadowRadius: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'all.toggles.activeForm',
                              },
                            }),
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'a3editpermissions',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'space-between',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flexDirection({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'row',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '60px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingHorizontal({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 10,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '2px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'lightgrey',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.SvgView1
                                            pass={{
                                              componentSvg: (
                                                Svg: any,
                                                SvgObj: any,
                                              ) => {
                                                const {
                                                  Defs,
                                                  Stop,
                                                  Path,
                                                  LinearGradient,
                                                  G,
                                                  Circle,
                                                  Rect,
                                                  Mask,
                                                } = SvgObj;
                                                return (props: any) => (
                                                  <Svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={14}
                                                    viewBox="0 0 20 14"
                                                    {...props}
                                                  >
                                                    <Path
                                                      fillRule="evenodd"
                                                      d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                                                      clipRule="evenodd"
                                                      {...props}
                                                    />
                                                  </Svg>
                                                );
                                              },

                                              svgOriginal: `
        <svg></svg>
      `,

                                              altura: '16px',

                                              largura: '16px',

                                              preenchimento: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.colors.primary',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A5.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.editData',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.toogles.newBikeImg',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'false',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [[]],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.maxWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Formulário de',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'EDITAR PERMISSÃO',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 40,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 40,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.SvgView1
                                                    pass={{
                                                      componentSvg: (
                                                        Svg: any,
                                                        SvgObj: any,
                                                      ) => {
                                                        const {
                                                          Defs,
                                                          Stop,
                                                          Path,
                                                          LinearGradient,
                                                          G,
                                                          Circle,
                                                          Rect,
                                                          Mask,
                                                        } = SvgObj;
                                                        return (props: any) => (
                                                          <Svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            {...props}
                                                          >
                                                            
                                                            <Path
                                                              fill="#000"
                                                              d="M14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 18a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                                                            />
                                                          </Svg>
                                                        );
                                                      },

                                                      svgOriginal: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="black"/> <path d="M14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6Z" fill="black"/> <path d="M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18C10 16.8954 10.8954 16 12 16C13.1046 16 14 16.8954 14 18Z" fill="black"/> </svg>
      `,

                                                      altura: '20px',

                                                      largura: '40px',

                                                      preenchimento: [
                                                        'transparent',
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.rightOptions',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'active',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.white',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),

                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '30px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.position({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'absolute',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.top({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '20px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.right({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '30px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: { arrayValue: [10] },
                                                }),
                                              ],

                                              variablePath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.toggles.rightOptions',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              expectedVal: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'active',
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.Pressable3
                                                    pass={{
                                                      elementProperties: [{}],

                                                      styles: [[]],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                {
                                                                  color:
                                                                    'black',
                                                                  fontSize: 12,
                                                                },
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Excluir',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      pressableFunctions: [
                                                        async (...args) =>
                                                          functions.funcGroup({
                                                            args,
                                                            pass: {
                                                              arrFunctions: [
                                                                (...args) =>
                                                                  functions.firebase.deleteDoc(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        fbInit:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.fireInit',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrRefStrings:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'permissions',
                                                                                },
                                                                              },
                                                                            ),
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'sc.A2.currData.currId',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrFuncs:
                                                                          [
                                                                            () => {},
                                                                          ],
                                                                      },
                                                                    },
                                                                  ),
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.funcGroup(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        arrFunctions:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'all.toggles.sideMenu',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'hide',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'all.toggles.activeForm',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'none',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'all.toggles.rightOptions',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'inactive',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                            },
                                                          }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '1px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.padding({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '20px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flex({ pass: { arrayValue: ['1'] } }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [
                                jsvals.varReader({
                                  pass: {
                                    path: 'true',
                                  },
                                }),
                              ],

                              expectedVal: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'true',
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.white',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowOpacity({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#000000',
                                                },
                                              }),
                                            ],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '0.2',
                                                },
                                              }),
                                            ],
                                            shadowRadius: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Nome Completo',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxtEdit
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              editPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A3.forms.editData.userName',
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData.userName',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'E-mail',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxtEdit
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              editPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A3.forms.editData.userEmail',
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData.userEmail',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Senha',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxtEdit
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              editPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A3.forms.editData.userPassword',
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData.userPassword',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Ponto de Retirada',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <customs.IptPicker
                                                    pass={{
                                                      pathList: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.lists.pickerList1',
                                                          },
                                                        }),
                                                      ],
                                                      pathEdit: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData.locationName',
                                                          },
                                                        }),
                                                      ],
                                                      arrFuncs: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A3.forms.editData.locationId',
                                                                  },
                                                                }),
                                                              ],
                                                              value:
                                                                jsvals.argReader(
                                                                  {
                                                                    pass: {
                                                                      argPath:
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '#1',
                                                                            },
                                                                          },
                                                                        ),
                                                                      args,
                                                                    },
                                                                  },
                                                                ),
                                                            },
                                                          }),
                                                        (...args) =>
                                                          customs.custom03({
                                                            args,
                                                            pass: {
                                                              ipt1: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A3.lists.pickerList1',
                                                                  },
                                                                }),
                                                              ],
                                                              ipt2: 'id',
                                                              ipt3: [
                                                                jsvals.argReader(
                                                                  {
                                                                    pass: {
                                                                      argPath:
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '#1',
                                                                            },
                                                                          },
                                                                        ),
                                                                      args,
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              ipt4: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A3.forms.editData.locationName',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.argReader(
                                                                            {
                                                                              pass: {
                                                                                argPath:
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '#1.label',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                args,
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                            },
                                                          }),
                                                      ],
                                                      pathReturn: [''],
                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#F5D3D3',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '3px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'all.toggles.emptyField',
                                          },
                                        }),
                                      ],

                                      expectedVal: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'yes',
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'red',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '11px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Preencha todos os campos para prosseguir',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: ['#000'],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: ['0.4px'],
                                            shadowRadius: ['20px'],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: ['#000'],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: ['0.4px'],
                                            shadowRadius: ['20px'],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: ['white'],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Salvar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.condition({
                                            args,
                                            pass: {
                                              conditionPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'sc.A3.forms.editData.userName',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              expectedValue: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: '',
                                                  },
                                                }),
                                              ],
                                              functionsTrue: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'yes',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                              functionsFalse: [
                                                async (...args) =>
                                                  functions.condition({
                                                    args,
                                                    pass: {
                                                      conditionPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.A3.forms.editData.userEmail',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      expectedValue: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues: '',
                                                          },
                                                        }),
                                                      ],
                                                      functionsTrue: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'all.toggles.emptyField',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'yes',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                      functionsFalse: [
                                                        async (...args) =>
                                                          functions.condition({
                                                            args,
                                                            pass: {
                                                              conditionPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'sc.A3.forms.editData.userPassword',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              expectedValue: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      '',
                                                                  },
                                                                }),
                                                              ],
                                                              functionsTrue: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'all.toggles.emptyField',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'yes',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              functionsFalse: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.condition(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        conditionPath:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'sc.A3.forms.editData.locationId',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        expectedValue:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    '',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        functionsTrue:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                'all.toggles.emptyField',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'yes',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                        functionsFalse:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.firebase.updateDoc(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    fbInit:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'all.fireInit',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    arrRefStrings:
                                                                                      [
                                                                                        jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                'permissions',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'sc.A3.currData.docID',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    arrPathData:
                                                                                      [
                                                                                        jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                'sc.A3.forms.editData',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    arrFuncs:
                                                                                      [
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'all.toggles.emptyField',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'no',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.varReader(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          path: jsvals.j8(
                                                                                                            {
                                                                                                              pass: {
                                                                                                                propertieValues:
                                                                                                                  'all.toggles.sideMenu',
                                                                                                              },
                                                                                                            },
                                                                                                          ),
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'hide',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.varReader(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          path: jsvals.j8(
                                                                                                            {
                                                                                                              pass: {
                                                                                                                propertieValues:
                                                                                                                  'all.toggles.activeForm',
                                                                                                              },
                                                                                                            },
                                                                                                          ),
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'none',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A3.forms.editData',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          '',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                      ],
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                        args,
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              args,
                                                            },
                                                          }),
                                                      ],
                                                      args,
                                                    },
                                                  }),
                                              ],
                                              args,
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Cancelar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.toggles.sideMenu',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'hide',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.toggles.activeForm',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'none',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A3.forms.iptsChanges',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: '',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A3.forms.editData',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: '',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'all.toggles.emptyField',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'no',
                                                },
                                              }),
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.flex({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '1px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.ice',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadows({
                          pass: {
                            shadowColor: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '#000000',
                                },
                              }),
                            ],
                            shadowOffset: ['{width: 0, height: 0}'],
                            shadowOpacity: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '0.2',
                                },
                              }),
                            ],
                            shadowRadius: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'all.toggles.activeForm',
                              },
                            }),
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'a5profile',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'space-between',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flexDirection({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'row',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '60px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingHorizontal({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 10,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '2px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'lightgrey',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.SvgView1
                                            pass={{
                                              componentSvg: (
                                                Svg: any,
                                                SvgObj: any,
                                              ) => {
                                                const {
                                                  Defs,
                                                  Stop,
                                                  Path,
                                                  LinearGradient,
                                                  G,
                                                  Circle,
                                                  Rect,
                                                  Mask,
                                                } = SvgObj;
                                                return (props: any) => (
                                                  <Svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={14}
                                                    viewBox="0 0 20 14"
                                                    {...props}
                                                  >
                                                    <Path
                                                      fillRule="evenodd"
                                                      d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                                                      clipRule="evenodd"
                                                      {...props}
                                                    />
                                                  </Svg>
                                                );
                                              },

                                              svgOriginal: `
        <svg></svg>
      `,

                                              altura: '16px',

                                              largura: '16px',

                                              preenchimento: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.colors.primary',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A5.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.editData',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.toogles.newBikeImg',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'false',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [[]],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.maxWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Formulário de',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'PERFIL DE USUÁRIO',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '1px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),
                        (...args: any) => (
                          <Elements.ScrollBar
                            pass={{
                              styles: [],
                              arrProps: [],
                              arrItems: [
                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.ice',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.white',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.paddingHorizontal({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '20px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.paddingVertical({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '20px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '15px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.shadowRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '5px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.shadowOpacity({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '5px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.shadows({
                                                  pass: {
                                                    shadowColor: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '#000000',
                                                        },
                                                      }),
                                                    ],
                                                    shadowOffset: [
                                                      '{width: 0, height: 0}',
                                                    ],
                                                    shadowOpacity: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '0.2',
                                                        },
                                                      }),
                                                    ],
                                                    shadowRadius: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: 'true',
                                                  },
                                                }),
                                              ],

                                              expectedVal: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'true',
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.ImageBox
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.borderRadius({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues: 75,
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'white',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '120px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '120px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.alignSelf({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'center',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      URIvariablePath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A5.forms.editData.userImage',
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Nome',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.IptTxtEdit
                                                            pass={{
                                                              propsArray: [{}],

                                                              stylesArray: [
                                                                stls.borderBottomWidth(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '1px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderBottomColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.grey',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              editPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A5.forms.editData.userName',
                                                                  },
                                                                }),
                                                              ],

                                                              funcsArray: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A5.forms.editData.userName',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.argReader(
                                                                            {
                                                                              pass: {
                                                                                argPath:
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '#0',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                args,
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Email',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.CustomListener
                                                            pass={{
                                                              variable: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A5.forms.editData.userEmail',
                                                                  },
                                                                }),
                                                              ],
                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            '#666',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.fontSize(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.textSizes.small',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'sc.A5.forms.editData.userEmail',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],
                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'CPF',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.IptTxtEdit
                                                            pass={{
                                                              propsArray: [{}],

                                                              stylesArray: [
                                                                stls.borderBottomWidth(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '1px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderBottomColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.grey',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              editPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A5.forms.editData.userCPF',
                                                                  },
                                                                }),
                                                              ],

                                                              funcsArray: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A5.forms.editData.userCPF',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.argReader(
                                                                            {
                                                                              pass: {
                                                                                argPath:
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '#0',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                args,
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'RG',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.IptTxtEdit
                                                            pass={{
                                                              propsArray: [{}],

                                                              stylesArray: [
                                                                stls.borderBottomWidth(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '1px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderBottomColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.grey',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              editPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A5.forms.editData.userRG',
                                                                  },
                                                                }),
                                                              ],

                                                              funcsArray: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A5.forms.editData.userRG',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.argReader(
                                                                            {
                                                                              pass: {
                                                                                argPath:
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '#0',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                args,
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Telefone',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.IptTxtEdit
                                                            pass={{
                                                              propsArray: [{}],

                                                              stylesArray: [
                                                                stls.borderBottomWidth(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '1px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderBottomColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.grey',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              editPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A5.forms.editData.userPhone',
                                                                  },
                                                                }),
                                                              ],

                                                              funcsArray: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A5.forms.editData.userPhone',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.argReader(
                                                                            {
                                                                              pass: {
                                                                                argPath:
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '#0',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                args,
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Endereço',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.AutocompleteGooglePlaces
                                                            pass={{
                                                              apiKey:
                                                                'AIzaSyBJ1MNdqlCOmPXK3taPtmuEJbsrmtiUDxU',
                                                              pathList: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A5.forms.editData.userAddress',
                                                                  },
                                                                }),
                                                              ],
                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                {
                                                                  backgroundColor:
                                                                    'white',
                                                                  justifyContent:
                                                                    'center',
                                                                  minHeight: 22,
                                                                  width: '100%',
                                                                },
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.DynView
                                                                    pass={{
                                                                      elementsProperties:
                                                                        [{}],

                                                                      styles: [
                                                                        stls.flexDirection(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'row',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],

                                                                      variablePath:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'all.toggles.radioButton',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      expectedVal:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'unselected',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      childrenItems:
                                                                        [
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.Pressable3
                                                                              pass={{
                                                                                elementProperties:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                styles:
                                                                                  [
                                                                                    stls.height(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '100%',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.flexDirection(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'row',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.alignItems(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'center',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.width(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '85px',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                childrenItems:
                                                                                  [
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.DynView
                                                                                        pass={{
                                                                                          elementsProperties:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          styles:
                                                                                            [
                                                                                              stls.width(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '18px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.height(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '18px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderColor(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.grey',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderWidth(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '1px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderRadius(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '10px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.backgroundColor(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.ice',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          variablePath:
                                                                                            [],

                                                                                          expectedVal:
                                                                                            [],

                                                                                          childrenItems:
                                                                                            [
                                                                                              () => (
                                                                                                <>

                                                                                                </>
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),

                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.DynView
                                                                                        pass={{
                                                                                          elementsProperties:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          styles:
                                                                                            [
                                                                                              stls.width(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '8px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          variablePath:
                                                                                            [],

                                                                                          expectedVal:
                                                                                            [],

                                                                                          childrenItems:
                                                                                            [
                                                                                              () => (
                                                                                                <>

                                                                                                </>
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.Text
                                                                                        pass={{
                                                                                          arrProps:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          arrStyles:
                                                                                            [
                                                                                              stls.color(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.grey2',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontSize(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.textSizes.small',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontWeight(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                'bold',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          children:
                                                                                            [
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A5.male',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                  ],

                                                                                pressableFunctions:
                                                                                  [
                                                                                    async (
                                                                                      ...args
                                                                                    ) =>
                                                                                      functions.setVar(
                                                                                        {
                                                                                          args,
                                                                                          pass: {
                                                                                            keyPath:
                                                                                              [
                                                                                                jsvals.j8(
                                                                                                  {
                                                                                                    pass: {
                                                                                                      propertieValues:
                                                                                                        'all.toggles.radioButton',
                                                                                                    },
                                                                                                  },
                                                                                                ),
                                                                                              ],
                                                                                            value:
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'male',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                    async (
                                                                                      ...args
                                                                                    ) =>
                                                                                      functions.setVar(
                                                                                        {
                                                                                          args,
                                                                                          pass: {
                                                                                            keyPath:
                                                                                              [
                                                                                                jsvals.j8(
                                                                                                  {
                                                                                                    pass: {
                                                                                                      propertieValues:
                                                                                                        'sc.A5.forms.editData.userGender',
                                                                                                    },
                                                                                                  },
                                                                                                ),
                                                                                              ],
                                                                                            value:
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A5.male',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),

                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.DynView
                                                                              pass={{
                                                                                elementsProperties:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                styles:
                                                                                  [
                                                                                    stls.width(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '25px',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                variablePath:
                                                                                  [],

                                                                                expectedVal:
                                                                                  [],

                                                                                childrenItems:
                                                                                  [
                                                                                    () => (
                                                                                      <>

                                                                                      </>
                                                                                    ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.Pressable3
                                                                              pass={{
                                                                                elementProperties:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                styles:
                                                                                  [
                                                                                    stls.height(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '100%',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.flexDirection(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'row',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.alignItems(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'center',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.width(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '85px',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                childrenItems:
                                                                                  [
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.DynView
                                                                                        pass={{
                                                                                          elementsProperties:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          styles:
                                                                                            [
                                                                                              stls.width(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '18px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.height(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '18px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderColor(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.grey',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderWidth(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '1px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderRadius(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '10px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.backgroundColor(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.ice',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          variablePath:
                                                                                            [],

                                                                                          expectedVal:
                                                                                            [],

                                                                                          childrenItems:
                                                                                            [
                                                                                              () => (
                                                                                                <>

                                                                                                </>
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),

                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.DynView
                                                                                        pass={{
                                                                                          elementsProperties:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          styles:
                                                                                            [
                                                                                              stls.width(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '8px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          variablePath:
                                                                                            [],

                                                                                          expectedVal:
                                                                                            [],

                                                                                          childrenItems:
                                                                                            [
                                                                                              () => (
                                                                                                <>

                                                                                                </>
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.Text
                                                                                        pass={{
                                                                                          arrProps:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          arrStyles:
                                                                                            [
                                                                                              stls.color(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.grey2',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontSize(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.textSizes.small',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontWeight(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                'bold',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          children:
                                                                                            [
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A5.female',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                  ],

                                                                                pressableFunctions:
                                                                                  [
                                                                                    async (
                                                                                      ...args
                                                                                    ) =>
                                                                                      functions.setVar(
                                                                                        {
                                                                                          args,
                                                                                          pass: {
                                                                                            keyPath:
                                                                                              [
                                                                                                jsvals.j8(
                                                                                                  {
                                                                                                    pass: {
                                                                                                      propertieValues:
                                                                                                        'all.toggles.radioButton',
                                                                                                    },
                                                                                                  },
                                                                                                ),
                                                                                              ],
                                                                                            value:
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'female',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                    async (
                                                                                      ...args
                                                                                    ) =>
                                                                                      functions.setVar(
                                                                                        {
                                                                                          args,
                                                                                          pass: {
                                                                                            keyPath:
                                                                                              [
                                                                                                jsvals.j8(
                                                                                                  {
                                                                                                    pass: {
                                                                                                      propertieValues:
                                                                                                        'sc.A5.forms.editData.userGender',
                                                                                                    },
                                                                                                  },
                                                                                                ),
                                                                                              ],
                                                                                            value:
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A5.female',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),

                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.DynView
                                                                    pass={{
                                                                      elementsProperties:
                                                                        [{}],

                                                                      styles: [
                                                                        stls.flexDirection(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'row',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],

                                                                      variablePath:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'all.toggles.radioButton',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      expectedVal:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'male',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      childrenItems:
                                                                        [
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.Pressable3
                                                                              pass={{
                                                                                elementProperties:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                styles:
                                                                                  [
                                                                                    stls.height(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '100%',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.flexDirection(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'row',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.alignItems(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'center',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.width(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '85px',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                childrenItems:
                                                                                  [
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.DynView
                                                                                        pass={{
                                                                                          elementsProperties:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          styles:
                                                                                            [
                                                                                              stls.backgroundColor(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.white',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.width(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '18px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.height(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '18px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderColor(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.primary',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderWidth(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '1px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderRadius(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '10px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.alignItems(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                'center',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.justifyContent(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                'center',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          variablePath:
                                                                                            [],

                                                                                          expectedVal:
                                                                                            [],

                                                                                          childrenItems:
                                                                                            [
                                                                                              (
                                                                                                ...args: any
                                                                                              ) => (
                                                                                                <Elements.DynView
                                                                                                  pass={{
                                                                                                    elementsProperties:
                                                                                                      [
                                                                                                        {},
                                                                                                      ],

                                                                                                    styles:
                                                                                                      [
                                                                                                        stls.backgroundColor(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              arrayValue:
                                                                                                                [
                                                                                                                  jsvals.varReader(
                                                                                                                    {
                                                                                                                      pass: {
                                                                                                                        path: jsvals.j8(
                                                                                                                          {
                                                                                                                            pass: {
                                                                                                                              propertieValues:
                                                                                                                                'all.colors.primary',
                                                                                                                            },
                                                                                                                          },
                                                                                                                        ),
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                                ],
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                        stls.width(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              arrayValue:
                                                                                                                [
                                                                                                                  jsvals.j8(
                                                                                                                    {
                                                                                                                      pass: {
                                                                                                                        propertieValues:
                                                                                                                          '12px',
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                                ],
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                        stls.height(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              arrayValue:
                                                                                                                [
                                                                                                                  jsvals.j8(
                                                                                                                    {
                                                                                                                      pass: {
                                                                                                                        propertieValues:
                                                                                                                          '12px',
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                                ],
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                        stls.borderRadius(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              arrayValue:
                                                                                                                [
                                                                                                                  jsvals.j8(
                                                                                                                    {
                                                                                                                      pass: {
                                                                                                                        propertieValues:
                                                                                                                          '10px',
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                                ],
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],

                                                                                                    variablePath:
                                                                                                      [],

                                                                                                    expectedVal:
                                                                                                      [],

                                                                                                    childrenItems:
                                                                                                      [
                                                                                                        () => (
                                                                                                          <>

                                                                                                          </>
                                                                                                        ),
                                                                                                      ],

                                                                                                    args,
                                                                                                  }}
                                                                                                />
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),

                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.DynView
                                                                                        pass={{
                                                                                          elementsProperties:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          styles:
                                                                                            [
                                                                                              stls.width(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '8px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          variablePath:
                                                                                            [],

                                                                                          expectedVal:
                                                                                            [],

                                                                                          childrenItems:
                                                                                            [
                                                                                              () => (
                                                                                                <>

                                                                                                </>
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.Text
                                                                                        pass={{
                                                                                          arrProps:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          arrStyles:
                                                                                            [
                                                                                              stls.color(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.grey2',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontSize(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.textSizes.small',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontWeight(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                'bold',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          children:
                                                                                            [
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A5.male',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                  ],

                                                                                pressableFunctions:
                                                                                  [
                                                                                    () =>
                                                                                      console.log(
                                                                                        'Você Clicou!',
                                                                                      ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),

                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.DynView
                                                                              pass={{
                                                                                elementsProperties:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                styles:
                                                                                  [
                                                                                    stls.width(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '25px',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                variablePath:
                                                                                  [],

                                                                                expectedVal:
                                                                                  [],

                                                                                childrenItems:
                                                                                  [
                                                                                    () => (
                                                                                      <>

                                                                                      </>
                                                                                    ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.Pressable3
                                                                              pass={{
                                                                                elementProperties:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                styles:
                                                                                  [
                                                                                    stls.height(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '100%',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.flexDirection(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'row',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.alignItems(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'center',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.width(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '85px',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                childrenItems:
                                                                                  [
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.DynView
                                                                                        pass={{
                                                                                          elementsProperties:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          styles:
                                                                                            [
                                                                                              stls.width(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '18px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.height(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '18px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderColor(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.grey',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderWidth(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '1px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderRadius(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '10px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.backgroundColor(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.ice',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          variablePath:
                                                                                            [],

                                                                                          expectedVal:
                                                                                            [],

                                                                                          childrenItems:
                                                                                            [
                                                                                              () => (
                                                                                                <>

                                                                                                </>
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),

                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.DynView
                                                                                        pass={{
                                                                                          elementsProperties:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          styles:
                                                                                            [
                                                                                              stls.width(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '8px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          variablePath:
                                                                                            [],

                                                                                          expectedVal:
                                                                                            [],

                                                                                          childrenItems:
                                                                                            [
                                                                                              () => (
                                                                                                <>

                                                                                                </>
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.Text
                                                                                        pass={{
                                                                                          arrProps:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          arrStyles:
                                                                                            [
                                                                                              stls.color(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.grey2',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontSize(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.textSizes.small',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontWeight(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                'bold',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          children:
                                                                                            [
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A5.female',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                  ],

                                                                                pressableFunctions:
                                                                                  [
                                                                                    async (
                                                                                      ...args
                                                                                    ) =>
                                                                                      functions.setVar(
                                                                                        {
                                                                                          args,
                                                                                          pass: {
                                                                                            keyPath:
                                                                                              [
                                                                                                jsvals.j8(
                                                                                                  {
                                                                                                    pass: {
                                                                                                      propertieValues:
                                                                                                        'all.toggles.radioButton',
                                                                                                    },
                                                                                                  },
                                                                                                ),
                                                                                              ],
                                                                                            value:
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'female',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                    async (
                                                                                      ...args
                                                                                    ) =>
                                                                                      functions.setVar(
                                                                                        {
                                                                                          args,
                                                                                          pass: {
                                                                                            keyPath:
                                                                                              [
                                                                                                jsvals.j8(
                                                                                                  {
                                                                                                    pass: {
                                                                                                      propertieValues:
                                                                                                        'sc.A5.forms.editData.userGender',
                                                                                                    },
                                                                                                  },
                                                                                                ),
                                                                                              ],
                                                                                            value:
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A5.female',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),

                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.DynView
                                                                    pass={{
                                                                      elementsProperties:
                                                                        [{}],

                                                                      styles: [
                                                                        stls.flexDirection(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'row',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],

                                                                      variablePath:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'all.toggles.radioButton',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      expectedVal:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'female',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      childrenItems:
                                                                        [
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.Pressable3
                                                                              pass={{
                                                                                elementProperties:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                styles:
                                                                                  [
                                                                                    stls.height(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '100%',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.flexDirection(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'row',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.alignItems(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'center',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.width(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '85px',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                childrenItems:
                                                                                  [
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.DynView
                                                                                        pass={{
                                                                                          elementsProperties:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          styles:
                                                                                            [
                                                                                              stls.width(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '18px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.height(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '18px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderColor(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.grey',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderWidth(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '1px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderRadius(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '10px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.backgroundColor(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.ice',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          variablePath:
                                                                                            [],

                                                                                          expectedVal:
                                                                                            [],

                                                                                          childrenItems:
                                                                                            [
                                                                                              () => (
                                                                                                <>

                                                                                                </>
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),

                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.DynView
                                                                                        pass={{
                                                                                          elementsProperties:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          styles:
                                                                                            [
                                                                                              stls.width(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '8px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          variablePath:
                                                                                            [],

                                                                                          expectedVal:
                                                                                            [],

                                                                                          childrenItems:
                                                                                            [
                                                                                              () => (
                                                                                                <>

                                                                                                </>
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.Text
                                                                                        pass={{
                                                                                          arrProps:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          arrStyles:
                                                                                            [
                                                                                              stls.color(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.grey2',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontSize(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.textSizes.small',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontWeight(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                'bold',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          children:
                                                                                            [
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A5.male',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                  ],

                                                                                pressableFunctions:
                                                                                  [
                                                                                    async (
                                                                                      ...args
                                                                                    ) =>
                                                                                      functions.setVar(
                                                                                        {
                                                                                          args,
                                                                                          pass: {
                                                                                            keyPath:
                                                                                              [
                                                                                                jsvals.j8(
                                                                                                  {
                                                                                                    pass: {
                                                                                                      propertieValues:
                                                                                                        'all.toggles.radioButton',
                                                                                                    },
                                                                                                  },
                                                                                                ),
                                                                                              ],
                                                                                            value:
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'male',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                    async (
                                                                                      ...args
                                                                                    ) =>
                                                                                      functions.setVar(
                                                                                        {
                                                                                          args,
                                                                                          pass: {
                                                                                            keyPath:
                                                                                              [
                                                                                                jsvals.j8(
                                                                                                  {
                                                                                                    pass: {
                                                                                                      propertieValues:
                                                                                                        'sc.A5.forms.editData.userGender',
                                                                                                    },
                                                                                                  },
                                                                                                ),
                                                                                              ],
                                                                                            value:
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A5.male',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),

                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.DynView
                                                                              pass={{
                                                                                elementsProperties:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                styles:
                                                                                  [
                                                                                    stls.width(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '25px',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                variablePath:
                                                                                  [],

                                                                                expectedVal:
                                                                                  [],

                                                                                childrenItems:
                                                                                  [
                                                                                    () => (
                                                                                      <>

                                                                                      </>
                                                                                    ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.Pressable3
                                                                              pass={{
                                                                                elementProperties:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                styles:
                                                                                  [
                                                                                    stls.height(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '100%',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.flexDirection(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'row',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.alignItems(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'center',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.width(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '85px',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                childrenItems:
                                                                                  [
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.DynView
                                                                                        pass={{
                                                                                          elementsProperties:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          styles:
                                                                                            [
                                                                                              stls.backgroundColor(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.white',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.width(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '18px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.height(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '18px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderColor(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.primary',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderWidth(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '1px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.borderRadius(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '10px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.alignItems(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                'center',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.justifyContent(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                'center',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          variablePath:
                                                                                            [],

                                                                                          expectedVal:
                                                                                            [],

                                                                                          childrenItems:
                                                                                            [
                                                                                              (
                                                                                                ...args: any
                                                                                              ) => (
                                                                                                <Elements.DynView
                                                                                                  pass={{
                                                                                                    elementsProperties:
                                                                                                      [
                                                                                                        {},
                                                                                                      ],

                                                                                                    styles:
                                                                                                      [
                                                                                                        stls.backgroundColor(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              arrayValue:
                                                                                                                [
                                                                                                                  jsvals.varReader(
                                                                                                                    {
                                                                                                                      pass: {
                                                                                                                        path: jsvals.j8(
                                                                                                                          {
                                                                                                                            pass: {
                                                                                                                              propertieValues:
                                                                                                                                'all.colors.primary',
                                                                                                                            },
                                                                                                                          },
                                                                                                                        ),
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                                ],
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                        stls.width(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              arrayValue:
                                                                                                                [
                                                                                                                  jsvals.j8(
                                                                                                                    {
                                                                                                                      pass: {
                                                                                                                        propertieValues:
                                                                                                                          '12px',
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                                ],
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                        stls.height(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              arrayValue:
                                                                                                                [
                                                                                                                  jsvals.j8(
                                                                                                                    {
                                                                                                                      pass: {
                                                                                                                        propertieValues:
                                                                                                                          '12px',
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                                ],
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                        stls.borderRadius(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              arrayValue:
                                                                                                                [
                                                                                                                  jsvals.j8(
                                                                                                                    {
                                                                                                                      pass: {
                                                                                                                        propertieValues:
                                                                                                                          '10px',
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                                ],
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],

                                                                                                    variablePath:
                                                                                                      [],

                                                                                                    expectedVal:
                                                                                                      [],

                                                                                                    childrenItems:
                                                                                                      [
                                                                                                        () => (
                                                                                                          <>

                                                                                                          </>
                                                                                                        ),
                                                                                                      ],

                                                                                                    args,
                                                                                                  }}
                                                                                                />
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),

                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.DynView
                                                                                        pass={{
                                                                                          elementsProperties:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          styles:
                                                                                            [
                                                                                              stls.width(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                '8px',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          variablePath:
                                                                                            [],

                                                                                          expectedVal:
                                                                                            [],

                                                                                          childrenItems:
                                                                                            [
                                                                                              () => (
                                                                                                <>

                                                                                                </>
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.Text
                                                                                        pass={{
                                                                                          arrProps:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          arrStyles:
                                                                                            [
                                                                                              stls.color(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.grey2',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontSize(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.textSizes.small',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontWeight(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                'bold',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          children:
                                                                                            [
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A5.female',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                  ],

                                                                                pressableFunctions:
                                                                                  [
                                                                                    () =>
                                                                                      console.log(
                                                                                        'Você Clicou!',
                                                                                      ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Revise os documentos fornecidos pelo usuário e indique se estão corretos.',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [[]],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.colors.grey2',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.fontSize(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.textSizes.small',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'Status',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),

                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.DynView
                                                                    pass={{
                                                                      elementsProperties:
                                                                        [{}],

                                                                      styles: [
                                                                        stls.backgroundColor(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.varReader(
                                                                                    {
                                                                                      pass: {
                                                                                        path: jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                'all.colors.white',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                        stls.borderBottomWidth(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '1px',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                        stls.borderBottomColor(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.varReader(
                                                                                    {
                                                                                      pass: {
                                                                                        path: jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                'all.colors.grey',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],

                                                                      variablePath:
                                                                        [],

                                                                      expectedVal:
                                                                        [],

                                                                      childrenItems:
                                                                        [
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.CustomListener
                                                                              pass={{
                                                                                variable:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'sc.A5.currData.userStatus',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                                childrenItems:
                                                                                  [
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <customs.IptPicker
                                                                                        pass={{
                                                                                          pathList:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'mock.A5.lists.pickerStatus',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                          pathEdit:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'sc.A5.forms.editData.userStatus',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                          arrFuncs:
                                                                                            [
                                                                                              async (
                                                                                                ...args
                                                                                              ) =>
                                                                                                functions.setVar(
                                                                                                  {
                                                                                                    args,
                                                                                                    pass: {
                                                                                                      keyPath:
                                                                                                        [
                                                                                                          jsvals.j8(
                                                                                                            {
                                                                                                              pass: {
                                                                                                                propertieValues:
                                                                                                                  'sc.A5.forms.editData.userStatus',
                                                                                                              },
                                                                                                            },
                                                                                                          ),
                                                                                                        ],
                                                                                                      value:
                                                                                                        jsvals.argReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              argPath:
                                                                                                                jsvals.j8(
                                                                                                                  {
                                                                                                                    pass: {
                                                                                                                      propertieValues:
                                                                                                                        '#1',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ),
                                                                                                              args,
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                    },
                                                                                                  },
                                                                                                ),
                                                                                            ],
                                                                                          pathReturn:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'sc.A5.forms.editData.userStatus',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                  ],
                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '5px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.white',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.paddingHorizontal({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '20px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.paddingVertical({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '20px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '15px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.shadowRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '5px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.shadowOpacity({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '5px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.shadows({
                                                  pass: {
                                                    shadowColor: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '#000000',
                                                        },
                                                      }),
                                                    ],
                                                    shadowOffset: [
                                                      '{width: 0, height: 0}',
                                                    ],
                                                    shadowOpacity: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '0.2',
                                                        },
                                                      }),
                                                    ],
                                                    shadowRadius: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'start',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.fontSize({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.textSizes.small',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.fontWeight({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'bold',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey2',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'Bloquear Usuário',
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.padding({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.alignItems({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'center',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderRadius({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '15px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderWidth({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '1px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.alignSelf({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'center',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.IptTxtEdit
                                                            pass={{
                                                              propsArray: [
                                                                (
                                                                  ...args: any
                                                                ) =>
                                                                  props.pt24({
                                                                    pass: {
                                                                      A: [
                                                                        'true',
                                                                      ],
                                                                    },
                                                                  }),
                                                                (
                                                                  ...args: any
                                                                ) =>
                                                                  props.pt23({
                                                                    pass: {
                                                                      A: ['4'],
                                                                    },
                                                                  }),
                                                              ],

                                                              stylesArray: [
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              editPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A5.forms.editData.reasonBlocking',
                                                                  },
                                                                }),
                                                              ],

                                                              funcsArray: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A5.forms.editData.reasonBlocking',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.argReader(
                                                                            {
                                                                              pass: {
                                                                                argPath:
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '#0',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                args,
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '#F5D3D3',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.paddingHorizontal({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.paddingVertical({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '3px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '25px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'all.toggles.emptyField',
                                                  },
                                                }),
                                              ],

                                              expectedVal: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'yes',
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'red',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.textAlign({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'center',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.fontSize({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '11px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'Preencha todos os campos para prosseguir',
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 40,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.padding({
                                                  pass: {
                                                    arrayValue: ['10px'],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '15px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '120px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '25px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.shadows({
                                                  pass: {
                                                    shadowColor: ['#000'],
                                                    shadowOffset: [
                                                      '{width: 0, height: 0}',
                                                    ],
                                                    shadowOpacity: ['0.4px'],
                                                    shadowRadius: ['20px'],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              'white',
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'Salvar',
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                async (...args) =>
                                                  functions.condition({
                                                    args,
                                                    pass: {
                                                      conditionPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.A5.forms.editData.userName',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      expectedValue: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues: '',
                                                          },
                                                        }),
                                                      ],
                                                      functionsTrue: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'all.toggles.emptyField',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'yes',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                      functionsFalse: [
                                                        async (...args) =>
                                                          functions.condition({
                                                            args,
                                                            pass: {
                                                              conditionPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'sc.A5.forms.editData.userCPF',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              expectedValue: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      '',
                                                                  },
                                                                }),
                                                              ],
                                                              functionsTrue: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'all.toggles.emptyField',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'yes',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              functionsFalse: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.condition(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        conditionPath:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'sc.A5.forms.editData.userRG',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        expectedValue:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    '',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        functionsTrue:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                'all.toggles.emptyField',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'yes',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                        functionsFalse:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.condition(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    conditionPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'sc.A5.forms.editData.userPhone',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    expectedValue:
                                                                                      [
                                                                                        jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                '',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    functionsTrue:
                                                                                      [
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'all.toggles.emptyField',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'yes',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                      ],
                                                                                    functionsFalse:
                                                                                      [
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.condition(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                conditionPath:
                                                                                                  [
                                                                                                    jsvals.varReader(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          path: jsvals.j8(
                                                                                                            {
                                                                                                              pass: {
                                                                                                                propertieValues:
                                                                                                                  'sc.A5.forms.editData.userAddress',
                                                                                                              },
                                                                                                            },
                                                                                                          ),
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                expectedValue:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            '',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                functionsTrue:
                                                                                                  [
                                                                                                    async (
                                                                                                      ...args
                                                                                                    ) =>
                                                                                                      functions.setVar(
                                                                                                        {
                                                                                                          args,
                                                                                                          pass: {
                                                                                                            keyPath:
                                                                                                              [
                                                                                                                jsvals.j8(
                                                                                                                  {
                                                                                                                    pass: {
                                                                                                                      propertieValues:
                                                                                                                        'all.toggles.emptyField',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ),
                                                                                                              ],
                                                                                                            value:
                                                                                                              jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'yes',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                          },
                                                                                                        },
                                                                                                      ),
                                                                                                  ],
                                                                                                functionsFalse:
                                                                                                  [
                                                                                                    async (
                                                                                                      ...args
                                                                                                    ) =>
                                                                                                      functions.condition(
                                                                                                        {
                                                                                                          args,
                                                                                                          pass: {
                                                                                                            conditionPath:
                                                                                                              [
                                                                                                                jsvals.varReader(
                                                                                                                  {
                                                                                                                    pass: {
                                                                                                                      path: jsvals.j8(
                                                                                                                        {
                                                                                                                          pass: {
                                                                                                                            propertieValues:
                                                                                                                              'sc.A5.forms.editData.userGender',
                                                                                                                          },
                                                                                                                        },
                                                                                                                      ),
                                                                                                                    },
                                                                                                                  },
                                                                                                                ),
                                                                                                              ],
                                                                                                            expectedValue:
                                                                                                              [
                                                                                                                jsvals.j8(
                                                                                                                  {
                                                                                                                    pass: {
                                                                                                                      propertieValues:
                                                                                                                        '',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ),
                                                                                                              ],
                                                                                                            functionsTrue:
                                                                                                              [
                                                                                                                async (
                                                                                                                  ...args
                                                                                                                ) =>
                                                                                                                  functions.setVar(
                                                                                                                    {
                                                                                                                      args,
                                                                                                                      pass: {
                                                                                                                        keyPath:
                                                                                                                          [
                                                                                                                            jsvals.j8(
                                                                                                                              {
                                                                                                                                pass: {
                                                                                                                                  propertieValues:
                                                                                                                                    'all.toggles.emptyField',
                                                                                                                                },
                                                                                                                              },
                                                                                                                            ),
                                                                                                                          ],
                                                                                                                        value:
                                                                                                                          jsvals.j8(
                                                                                                                            {
                                                                                                                              pass: {
                                                                                                                                propertieValues:
                                                                                                                                  'yes',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ),
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                              ],
                                                                                                            functionsFalse:
                                                                                                              [
                                                                                                                async (
                                                                                                                  ...args
                                                                                                                ) =>
                                                                                                                  functions.condition(
                                                                                                                    {
                                                                                                                      args,
                                                                                                                      pass: {
                                                                                                                        conditionPath:
                                                                                                                          [
                                                                                                                            jsvals.varReader(
                                                                                                                              {
                                                                                                                                pass: {
                                                                                                                                  path: jsvals.j8(
                                                                                                                                    {
                                                                                                                                      pass: {
                                                                                                                                        propertieValues:
                                                                                                                                          'sc.A5.forms.editData.userStatus',
                                                                                                                                      },
                                                                                                                                    },
                                                                                                                                  ),
                                                                                                                                },
                                                                                                                              },
                                                                                                                            ),
                                                                                                                          ],
                                                                                                                        expectedValue:
                                                                                                                          [
                                                                                                                            jsvals.j8(
                                                                                                                              {
                                                                                                                                pass: {
                                                                                                                                  propertieValues:
                                                                                                                                    '',
                                                                                                                                },
                                                                                                                              },
                                                                                                                            ),
                                                                                                                          ],
                                                                                                                        functionsTrue:
                                                                                                                          [
                                                                                                                            async (
                                                                                                                              ...args
                                                                                                                            ) =>
                                                                                                                              functions.setVar(
                                                                                                                                {
                                                                                                                                  args,
                                                                                                                                  pass: {
                                                                                                                                    keyPath:
                                                                                                                                      [
                                                                                                                                        jsvals.j8(
                                                                                                                                          {
                                                                                                                                            pass: {
                                                                                                                                              propertieValues:
                                                                                                                                                'all.toggles.emptyField',
                                                                                                                                            },
                                                                                                                                          },
                                                                                                                                        ),
                                                                                                                                      ],
                                                                                                                                    value:
                                                                                                                                      jsvals.j8(
                                                                                                                                        {
                                                                                                                                          pass: {
                                                                                                                                            propertieValues:
                                                                                                                                              'yes',
                                                                                                                                          },
                                                                                                                                        },
                                                                                                                                      ),
                                                                                                                                  },
                                                                                                                                },
                                                                                                                              ),
                                                                                                                          ],
                                                                                                                        functionsFalse:
                                                                                                                          [
                                                                                                                            async (
                                                                                                                              ...args
                                                                                                                            ) =>
                                                                                                                              functions.firebase.updateDoc(
                                                                                                                                {
                                                                                                                                  args,
                                                                                                                                  pass: {
                                                                                                                                    fbInit:
                                                                                                                                      [
                                                                                                                                        jsvals.varReader(
                                                                                                                                          {
                                                                                                                                            pass: {
                                                                                                                                              path: jsvals.j8(
                                                                                                                                                {
                                                                                                                                                  pass: {
                                                                                                                                                    propertieValues:
                                                                                                                                                      'all.fireInit',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                              ),
                                                                                                                                            },
                                                                                                                                          },
                                                                                                                                        ),
                                                                                                                                      ],
                                                                                                                                    arrRefStrings:
                                                                                                                                      [
                                                                                                                                        jsvals.j8(
                                                                                                                                          {
                                                                                                                                            pass: {
                                                                                                                                              propertieValues:
                                                                                                                                                'users',
                                                                                                                                            },
                                                                                                                                          },
                                                                                                                                        ),
                                                                                                                                        jsvals.varReader(
                                                                                                                                          {
                                                                                                                                            pass: {
                                                                                                                                              path: jsvals.j8(
                                                                                                                                                {
                                                                                                                                                  pass: {
                                                                                                                                                    propertieValues:
                                                                                                                                                      'sc.A5.currData.itemID',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                              ),
                                                                                                                                            },
                                                                                                                                          },
                                                                                                                                        ),
                                                                                                                                      ],
                                                                                                                                    arrPathData:
                                                                                                                                      [
                                                                                                                                        jsvals.j8(
                                                                                                                                          {
                                                                                                                                            pass: {
                                                                                                                                              propertieValues:
                                                                                                                                                'sc.A5.forms.editData',
                                                                                                                                            },
                                                                                                                                          },
                                                                                                                                        ),
                                                                                                                                      ],
                                                                                                                                    arrFuncs:
                                                                                                                                      [
                                                                                                                                        async (
                                                                                                                                          ...args
                                                                                                                                        ) =>
                                                                                                                                          functions.funcGroup(
                                                                                                                                            {
                                                                                                                                              args,
                                                                                                                                              pass: {
                                                                                                                                                arrFunctions:
                                                                                                                                                  [
                                                                                                                                                    async (
                                                                                                                                                      ...args
                                                                                                                                                    ) =>
                                                                                                                                                      functions.setVar(
                                                                                                                                                        {
                                                                                                                                                          args,
                                                                                                                                                          pass: {
                                                                                                                                                            keyPath:
                                                                                                                                                              [
                                                                                                                                                                jsvals.varReader(
                                                                                                                                                                  {
                                                                                                                                                                    pass: {
                                                                                                                                                                      path: jsvals.j8(
                                                                                                                                                                        {
                                                                                                                                                                          pass: {
                                                                                                                                                                            propertieValues:
                                                                                                                                                                              'all.toggles.sideMenu',
                                                                                                                                                                          },
                                                                                                                                                                        },
                                                                                                                                                                      ),
                                                                                                                                                                    },
                                                                                                                                                                  },
                                                                                                                                                                ),
                                                                                                                                                              ],
                                                                                                                                                            value:
                                                                                                                                                              jsvals.j8(
                                                                                                                                                                {
                                                                                                                                                                  pass: {
                                                                                                                                                                    propertieValues:
                                                                                                                                                                      'hide',
                                                                                                                                                                  },
                                                                                                                                                                },
                                                                                                                                                              ),
                                                                                                                                                          },
                                                                                                                                                        },
                                                                                                                                                      ),
                                                                                                                                                    async (
                                                                                                                                                      ...args
                                                                                                                                                    ) =>
                                                                                                                                                      functions.setVar(
                                                                                                                                                        {
                                                                                                                                                          args,
                                                                                                                                                          pass: {
                                                                                                                                                            keyPath:
                                                                                                                                                              [
                                                                                                                                                                jsvals.varReader(
                                                                                                                                                                  {
                                                                                                                                                                    pass: {
                                                                                                                                                                      path: jsvals.j8(
                                                                                                                                                                        {
                                                                                                                                                                          pass: {
                                                                                                                                                                            propertieValues:
                                                                                                                                                                              'all.toggles.activeForm',
                                                                                                                                                                          },
                                                                                                                                                                        },
                                                                                                                                                                      ),
                                                                                                                                                                    },
                                                                                                                                                                  },
                                                                                                                                                                ),
                                                                                                                                                              ],
                                                                                                                                                            value:
                                                                                                                                                              jsvals.j8(
                                                                                                                                                                {
                                                                                                                                                                  pass: {
                                                                                                                                                                    propertieValues:
                                                                                                                                                                      'none',
                                                                                                                                                                  },
                                                                                                                                                                },
                                                                                                                                                              ),
                                                                                                                                                          },
                                                                                                                                                        },
                                                                                                                                                      ),
                                                                                                                                                    async (
                                                                                                                                                      ...args
                                                                                                                                                    ) =>
                                                                                                                                                      functions.setVar(
                                                                                                                                                        {
                                                                                                                                                          args,
                                                                                                                                                          pass: {
                                                                                                                                                            keyPath:
                                                                                                                                                              [
                                                                                                                                                                jsvals.j8(
                                                                                                                                                                  {
                                                                                                                                                                    pass: {
                                                                                                                                                                      propertieValues:
                                                                                                                                                                        'all.toggles.emptyField',
                                                                                                                                                                    },
                                                                                                                                                                  },
                                                                                                                                                                ),
                                                                                                                                                              ],
                                                                                                                                                            value:
                                                                                                                                                              jsvals.j8(
                                                                                                                                                                {
                                                                                                                                                                  pass: {
                                                                                                                                                                    propertieValues:
                                                                                                                                                                      'no',
                                                                                                                                                                  },
                                                                                                                                                                },
                                                                                                                                                              ),
                                                                                                                                                          },
                                                                                                                                                        },
                                                                                                                                                      ),
                                                                                                                                                  ],
                                                                                                                                              },
                                                                                                                                            },
                                                                                                                                          ),
                                                                                                                                      ],
                                                                                                                                  },
                                                                                                                                },
                                                                                                                              ),
                                                                                                                          ],
                                                                                                                        args,
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                              ],
                                                                                                            args,
                                                                                                          },
                                                                                                        },
                                                                                                      ),
                                                                                                  ],
                                                                                                args,
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                      ],
                                                                                    args,
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                        args,
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              args,
                                                            },
                                                          }),
                                                      ],
                                                      args,
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 40,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.padding({
                                                  pass: {
                                                    arrayValue: ['10px'],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '15px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '120px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '25px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.deepPurple',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'Cancelar',
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A5.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'all.toggles.sideMenu',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'hide',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'all.toggles.activeForm',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'none',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'all.toggles.emptyField',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'no',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],
                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.flex({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '1px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.ice',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadows({
                          pass: {
                            shadowColor: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '#000000',
                                },
                              }),
                            ],
                            shadowOffset: ['{width: 0, height: 0}'],
                            shadowOpacity: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '0.2',
                                },
                              }),
                            ],
                            shadowRadius: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'all.toggles.activeForm',
                              },
                            }),
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'a7location',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'space-between',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flexDirection({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'row',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '60px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingHorizontal({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 10,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '2px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'lightgrey',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.SvgView1
                                            pass={{
                                              componentSvg: (
                                                Svg: any,
                                                SvgObj: any,
                                              ) => {
                                                const {
                                                  Defs,
                                                  Stop,
                                                  Path,
                                                  LinearGradient,
                                                  G,
                                                  Circle,
                                                  Rect,
                                                  Mask,
                                                } = SvgObj;
                                                return (props: any) => (
                                                  <Svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={14}
                                                    viewBox="0 0 20 14"
                                                    {...props}
                                                  >
                                                    <Path
                                                      fillRule="evenodd"
                                                      d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                                                      clipRule="evenodd"
                                                      {...props}
                                                    />
                                                  </Svg>
                                                );
                                              },

                                              svgOriginal: `
        <svg></svg>
      `,

                                              altura: '16px',

                                              largura: '16px',

                                              preenchimento: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.colors.primary',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A5.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.editData',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.toogles.newBikeImg',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'false',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [[]],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.maxWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Formulário de',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'ADICIONAR PONTO',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.padding({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '20px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flex({ pass: { arrayValue: ['1'] } }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [
                                jsvals.varReader({
                                  pass: {
                                    path: 'true',
                                  },
                                }),
                              ],

                              expectedVal: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'true',
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.white',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowOpacity({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#000000',
                                                },
                                              }),
                                            ],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '0.2',
                                                },
                                              }),
                                            ],
                                            shadowRadius: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Nome',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxt2
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges.localName',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Endereço',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.AutocompleteGooglePlaces
                                            pass={{
                                              apiKey:
                                                'AIzaSyBJ1MNdqlCOmPXK3taPtmuEJbsrmtiUDxU',
                                              pathList: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A7.forms.iptsChanges.localAddress',
                                                  },
                                                }),
                                              ],
                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Referência',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxt2
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges.localRef',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '300px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.white',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#000000',
                                                },
                                              }),
                                            ],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '0.2',
                                                },
                                              }),
                                            ],
                                            shadowRadius: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.minHeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '290px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args: any) => {
                                                  return (
                                                    <customs.MapPlaces
                                                      pass={{
                                                        keyPlaces:
                                                          'AIzaSyBJ1MNdqlCOmPXK3taPtmuEJbsrmtiUDxU',
                                                        pathInitMap: [
                                                          jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'sc.A6.currData.locationsList.initialPoint',
                                                            },
                                                          }),
                                                        ],
                                                        arrPins: [
                                                          jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'sc.A6.currData.locationsList',
                                                            },
                                                          }),
                                                        ],
                                                        arrFuncs: [
                                                          async (...args) =>
                                                            functions.setVar({
                                                              args,
                                                              pass: {
                                                                keyPath: [
                                                                  jsvals.j8({
                                                                    pass: {
                                                                      propertieValues:
                                                                        'sc.A6.currData.currLocation.show',
                                                                    },
                                                                  }),
                                                                ],
                                                                value:
                                                                  jsvals.j8({
                                                                    pass: {
                                                                      propertieValues:
                                                                        'localDetails',
                                                                    },
                                                                  }),
                                                              },
                                                            }),
                                                          async (...args) =>
                                                            functions.setVar({
                                                              args,
                                                              pass: {
                                                                keyPath: [
                                                                  jsvals.j8({
                                                                    pass: {
                                                                      propertieValues:
                                                                        'sc.A6.currData.currLocation.data',
                                                                    },
                                                                  }),
                                                                ],
                                                                value:
                                                                  jsvals.argReader(
                                                                    {
                                                                      pass: {
                                                                        argPath:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '#0',
                                                                              },
                                                                            },
                                                                          ),
                                                                        args,
                                                                      },
                                                                    },
                                                                  ),
                                                              },
                                                            }),
                                                        ],
                                                        args,
                                                      }}
                                                    />
                                                  );
                                                },
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#F5D3D3',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '3px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'all.toggles.emptyField',
                                          },
                                        }),
                                      ],

                                      expectedVal: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'yes',
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'red',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '11px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Preencha todos os campos para prosseguir',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: ['#000'],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: ['0.4px'],
                                            shadowRadius: ['20px'],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: ['white'],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Salvar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.condition({
                                            args,
                                            pass: {
                                              conditionPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'sc.A7.forms.iptsChanges.localName',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              expectedValue: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: '',
                                                  },
                                                }),
                                              ],
                                              functionsTrue: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'yes',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                              functionsFalse: [
                                                async (...args) =>
                                                  functions.condition({
                                                    args,
                                                    pass: {
                                                      conditionPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.A7.forms.iptsChanges.localAddress',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      expectedValue: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues: '',
                                                          },
                                                        }),
                                                      ],
                                                      functionsTrue: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'all.toggles.emptyField',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'yes',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A7.forms.iptsChanges',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                      functionsFalse: [
                                                        async (...args) =>
                                                          functions.condition({
                                                            args,
                                                            pass: {
                                                              conditionPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'sc.A7.forms.iptsChanges.localRef',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              expectedValue: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      '',
                                                                  },
                                                                }),
                                                              ],
                                                              functionsTrue: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'all.toggles.emptyField',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'yes',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A7.forms.iptsChanges',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              functionsFalse: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.firebase.setDoc(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        fbInit:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.fireInit',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrRefStrings:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'locations',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrPathData:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A7.forms.iptsChanges',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrFuncs:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                'all.toggles.emptyField',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'no',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                'sc.A7.forms.iptsChanges',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              '',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                      },
                                                                    },
                                                                  ),
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.toggles.sideMenu',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'hide',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.toggles.activeForm',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'none',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              args,
                                                            },
                                                          }),
                                                      ],
                                                      args,
                                                    },
                                                  }),
                                              ],
                                              args,
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Cancelar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.toggles.sideMenu',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'hide',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.toggles.activeForm',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'none',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A7.forms.iptsChanges',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: '',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'all.toggles.emptyField',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'no',
                                                },
                                              }),
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.flex({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '1px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.ice',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadows({
                          pass: {
                            shadowColor: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '#000000',
                                },
                              }),
                            ],
                            shadowOffset: ['{width: 0, height: 0}'],
                            shadowOpacity: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '0.2',
                                },
                              }),
                            ],
                            shadowRadius: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'all.toggles.activeForm',
                              },
                            }),
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'a7editlocation',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'space-between',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flexDirection({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'row',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '60px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingHorizontal({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 10,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '2px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'lightgrey',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.SvgView1
                                            pass={{
                                              componentSvg: (
                                                Svg: any,
                                                SvgObj: any,
                                              ) => {
                                                const {
                                                  Defs,
                                                  Stop,
                                                  Path,
                                                  LinearGradient,
                                                  G,
                                                  Circle,
                                                  Rect,
                                                  Mask,
                                                } = SvgObj;
                                                return (props: any) => (
                                                  <Svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={14}
                                                    viewBox="0 0 20 14"
                                                    {...props}
                                                  >
                                                    <Path
                                                      fillRule="evenodd"
                                                      d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                                                      clipRule="evenodd"
                                                      {...props}
                                                    />
                                                  </Svg>
                                                );
                                              },

                                              svgOriginal: `
        <svg></svg>
      `,

                                              altura: '16px',

                                              largura: '16px',

                                              preenchimento: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.colors.primary',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A5.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.editData',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.toogles.newBikeImg',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'false',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [[]],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.maxWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Formulário de',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'EDITAR PONTO',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 40,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 40,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.SvgView1
                                                    pass={{
                                                      componentSvg: (
                                                        Svg: any,
                                                        SvgObj: any,
                                                      ) => {
                                                        const {
                                                          Defs,
                                                          Stop,
                                                          Path,
                                                          LinearGradient,
                                                          G,
                                                          Circle,
                                                          Rect,
                                                          Mask,
                                                        } = SvgObj;
                                                        return (props: any) => (
                                                          <Svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            {...props}
                                                          >
                                                            
                                                            <Path
                                                              fill="#000"
                                                              d="M14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 18a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                                                            />
                                                          </Svg>
                                                        );
                                                      },

                                                      svgOriginal: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="black"/> <path d="M14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6Z" fill="black"/> <path d="M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18C10 16.8954 10.8954 16 12 16C13.1046 16 14 16.8954 14 18Z" fill="black"/> </svg>
      `,

                                                      altura: '20px',

                                                      largura: '40px',

                                                      preenchimento: [
                                                        'transparent',
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.rightOptions',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'active',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.white',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),

                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '30px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.position({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'absolute',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.top({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '20px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.right({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '30px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: { arrayValue: [10] },
                                                }),
                                              ],

                                              variablePath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.toggles.rightOptions',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              expectedVal: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'active',
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.Pressable3
                                                    pass={{
                                                      elementProperties: [{}],

                                                      styles: [[]],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                {
                                                                  color:
                                                                    'black',
                                                                  fontSize: 12,
                                                                },
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Excluir',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      pressableFunctions: [
                                                        async (...args) =>
                                                          functions.funcGroup({
                                                            args,
                                                            pass: {
                                                              arrFunctions: [
                                                                (...args) =>
                                                                  functions.firebase.deleteDoc(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        fbInit:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.fireInit',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrRefStrings:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'locations',
                                                                                },
                                                                              },
                                                                            ),
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'sc.A6.currData.currId',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrFuncs:
                                                                          [
                                                                            () => {},
                                                                          ],
                                                                      },
                                                                    },
                                                                  ),
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.funcGroup(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        arrFunctions:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'all.toggles.sideMenu',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'hide',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'all.toggles.activeForm',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'none',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'all.toggles.rightOptions',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'inactive',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                            },
                                                          }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '1px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.padding({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '20px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flex({ pass: { arrayValue: ['1'] } }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [
                                jsvals.varReader({
                                  pass: {
                                    path: 'true',
                                  },
                                }),
                              ],

                              expectedVal: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'true',
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.white',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowOpacity({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#000000',
                                                },
                                              }),
                                            ],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '0.2',
                                                },
                                              }),
                                            ],
                                            shadowRadius: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Nome do Ponto',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxtEdit
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              editPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A7.forms.editData.localName',
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.editData.localName',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Endereço',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.AutocompleteGooglePlaces
                                            pass={{
                                              apiKey:
                                                'AIzaSyBJ1MNdqlCOmPXK3taPtmuEJbsrmtiUDxU',
                                              pathList: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A7.forms.editData.localAddress',
                                                  },
                                                }),
                                              ],
                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Referência',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxtEdit
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              editPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A7.forms.editData.localRef',
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.editData.localRef',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '300px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.white',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#000000',
                                                },
                                              }),
                                            ],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '0.2',
                                                },
                                              }),
                                            ],
                                            shadowRadius: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.minHeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '290px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args: any) => {
                                                  return (
                                                    <customs.MapPlaces
                                                      pass={{
                                                        keyPlaces:
                                                          'AIzaSyBJ1MNdqlCOmPXK3taPtmuEJbsrmtiUDxU',
                                                        pathInitMap: [
                                                          jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'sc.A6.currData.locationsList.initialPoint',
                                                            },
                                                          }),
                                                        ],
                                                        arrPins: [
                                                          jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'sc.A6.currData.locationsList',
                                                            },
                                                          }),
                                                        ],
                                                        arrFuncs: [
                                                          async (...args) =>
                                                            functions.setVar({
                                                              args,
                                                              pass: {
                                                                keyPath: [
                                                                  jsvals.j8({
                                                                    pass: {
                                                                      propertieValues:
                                                                        'sc.A6.currData.currLocation.show',
                                                                    },
                                                                  }),
                                                                ],
                                                                value:
                                                                  jsvals.j8({
                                                                    pass: {
                                                                      propertieValues:
                                                                        'localDetails',
                                                                    },
                                                                  }),
                                                              },
                                                            }),
                                                          async (...args) =>
                                                            functions.setVar({
                                                              args,
                                                              pass: {
                                                                keyPath: [
                                                                  jsvals.j8({
                                                                    pass: {
                                                                      propertieValues:
                                                                        'sc.A6.currData.currLocation.data',
                                                                    },
                                                                  }),
                                                                ],
                                                                value:
                                                                  jsvals.argReader(
                                                                    {
                                                                      pass: {
                                                                        argPath:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '#0',
                                                                              },
                                                                            },
                                                                          ),
                                                                        args,
                                                                      },
                                                                    },
                                                                  ),
                                                              },
                                                            }),
                                                        ],
                                                        args,
                                                      }}
                                                    />
                                                  );
                                                },
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#F5D3D3',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '3px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'all.toggles.emptyField',
                                          },
                                        }),
                                      ],

                                      expectedVal: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'yes',
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'red',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '11px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Preencha todos os campos para prosseguir',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: ['#000'],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: ['0.4px'],
                                            shadowRadius: ['20px'],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: ['white'],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Salvar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.condition({
                                            args,
                                            pass: {
                                              conditionPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'sc.A7.forms.editData.localName',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              expectedValue: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: '',
                                                  },
                                                }),
                                              ],
                                              functionsTrue: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'yes',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                              functionsFalse: [
                                                async (...args) =>
                                                  functions.condition({
                                                    args,
                                                    pass: {
                                                      conditionPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.A7.forms.editData.localAddress',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      expectedValue: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues: '',
                                                          },
                                                        }),
                                                      ],
                                                      functionsTrue: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'all.toggles.emptyField',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'yes',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                      functionsFalse: [
                                                        async (...args) =>
                                                          functions.condition({
                                                            args,
                                                            pass: {
                                                              conditionPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'sc.A7.forms.editData.localRef',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              expectedValue: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      '',
                                                                  },
                                                                }),
                                                              ],
                                                              functionsTrue: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'all.toggles.emptyField',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'yes',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              functionsFalse: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.firebase.updateDoc(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        fbInit:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.fireInit',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrRefStrings:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'locations',
                                                                                },
                                                                              },
                                                                            ),
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'sc.A6.currData.currId',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrPathData:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A7.forms.editData',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrFuncs:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                'all.toggles.emptyField',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'no',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                'sc.A7.forms.editData',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              '',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                      },
                                                                    },
                                                                  ),
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.toggles.sideMenu',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'hide',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.toggles.activeForm',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'none',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              args,
                                                            },
                                                          }),
                                                      ],
                                                      args,
                                                    },
                                                  }),
                                              ],
                                              args,
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Cancelar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.toggles.sideMenu',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'hide',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.toggles.activeForm',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'none',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A7.forms.editData',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: '',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'all.toggles.emptyField',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'no',
                                                },
                                              }),
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.flex({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '1px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.ice',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadows({
                          pass: {
                            shadowColor: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '#000000',
                                },
                              }),
                            ],
                            shadowOffset: ['{width: 0, height: 0}'],
                            shadowOpacity: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '0.2',
                                },
                              }),
                            ],
                            shadowRadius: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'all.toggles.activeForm',
                              },
                            }),
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'a9addmodel',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'space-between',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flexDirection({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'row',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '60px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingHorizontal({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 10,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '2px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'lightgrey',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.SvgView1
                                            pass={{
                                              componentSvg: (
                                                Svg: any,
                                                SvgObj: any,
                                              ) => {
                                                const {
                                                  Defs,
                                                  Stop,
                                                  Path,
                                                  LinearGradient,
                                                  G,
                                                  Circle,
                                                  Rect,
                                                  Mask,
                                                } = SvgObj;
                                                return (props: any) => (
                                                  <Svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={14}
                                                    viewBox="0 0 20 14"
                                                    {...props}
                                                  >
                                                    <Path
                                                      fillRule="evenodd"
                                                      d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                                                      clipRule="evenodd"
                                                      {...props}
                                                    />
                                                  </Svg>
                                                );
                                              },

                                              svgOriginal: `
        <svg></svg>
      `,

                                              altura: '16px',

                                              largura: '16px',

                                              preenchimento: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.colors.primary',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A5.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.editData',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.toogles.newBikeImg',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'false',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [[]],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.maxWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Formulário de',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'ADICIONAR MODELO BICICLETA',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.padding({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '20px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flex({ pass: { arrayValue: ['1'] } }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [
                                jsvals.varReader({
                                  pass: {
                                    path: 'true',
                                  },
                                }),
                              ],

                              expectedVal: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'true',
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.white',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: { arrayValue: [10] },
                                        }),
                                        stls.padding({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.ice',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#000000',
                                                },
                                              }),
                                            ],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '0.2',
                                                },
                                              }),
                                            ],
                                            shadowRadius: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.ImageBox
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.borderColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'white',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '120px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 5,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              URIvariablePath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'all.tempFiles.assets.0.uri',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args) => (
                                          <customs.FilePicker
                                            pass={{
                                              arrChildren: [
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.deepPurple',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'Carregar Imagem',
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],
                                              styles: [
                                                stls.borderWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),

                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '15px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.paddingHorizontal({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '12px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.paddingVertical({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '5px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],
                                              arrFuncs: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.tempFiles',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#1',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.white',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowOpacity({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#000000',
                                                },
                                              }),
                                            ],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '0.2',
                                                },
                                              }),
                                            ],
                                            shadowRadius: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Nome do Modelo',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxt2
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges.modelName',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Valor da Hora',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                {
                                                  backgroundColor: 'white',
                                                  justifyContent: 'center',
                                                  minHeight: 22,
                                                  width: '100%',
                                                },
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.CustomListener
                                                    pass={{
                                                      variable: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges.modelPrice',
                                                          },
                                                        }),
                                                      ],
                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.borderBottomWidth(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '1px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderBottomColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.grey',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                customs.currencyString(
                                                                  {
                                                                    pass: {
                                                                      value: [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'sc.A9.forms.iptsChanges.modelPrice',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                    },
                                                                  },
                                                                ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],
                                                      args,
                                                    }}
                                                  />
                                                ),
                                                (...args: any) => (
                                                  <Elements.IptTxt2
                                                    pass={{
                                                      propsArray: [{}],

                                                      stylesArray: [
                                                        stls.borderBottomWidth({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '1px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderBottomColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey2',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.fontSize({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.textSizes.small',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.position({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'absolute',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.zIndex({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '1px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'rgba(0,0,0,0)',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      funcsArray: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A9.forms.iptsChanges.modelPrice',
                                                                  },
                                                                }),
                                                              ],
                                                              value:
                                                                customs.currencyString(
                                                                  {
                                                                    pass: {
                                                                      value: [
                                                                        jsvals.argReader(
                                                                          {
                                                                            pass: {
                                                                              argPath:
                                                                                jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        '#0',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              args,
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                    },
                                                                  },
                                                                ),
                                                            },
                                                          }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#F5D3D3',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '3px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'all.toggles.emptyField',
                                          },
                                        }),
                                      ],

                                      expectedVal: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'yes',
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'red',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '11px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Preencha todos os campos para prosseguir',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: ['#000'],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: ['0.4px'],
                                            shadowRadius: ['20px'],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: ['white'],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Salvar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.condition({
                                            args,
                                            pass: {
                                              conditionPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'sc.A9.forms.iptsChanges.modelName',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              expectedValue: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: '',
                                                  },
                                                }),
                                              ],
                                              functionsTrue: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'yes',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                              functionsFalse: [
                                                async (...args) =>
                                                  functions.condition({
                                                    args,
                                                    pass: {
                                                      conditionPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.A9.forms.iptsChanges.modelPrice',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      expectedValue: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues: '',
                                                          },
                                                        }),
                                                      ],
                                                      functionsTrue: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'all.toggles.emptyField',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'yes',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                      functionsFalse: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A9.forms.iptsChanges.modelAmount',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '0',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.firebase.uploadFile(
                                                            {
                                                              args,
                                                              pass: {
                                                                fbInit: [
                                                                  jsvals.varReader(
                                                                    {
                                                                      pass: {
                                                                        path: jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                'all.fireInit',
                                                                            },
                                                                          },
                                                                        ),
                                                                      },
                                                                    },
                                                                  ),
                                                                ],
                                                                arrFiles: [
                                                                  jsvals.varReader(
                                                                    {
                                                                      pass: {
                                                                        path: jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                'all.tempFiles',
                                                                            },
                                                                          },
                                                                        ),
                                                                      },
                                                                    },
                                                                  ),
                                                                ],
                                                                arrFuncs: [
                                                                  async (
                                                                    ...args
                                                                  ) =>
                                                                    functions.setVar(
                                                                      {
                                                                        args,
                                                                        pass: {
                                                                          keyPath:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'sc.A9.forms.iptsChanges.imgUrl',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          value:
                                                                            jsvals.argReader(
                                                                              {
                                                                                pass: {
                                                                                  argPath:
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            '#1',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  args,
                                                                                },
                                                                              },
                                                                            ),
                                                                        },
                                                                      },
                                                                    ),
                                                                  async (
                                                                    ...args
                                                                  ) =>
                                                                    functions.firebase.setDoc(
                                                                      {
                                                                        args,
                                                                        pass: {
                                                                          fbInit:
                                                                            [
                                                                              jsvals.varReader(
                                                                                {
                                                                                  pass: {
                                                                                    path: jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'all.fireInit',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          arrRefStrings:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'bikeModels',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          arrPathData:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'sc.A9.forms.iptsChanges',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          arrFuncs:
                                                                            [
                                                                              async (
                                                                                ...args
                                                                              ) =>
                                                                                functions.funcGroup(
                                                                                  {
                                                                                    args,
                                                                                    pass: {
                                                                                      arrFunctions:
                                                                                        [
                                                                                          async (
                                                                                            ...args
                                                                                          ) =>
                                                                                            functions.setVar(
                                                                                              {
                                                                                                args,
                                                                                                pass: {
                                                                                                  keyPath:
                                                                                                    [
                                                                                                      jsvals.varReader(
                                                                                                        {
                                                                                                          pass: {
                                                                                                            path: jsvals.j8(
                                                                                                              {
                                                                                                                pass: {
                                                                                                                  propertieValues:
                                                                                                                    'all.toggles.sideMenu',
                                                                                                                },
                                                                                                              },
                                                                                                            ),
                                                                                                          },
                                                                                                        },
                                                                                                      ),
                                                                                                    ],
                                                                                                  value:
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'hide',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                },
                                                                                              },
                                                                                            ),
                                                                                          async (
                                                                                            ...args
                                                                                          ) =>
                                                                                            functions.setVar(
                                                                                              {
                                                                                                args,
                                                                                                pass: {
                                                                                                  keyPath:
                                                                                                    [
                                                                                                      jsvals.varReader(
                                                                                                        {
                                                                                                          pass: {
                                                                                                            path: jsvals.j8(
                                                                                                              {
                                                                                                                pass: {
                                                                                                                  propertieValues:
                                                                                                                    'all.toggles.activeForm',
                                                                                                                },
                                                                                                              },
                                                                                                            ),
                                                                                                          },
                                                                                                        },
                                                                                                      ),
                                                                                                    ],
                                                                                                  value:
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'none',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                },
                                                                                              },
                                                                                            ),
                                                                                          async (
                                                                                            ...args
                                                                                          ) =>
                                                                                            functions.setVar(
                                                                                              {
                                                                                                args,
                                                                                                pass: {
                                                                                                  keyPath:
                                                                                                    [
                                                                                                      jsvals.j8(
                                                                                                        {
                                                                                                          pass: {
                                                                                                            propertieValues:
                                                                                                              'all.toggles.emptyField',
                                                                                                          },
                                                                                                        },
                                                                                                      ),
                                                                                                    ],
                                                                                                  value:
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'no',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                },
                                                                                              },
                                                                                            ),
                                                                                        ],
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              async (
                                                                                ...args
                                                                              ) =>
                                                                                functions.setVar(
                                                                                  {
                                                                                    args,
                                                                                    pass: {
                                                                                      keyPath:
                                                                                        [
                                                                                          jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'sc.A9.forms.iptsChanges',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        ],
                                                                                      value:
                                                                                        jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                '',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                    },
                                                                                  },
                                                                                ),
                                                                            ],
                                                                        },
                                                                      },
                                                                    ),
                                                                  async (
                                                                    ...args
                                                                  ) =>
                                                                    functions.setVar(
                                                                      {
                                                                        args,
                                                                        pass: {
                                                                          keyPath:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.tempFiles',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          value:
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    {},
                                                                                },
                                                                              },
                                                                            ),
                                                                        },
                                                                      },
                                                                    ),
                                                                ],
                                                              },
                                                            },
                                                          ),
                                                      ],
                                                      args,
                                                    },
                                                  }),
                                              ],
                                              args,
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Cancelar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'all.toggles.sideMenu',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'hide',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'all.toggles.activeForm',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'none',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'all.toggles.emptyField',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'no',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.flex({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '1px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.ice',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadows({
                          pass: {
                            shadowColor: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '#000000',
                                },
                              }),
                            ],
                            shadowOffset: ['{width: 0, height: 0}'],
                            shadowOpacity: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '0.2',
                                },
                              }),
                            ],
                            shadowRadius: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'all.toggles.activeForm',
                              },
                            }),
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'a9editmodel',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'space-between',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flexDirection({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'row',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '60px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingHorizontal({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 10,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '2px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'lightgrey',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.SvgView1
                                            pass={{
                                              componentSvg: (
                                                Svg: any,
                                                SvgObj: any,
                                              ) => {
                                                const {
                                                  Defs,
                                                  Stop,
                                                  Path,
                                                  LinearGradient,
                                                  G,
                                                  Circle,
                                                  Rect,
                                                  Mask,
                                                } = SvgObj;
                                                return (props: any) => (
                                                  <Svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={14}
                                                    viewBox="0 0 20 14"
                                                    {...props}
                                                  >
                                                    <Path
                                                      fillRule="evenodd"
                                                      d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                                                      clipRule="evenodd"
                                                      {...props}
                                                    />
                                                  </Svg>
                                                );
                                              },

                                              svgOriginal: `
        <svg></svg>
      `,

                                              altura: '16px',

                                              largura: '16px',

                                              preenchimento: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.colors.primary',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A5.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.editData',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.toogles.newBikeImg',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'false',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [[]],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.maxWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Formulário de',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'EDITAR BICICLETA',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 40,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 40,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.SvgView1
                                                    pass={{
                                                      componentSvg: (
                                                        Svg: any,
                                                        SvgObj: any,
                                                      ) => {
                                                        const {
                                                          Defs,
                                                          Stop,
                                                          Path,
                                                          LinearGradient,
                                                          G,
                                                          Circle,
                                                          Rect,
                                                          Mask,
                                                        } = SvgObj;
                                                        return (props: any) => (
                                                          <Svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            {...props}
                                                          >
                                                            
                                                            <Path
                                                              fill="#000"
                                                              d="M14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 18a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                                                            />
                                                          </Svg>
                                                        );
                                                      },

                                                      svgOriginal: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="black"/> <path d="M14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6Z" fill="black"/> <path d="M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18C10 16.8954 10.8954 16 12 16C13.1046 16 14 16.8954 14 18Z" fill="black"/> </svg>
      `,

                                                      altura: '20px',

                                                      largura: '40px',

                                                      preenchimento: [
                                                        'transparent',
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.rightOptions',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'active',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.white',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),

                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '30px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.position({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'absolute',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.top({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '20px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.right({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '30px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: { arrayValue: [10] },
                                                }),
                                              ],

                                              variablePath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.toggles.rightOptions',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              expectedVal: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'active',
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.Pressable3
                                                    pass={{
                                                      elementProperties: [{}],

                                                      styles: [[]],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                {
                                                                  color:
                                                                    'black',
                                                                  fontSize: 12,
                                                                },
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Excluir',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      pressableFunctions: [
                                                        async (...args) =>
                                                          functions.funcGroup({
                                                            args,
                                                            pass: {
                                                              arrFunctions: [
                                                                (...args) =>
                                                                  functions.firebase.deleteDoc(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        fbInit:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.fireInit',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrRefStrings:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'bikeModels',
                                                                                },
                                                                              },
                                                                            ),
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'sc.A8.currData.currId',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrFuncs:
                                                                          [
                                                                            () => {},
                                                                          ],
                                                                      },
                                                                    },
                                                                  ),
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.funcGroup(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        arrFunctions:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'all.toggles.sideMenu',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'hide',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'all.toggles.activeForm',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'none',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'all.toggles.rightOptions',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'inactive',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                            },
                                                          }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '1px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.padding({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '20px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flex({ pass: { arrayValue: ['1'] } }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [
                                jsvals.varReader({
                                  pass: {
                                    path: 'true',
                                  },
                                }),
                              ],

                              expectedVal: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'true',
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.white',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: { arrayValue: [10] },
                                        }),
                                        stls.padding({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.ice',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#000000',
                                                },
                                              }),
                                            ],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '0.2',
                                                },
                                              }),
                                            ],
                                            shadowRadius: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.ImageBox
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.borderColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'white',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '120px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 5,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              URIvariablePath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'all.tempFiles.assets.0.uri',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args) => (
                                          <customs.FilePicker
                                            pass={{
                                              arrChildren: [
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.deepPurple',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'Carregar Imagem',
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],
                                              styles: [
                                                stls.borderWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),

                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '15px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.paddingHorizontal({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '12px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.paddingVertical({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '5px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],
                                              arrFuncs: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.tempFiles',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#1',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.white',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowOpacity({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#000000',
                                                },
                                              }),
                                            ],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '0.2',
                                                },
                                              }),
                                            ],
                                            shadowRadius: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Nome do Modelo',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxtEdit
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              editPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A9.forms.editData.modelName',
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData.modelName',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Valor da Hora',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                {
                                                  backgroundColor: 'white',
                                                  justifyContent: 'center',
                                                  minHeight: 22,
                                                  width: '100%',
                                                },
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.CustomListener
                                                    pass={{
                                                      variable: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData.modelPrice',
                                                          },
                                                        }),
                                                      ],
                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.borderBottomWidth(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '1px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderBottomColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.grey',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                customs.currencyString(
                                                                  {
                                                                    pass: {
                                                                      value: [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'sc.A9.forms.editData.modelPrice',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                    },
                                                                  },
                                                                ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],
                                                      args,
                                                    }}
                                                  />
                                                ),
                                                (...args: any) => (
                                                  <Elements.IptTxt2
                                                    pass={{
                                                      propsArray: [
                                                        (...args: any) =>
                                                          props.placeholderTextColor(
                                                            {
                                                              pass: {
                                                                A: [
                                                                  jsvals.j8({
                                                                    pass: {
                                                                      propertieValues:
                                                                        'rgba(0,0,0,0)',
                                                                    },
                                                                  }),
                                                                ],
                                                              },
                                                            },
                                                          ),
                                                        (...args: any) =>
                                                          props.placeholder({
                                                            pass: {
                                                              A: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      '',
                                                                  },
                                                                }),
                                                              ],
                                                            },
                                                          }),
                                                      ],

                                                      stylesArray: [
                                                        stls.borderBottomWidth({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '1px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderBottomColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.grey2',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.fontSize({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.textSizes.small',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.position({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'absolute',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.zIndex({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '1px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'rgba(0,0,0,0)',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      funcsArray: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A9.forms.editData.modelPrice',
                                                                  },
                                                                }),
                                                              ],
                                                              value:
                                                                customs.currencyString(
                                                                  {
                                                                    pass: {
                                                                      value: [
                                                                        jsvals.argReader(
                                                                          {
                                                                            pass: {
                                                                              argPath:
                                                                                jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        '#0',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              args,
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                    },
                                                                  },
                                                                ),
                                                            },
                                                          }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#F5D3D3',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '3px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'all.toggles.emptyField',
                                          },
                                        }),
                                      ],

                                      expectedVal: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'yes',
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'red',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '11px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Preencha todos os campos para prosseguir',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: ['#000'],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: ['0.4px'],
                                            shadowRadius: ['20px'],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: ['white'],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Salvar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.condition({
                                            args,
                                            pass: {
                                              conditionPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'sc.A9.forms.editData.modelName',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              expectedValue: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: '',
                                                  },
                                                }),
                                              ],
                                              functionsTrue: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'yes',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                              functionsFalse: [
                                                async (...args) =>
                                                  functions.condition({
                                                    args,
                                                    pass: {
                                                      conditionPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.A9.forms.editData.modelPrice',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      expectedValue: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues: '',
                                                          },
                                                        }),
                                                      ],
                                                      functionsTrue: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'all.toggles.emptyField',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'yes',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                      functionsFalse: [
                                                        async (...args) =>
                                                          functions.firebase.uploadFile(
                                                            {
                                                              args,
                                                              pass: {
                                                                fbInit: [
                                                                  jsvals.varReader(
                                                                    {
                                                                      pass: {
                                                                        path: jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                'all.fireInit',
                                                                            },
                                                                          },
                                                                        ),
                                                                      },
                                                                    },
                                                                  ),
                                                                ],
                                                                arrFiles: [
                                                                  jsvals.varReader(
                                                                    {
                                                                      pass: {
                                                                        path: jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                'all.tempFiles',
                                                                            },
                                                                          },
                                                                        ),
                                                                      },
                                                                    },
                                                                  ),
                                                                ],
                                                                arrFuncs: [
                                                                  async (
                                                                    ...args
                                                                  ) =>
                                                                    functions.condition(
                                                                      {
                                                                        args,
                                                                        pass: {
                                                                          conditionPath:
                                                                            [
                                                                              jsvals.argReader(
                                                                                {
                                                                                  pass: {
                                                                                    argPath:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              '#1',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                    args,
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          expectedValue:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      '',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          functionsTrue:
                                                                            [
                                                                              () => {},
                                                                            ],
                                                                          functionsFalse:
                                                                            [
                                                                              async (
                                                                                ...args
                                                                              ) =>
                                                                                functions.setVar(
                                                                                  {
                                                                                    args,
                                                                                    pass: {
                                                                                      keyPath:
                                                                                        [
                                                                                          jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'sc.A9.forms.editData.imgUrl',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        ],
                                                                                      value:
                                                                                        jsvals.argReader(
                                                                                          {
                                                                                            pass: {
                                                                                              argPath:
                                                                                                jsvals.j8(
                                                                                                  {
                                                                                                    pass: {
                                                                                                      propertieValues:
                                                                                                        '#0.1',
                                                                                                    },
                                                                                                  },
                                                                                                ),
                                                                                              args,
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                    },
                                                                                  },
                                                                                ),
                                                                            ],
                                                                          args,
                                                                        },
                                                                      },
                                                                    ),
                                                                  async (
                                                                    ...args
                                                                  ) =>
                                                                    functions.setVar(
                                                                      {
                                                                        args,
                                                                        pass: {
                                                                          keyPath:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.tempFiles',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          value:
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    {},
                                                                                },
                                                                              },
                                                                            ),
                                                                        },
                                                                      },
                                                                    ),
                                                                  async (
                                                                    ...args
                                                                  ) =>
                                                                    functions.firebase.updateDoc(
                                                                      {
                                                                        args,
                                                                        pass: {
                                                                          fbInit:
                                                                            [
                                                                              jsvals.varReader(
                                                                                {
                                                                                  pass: {
                                                                                    path: jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'all.fireInit',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          arrRefStrings:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'bikeModels',
                                                                                  },
                                                                                },
                                                                              ),
                                                                              jsvals.varReader(
                                                                                {
                                                                                  pass: {
                                                                                    path: jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'sc.A8.currData.currId',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          arrPathData:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'sc.A9.forms.editData',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          arrFuncs:
                                                                            [
                                                                              async (
                                                                                ...args
                                                                              ) =>
                                                                                functions.funcGroup(
                                                                                  {
                                                                                    args,
                                                                                    pass: {
                                                                                      arrFunctions:
                                                                                        [
                                                                                          async (
                                                                                            ...args
                                                                                          ) =>
                                                                                            functions.funcGroup(
                                                                                              {
                                                                                                args,
                                                                                                pass: {
                                                                                                  arrFunctions:
                                                                                                    [
                                                                                                      async (
                                                                                                        ...args
                                                                                                      ) =>
                                                                                                        functions.setVar(
                                                                                                          {
                                                                                                            args,
                                                                                                            pass: {
                                                                                                              keyPath:
                                                                                                                [
                                                                                                                  jsvals.varReader(
                                                                                                                    {
                                                                                                                      pass: {
                                                                                                                        path: jsvals.j8(
                                                                                                                          {
                                                                                                                            pass: {
                                                                                                                              propertieValues:
                                                                                                                                'all.toggles.sideMenu',
                                                                                                                            },
                                                                                                                          },
                                                                                                                        ),
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                                ],
                                                                                                              value:
                                                                                                                jsvals.j8(
                                                                                                                  {
                                                                                                                    pass: {
                                                                                                                      propertieValues:
                                                                                                                        'hide',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      async (
                                                                                                        ...args
                                                                                                      ) =>
                                                                                                        functions.setVar(
                                                                                                          {
                                                                                                            args,
                                                                                                            pass: {
                                                                                                              keyPath:
                                                                                                                [
                                                                                                                  jsvals.varReader(
                                                                                                                    {
                                                                                                                      pass: {
                                                                                                                        path: jsvals.j8(
                                                                                                                          {
                                                                                                                            pass: {
                                                                                                                              propertieValues:
                                                                                                                                'all.toggles.activeForm',
                                                                                                                            },
                                                                                                                          },
                                                                                                                        ),
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                                ],
                                                                                                              value:
                                                                                                                jsvals.j8(
                                                                                                                  {
                                                                                                                    pass: {
                                                                                                                      propertieValues:
                                                                                                                        'none',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      async (
                                                                                                        ...args
                                                                                                      ) =>
                                                                                                        functions.setVar(
                                                                                                          {
                                                                                                            args,
                                                                                                            pass: {
                                                                                                              keyPath:
                                                                                                                [
                                                                                                                  jsvals.j8(
                                                                                                                    {
                                                                                                                      pass: {
                                                                                                                        propertieValues:
                                                                                                                          'all.toggles.emptyField',
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                                ],
                                                                                                              value:
                                                                                                                jsvals.j8(
                                                                                                                  {
                                                                                                                    pass: {
                                                                                                                      propertieValues:
                                                                                                                        'no',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                    ],
                                                                                                },
                                                                                              },
                                                                                            ),
                                                                                          async (
                                                                                            ...args
                                                                                          ) =>
                                                                                            functions.setVar(
                                                                                              {
                                                                                                args,
                                                                                                pass: {
                                                                                                  keyPath:
                                                                                                    [
                                                                                                      jsvals.j8(
                                                                                                        {
                                                                                                          pass: {
                                                                                                            propertieValues:
                                                                                                              'sc.A9.forms.editData',
                                                                                                          },
                                                                                                        },
                                                                                                      ),
                                                                                                    ],
                                                                                                  value:
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            '',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                },
                                                                                              },
                                                                                            ),
                                                                                        ],
                                                                                    },
                                                                                  },
                                                                                ),
                                                                            ],
                                                                        },
                                                                      },
                                                                    ),
                                                                ],
                                                              },
                                                            },
                                                          ),
                                                      ],
                                                      args,
                                                    },
                                                  }),
                                              ],
                                              args,
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Cancelar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'all.toggles.sideMenu',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'hide',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'all.toggles.activeForm',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'none',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'all.toggles.emptyField',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'no',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.flex({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '1px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.ice',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadows({
                          pass: {
                            shadowColor: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '#000000',
                                },
                              }),
                            ],
                            shadowOffset: ['{width: 0, height: 0}'],
                            shadowOpacity: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '0.2',
                                },
                              }),
                            ],
                            shadowRadius: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'all.toggles.activeForm',
                              },
                            }),
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'a11addbike',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'space-between',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flexDirection({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'row',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '60px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingHorizontal({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 10,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '2px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'lightgrey',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.SvgView1
                                            pass={{
                                              componentSvg: (
                                                Svg: any,
                                                SvgObj: any,
                                              ) => {
                                                const {
                                                  Defs,
                                                  Stop,
                                                  Path,
                                                  LinearGradient,
                                                  G,
                                                  Circle,
                                                  Rect,
                                                  Mask,
                                                } = SvgObj;
                                                return (props: any) => (
                                                  <Svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={14}
                                                    viewBox="0 0 20 14"
                                                    {...props}
                                                  >
                                                    <Path
                                                      fillRule="evenodd"
                                                      d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                                                      clipRule="evenodd"
                                                      {...props}
                                                    />
                                                  </Svg>
                                                );
                                              },

                                              svgOriginal: `
        <svg></svg>
      `,

                                              altura: '16px',

                                              largura: '16px',

                                              preenchimento: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.colors.primary',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A5.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.editData',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.toogles.newBikeImg',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'false',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [[]],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.maxWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Formulário de',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'ADICIONAR BICICLETA',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.padding({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '20px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flex({ pass: { arrayValue: ['1'] } }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [
                                jsvals.varReader({
                                  pass: {
                                    path: 'true',
                                  },
                                }),
                              ],

                              expectedVal: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'true',
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.white',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowOpacity({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#000000',
                                                },
                                              }),
                                            ],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '0.2',
                                                },
                                              }),
                                            ],
                                            shadowRadius: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.white',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.paddingHorizontal({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '20px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: { arrayValue: [10] },
                                                }),
                                                stls.padding({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.ice',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.shadows({
                                                  pass: {
                                                    shadowColor: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '#000000',
                                                        },
                                                      }),
                                                    ],
                                                    shadowOffset: [
                                                      '{width: 0, height: 0}',
                                                    ],
                                                    shadowOpacity: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '0.2',
                                                        },
                                                      }),
                                                    ],
                                                    shadowRadius: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A11.toogles.newBikeImg',
                                                  },
                                                }),
                                              ],

                                              expectedVal: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'true',
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.ImageBox
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.borderColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'white',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '120px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderRadius({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues: 5,
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      URIvariablePath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A11.forms.iptsChanges.imgUrl',
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Selecionar Modelo',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <customs.IptPicker
                                                    pass={{
                                                      pathList: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A10.currData.allBikes',
                                                          },
                                                        }),
                                                      ],
                                                      pathEdit: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A10.currData.bikesList.modelName',
                                                          },
                                                        }),
                                                      ],
                                                      arrFuncs: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges.modelId',
                                                                  },
                                                                }),
                                                              ],
                                                              value:
                                                                jsvals.argReader(
                                                                  {
                                                                    pass: {
                                                                      argPath:
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '#1',
                                                                            },
                                                                          },
                                                                        ),
                                                                      args,
                                                                    },
                                                                  },
                                                                ),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.firebase.where(
                                                            {
                                                              args,
                                                              pass: {
                                                                fbInit: [
                                                                  jsvals.varReader(
                                                                    {
                                                                      pass: {
                                                                        path: jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                'all.fireInit',
                                                                            },
                                                                          },
                                                                        ),
                                                                      },
                                                                    },
                                                                  ),
                                                                ],
                                                                arrRefStrings: [
                                                                  jsvals.j8({
                                                                    pass: {
                                                                      propertieValues:
                                                                        'bikeModels',
                                                                    },
                                                                  }),
                                                                ],
                                                                arrWhere: [
                                                                  (...args) =>
                                                                    functions.firebase.whereConds(
                                                                      {
                                                                        args,
                                                                        pass: {
                                                                          arrStrings:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'docId',
                                                                                  },
                                                                                },
                                                                              ),
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      '==',
                                                                                  },
                                                                                },
                                                                              ),
                                                                              jsvals.varReader(
                                                                                {
                                                                                  pass: {
                                                                                    path: jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'sc.A11.forms.iptsChanges.modelId',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                        },
                                                                      },
                                                                    ),
                                                                ],
                                                                arrFuncs: [
                                                                  async (
                                                                    ...args
                                                                  ) =>
                                                                    functions.setVar(
                                                                      {
                                                                        args,
                                                                        pass: {
                                                                          keyPath:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'sc.A11.forms.iptsChanges.imgUrl',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          value:
                                                                            jsvals.argReader(
                                                                              {
                                                                                pass: {
                                                                                  argPath:
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            '#0.0.imgUrl',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  args,
                                                                                },
                                                                              },
                                                                            ),
                                                                        },
                                                                      },
                                                                    ),
                                                                ],
                                                              },
                                                            },
                                                          ),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.toogles.newBikeImg',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'true',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                      pathReturn: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A11.forms.iptsChanges.modelName',
                                                          },
                                                        }),
                                                      ],
                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Código',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxt2
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A11.forms.iptsChanges.modelCode',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Selecionar Status',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <customs.IptPicker
                                                    pass={{
                                                      pathList: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.static.pickers.bikeStatus',
                                                          },
                                                        }),
                                                      ],
                                                      pathEdit: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A10.currData.bikesList.modelName',
                                                          },
                                                        }),
                                                      ],
                                                      arrFuncs: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges.status',
                                                                  },
                                                                }),
                                                              ],
                                                              value:
                                                                jsvals.argReader(
                                                                  {
                                                                    pass: {
                                                                      argPath:
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '#1',
                                                                            },
                                                                          },
                                                                        ),
                                                                      args,
                                                                    },
                                                                  },
                                                                ),
                                                            },
                                                          }),
                                                      ],
                                                      pathReturn: [''],
                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Ponto de Retirada',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                {
                                                  color: 'black',
                                                  fontSize: 12,
                                                },
                                              ],

                                              children: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'sc.A6.currData.currLocationName',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#F5D3D3',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '3px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'all.toggles.emptyField',
                                          },
                                        }),
                                      ],

                                      expectedVal: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'yes',
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'red',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '11px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Preencha todos os campos para prosseguir',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: ['#000'],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: ['0.4px'],
                                            shadowRadius: ['20px'],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: ['white'],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Salvar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.condition({
                                            args,
                                            pass: {
                                              conditionPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'sc.A11.forms.iptsChanges.imgUrl',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              expectedValue: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: '',
                                                  },
                                                }),
                                              ],
                                              functionsTrue: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'yes',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                              functionsFalse: [
                                                async (...args) =>
                                                  functions.condition({
                                                    args,
                                                    pass: {
                                                      conditionPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.A11.forms.iptsChanges.modelCode',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      expectedValue: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues: '',
                                                          },
                                                        }),
                                                      ],
                                                      functionsTrue: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'all.toggles.emptyField',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'yes',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                      functionsFalse: [
                                                        async (...args) =>
                                                          functions.condition({
                                                            args,
                                                            pass: {
                                                              conditionPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'sc.A11.forms.iptsChanges.status',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              expectedValue: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      '',
                                                                  },
                                                                }),
                                                              ],
                                                              functionsTrue: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'all.toggles.emptyField',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'yes',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              functionsFalse: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.firebase.setDoc(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        fbInit:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.fireInit',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrRefStrings:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'locations',
                                                                                },
                                                                              },
                                                                            ),
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'sc.A8.currData.currId',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'localBikes',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrPathData:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A11.forms.iptsChanges',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrFuncs:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.funcGroup(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    arrFunctions:
                                                                                      [
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A11.forms.iptsChanges',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          {},
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A11.forms.editData',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          {},
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A11.toogles.newBikeImg',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'false',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                      ],
                                                                                  },
                                                                                },
                                                                              ),
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.funcGroup(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    arrFunctions:
                                                                                      [
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.varReader(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          path: jsvals.j8(
                                                                                                            {
                                                                                                              pass: {
                                                                                                                propertieValues:
                                                                                                                  'all.toggles.sideMenu',
                                                                                                              },
                                                                                                            },
                                                                                                          ),
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'hide',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.varReader(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          path: jsvals.j8(
                                                                                                            {
                                                                                                              pass: {
                                                                                                                propertieValues:
                                                                                                                  'all.toggles.activeForm',
                                                                                                              },
                                                                                                            },
                                                                                                          ),
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'none',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'all.toggles.emptyField',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'no',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                      ],
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              args,
                                                            },
                                                          }),
                                                      ],
                                                      args,
                                                    },
                                                  }),
                                              ],
                                              args,
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Cancelar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A11.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: {},
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A11.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: {},
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A11.toogles.newBikeImg',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'false',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.flex({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '1px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.ice',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadows({
                          pass: {
                            shadowColor: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '#000000',
                                },
                              }),
                            ],
                            shadowOffset: ['{width: 0, height: 0}'],
                            shadowOpacity: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '0.2',
                                },
                              }),
                            ],
                            shadowRadius: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'all.toggles.activeForm',
                              },
                            }),
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'a11editbike',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'space-between',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flexDirection({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'row',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '60px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingHorizontal({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 10,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '2px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'lightgrey',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.SvgView1
                                            pass={{
                                              componentSvg: (
                                                Svg: any,
                                                SvgObj: any,
                                              ) => {
                                                const {
                                                  Defs,
                                                  Stop,
                                                  Path,
                                                  LinearGradient,
                                                  G,
                                                  Circle,
                                                  Rect,
                                                  Mask,
                                                } = SvgObj;
                                                return (props: any) => (
                                                  <Svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={14}
                                                    viewBox="0 0 20 14"
                                                    {...props}
                                                  >
                                                    <Path
                                                      fillRule="evenodd"
                                                      d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                                                      clipRule="evenodd"
                                                      {...props}
                                                    />
                                                  </Svg>
                                                );
                                              },

                                              svgOriginal: `
        <svg></svg>
      `,

                                              altura: '16px',

                                              largura: '16px',

                                              preenchimento: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.colors.primary',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A5.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.editData',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.toogles.newBikeImg',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'false',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [[]],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.maxWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Formulário de',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'EDITAR BICICLETA',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 40,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 40,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.SvgView1
                                                    pass={{
                                                      componentSvg: (
                                                        Svg: any,
                                                        SvgObj: any,
                                                      ) => {
                                                        const {
                                                          Defs,
                                                          Stop,
                                                          Path,
                                                          LinearGradient,
                                                          G,
                                                          Circle,
                                                          Rect,
                                                          Mask,
                                                        } = SvgObj;
                                                        return (props: any) => (
                                                          <Svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            {...props}
                                                          >
                                                            
                                                            <Path
                                                              fill="#000"
                                                              d="M14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 18a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                                                            />
                                                          </Svg>
                                                        );
                                                      },

                                                      svgOriginal: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="black"/> <path d="M14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6Z" fill="black"/> <path d="M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18C10 16.8954 10.8954 16 12 16C13.1046 16 14 16.8954 14 18Z" fill="black"/> </svg>
      `,

                                                      altura: '20px',

                                                      largura: '40px',

                                                      preenchimento: [
                                                        'transparent',
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.rightOptions',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'active',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.white',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),

                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '30px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.position({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'absolute',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.top({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '20px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.right({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '30px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: { arrayValue: [10] },
                                                }),
                                              ],

                                              variablePath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.toggles.rightOptions',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              expectedVal: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'active',
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.Pressable3
                                                    pass={{
                                                      elementProperties: [{}],

                                                      styles: [[]],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                {
                                                                  color:
                                                                    'black',
                                                                  fontSize: 12,
                                                                },
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Excluir',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      pressableFunctions: [
                                                        async (...args) =>
                                                          functions.funcGroup({
                                                            args,
                                                            pass: {
                                                              arrFunctions: [
                                                                (...args) =>
                                                                  functions.firebase.deleteDoc(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        fbInit:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.fireInit',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrRefStrings:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'locations',
                                                                                },
                                                                              },
                                                                            ),
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'sc.A8.currData.currId',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'localBikes',
                                                                                },
                                                                              },
                                                                            ),
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'sc.A10.currData.currLocal.localBikeId',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrFuncs:
                                                                          [
                                                                            () => {},
                                                                          ],
                                                                      },
                                                                    },
                                                                  ),
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.funcGroup(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        arrFunctions:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'all.toggles.sideMenu',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'hide',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'all.toggles.activeForm',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'none',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.setVar(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    keyPath:
                                                                                      [
                                                                                        jsvals.varReader(
                                                                                          {
                                                                                            pass: {
                                                                                              path: jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'all.toggles.rightOptions',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      ],
                                                                                    value:
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'inactive',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                            },
                                                          }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.padding({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '20px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flex({ pass: { arrayValue: ['1'] } }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [
                                jsvals.varReader({
                                  pass: {
                                    path: 'true',
                                  },
                                }),
                              ],

                              expectedVal: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'true',
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.white',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowOpacity({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#000000',
                                                },
                                              }),
                                            ],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '0.2',
                                                },
                                              }),
                                            ],
                                            shadowRadius: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.white',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.paddingHorizontal({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '20px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: { arrayValue: [10] },
                                                }),
                                                stls.padding({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.ice',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.shadows({
                                                  pass: {
                                                    shadowColor: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '#000000',
                                                        },
                                                      }),
                                                    ],
                                                    shadowOffset: [
                                                      '{width: 0, height: 0}',
                                                    ],
                                                    shadowOpacity: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '0.2',
                                                        },
                                                      }),
                                                    ],
                                                    shadowRadius: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.ImageBox
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.borderColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'white',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '120px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderRadius({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues: 5,
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      URIvariablePath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A11.forms.editData.imgUrl',
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Selecionar Modelo',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <customs.IptPicker
                                                    pass={{
                                                      pathList: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A10.currData.allBikes',
                                                          },
                                                        }),
                                                      ],
                                                      pathEdit: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A11.forms.editData.modelId',
                                                          },
                                                        }),
                                                      ],
                                                      arrFuncs: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.editData.modelId',
                                                                  },
                                                                }),
                                                              ],
                                                              value:
                                                                jsvals.argReader(
                                                                  {
                                                                    pass: {
                                                                      argPath:
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '#1',
                                                                            },
                                                                          },
                                                                        ),
                                                                      args,
                                                                    },
                                                                  },
                                                                ),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges.modelId',
                                                                  },
                                                                }),
                                                              ],
                                                              value:
                                                                jsvals.argReader(
                                                                  {
                                                                    pass: {
                                                                      argPath:
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '#1',
                                                                            },
                                                                          },
                                                                        ),
                                                                      args,
                                                                    },
                                                                  },
                                                                ),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.firebase.where(
                                                            {
                                                              args,
                                                              pass: {
                                                                fbInit: [
                                                                  jsvals.varReader(
                                                                    {
                                                                      pass: {
                                                                        path: jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                'all.fireInit',
                                                                            },
                                                                          },
                                                                        ),
                                                                      },
                                                                    },
                                                                  ),
                                                                ],
                                                                arrRefStrings: [
                                                                  jsvals.j8({
                                                                    pass: {
                                                                      propertieValues:
                                                                        'bikeModels',
                                                                    },
                                                                  }),
                                                                ],
                                                                arrWhere: [
                                                                  (...args) =>
                                                                    functions.firebase.whereConds(
                                                                      {
                                                                        args,
                                                                        pass: {
                                                                          arrStrings:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'docId',
                                                                                  },
                                                                                },
                                                                              ),
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      '==',
                                                                                  },
                                                                                },
                                                                              ),
                                                                              jsvals.varReader(
                                                                                {
                                                                                  pass: {
                                                                                    path: jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'sc.A11.forms.iptsChanges.modelId',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                        },
                                                                      },
                                                                    ),
                                                                ],
                                                                arrFuncs: [
                                                                  async (
                                                                    ...args
                                                                  ) =>
                                                                    functions.setVar(
                                                                      {
                                                                        args,
                                                                        pass: {
                                                                          keyPath:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'sc.A11.forms.editData.imgUrl',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          value:
                                                                            jsvals.argReader(
                                                                              {
                                                                                pass: {
                                                                                  argPath:
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            '#0.0.imgUrl',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  args,
                                                                                },
                                                                              },
                                                                            ),
                                                                        },
                                                                      },
                                                                    ),
                                                                ],
                                                              },
                                                            },
                                                          ),
                                                      ],
                                                      pathReturn: [''],
                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Código',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.IptTxtEdit
                                            pass={{
                                              propsArray: [{}],

                                              stylesArray: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              editPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A11.forms.editData.modelCode',
                                                  },
                                                }),
                                              ],

                                              funcsArray: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A11.forms.editData.modelCode',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Selecionar Status',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.borderBottomWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderBottomColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <customs.IptPicker
                                                    pass={{
                                                      pathList: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.static.pickers.bikeStatus',
                                                          },
                                                        }),
                                                      ],
                                                      pathEdit: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A11.forms.editData.status',
                                                          },
                                                        }),
                                                      ],
                                                      arrFuncs: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.editData.status',
                                                                  },
                                                                }),
                                                              ],
                                                              value:
                                                                jsvals.argReader(
                                                                  {
                                                                    pass: {
                                                                      argPath:
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '#1',
                                                                            },
                                                                          },
                                                                        ),
                                                                      args,
                                                                    },
                                                                  },
                                                                ),
                                                            },
                                                          }),
                                                      ],
                                                      pathReturn: [''],
                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Ponto de Retirada',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                {
                                                  color: 'black',
                                                  fontSize: 12,
                                                },
                                              ],

                                              children: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'sc.A6.currData.currLocationName',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#F5D3D3',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '3px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'all.toggles.emptyField',
                                          },
                                        }),
                                      ],

                                      expectedVal: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'yes',
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'red',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '11px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Preencha todos os campos para prosseguir',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: ['#000'],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: ['0.4px'],
                                            shadowRadius: ['20px'],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: ['white'],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Salvar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.condition({
                                            args,
                                            pass: {
                                              conditionPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'sc.A11.forms.editData.imgUrl',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              expectedValue: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: '',
                                                  },
                                                }),
                                              ],
                                              functionsTrue: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'yes',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                              functionsFalse: [
                                                async (...args) =>
                                                  functions.condition({
                                                    args,
                                                    pass: {
                                                      conditionPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.A11.forms.editData.modelCode',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      expectedValue: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues: '',
                                                          },
                                                        }),
                                                      ],
                                                      functionsTrue: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'all.toggles.emptyField',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'yes',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                      functionsFalse: [
                                                        async (...args) =>
                                                          functions.condition({
                                                            args,
                                                            pass: {
                                                              conditionPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'sc.A11.forms.editData.status',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              expectedValue: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      '',
                                                                  },
                                                                }),
                                                              ],
                                                              functionsTrue: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'all.toggles.emptyField',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'yes',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              functionsFalse: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.firebase.updateDoc(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        fbInit:
                                                                          [
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'all.fireInit',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrRefStrings:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'locations',
                                                                                },
                                                                              },
                                                                            ),
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'sc.A8.currData.currId',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'localBikes',
                                                                                },
                                                                              },
                                                                            ),
                                                                            jsvals.varReader(
                                                                              {
                                                                                pass: {
                                                                                  path: jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'sc.A10.currData.itemId',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrPathData:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'sc.A11.forms.editData',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        arrFuncs:
                                                                          [
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.funcGroup(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    arrFunctions:
                                                                                      [
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A11.forms.iptsChanges',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          {},
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A11.forms.editData',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          {},
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'sc.A11.toogles.newBikeImg',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'false',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                      ],
                                                                                  },
                                                                                },
                                                                              ),
                                                                            async (
                                                                              ...args
                                                                            ) =>
                                                                              functions.funcGroup(
                                                                                {
                                                                                  args,
                                                                                  pass: {
                                                                                    arrFunctions:
                                                                                      [
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.varReader(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          path: jsvals.j8(
                                                                                                            {
                                                                                                              pass: {
                                                                                                                propertieValues:
                                                                                                                  'all.toggles.sideMenu',
                                                                                                              },
                                                                                                            },
                                                                                                          ),
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'hide',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.varReader(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          path: jsvals.j8(
                                                                                                            {
                                                                                                              pass: {
                                                                                                                propertieValues:
                                                                                                                  'all.toggles.activeForm',
                                                                                                              },
                                                                                                            },
                                                                                                          ),
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'none',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        async (
                                                                                          ...args
                                                                                        ) =>
                                                                                          functions.setVar(
                                                                                            {
                                                                                              args,
                                                                                              pass: {
                                                                                                keyPath:
                                                                                                  [
                                                                                                    jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'all.toggles.emptyField',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  ],
                                                                                                value:
                                                                                                  jsvals.j8(
                                                                                                    {
                                                                                                      pass: {
                                                                                                        propertieValues:
                                                                                                          'no',
                                                                                                      },
                                                                                                    },
                                                                                                  ),
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                      ],
                                                                                  },
                                                                                },
                                                                              ),
                                                                          ],
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              args,
                                                            },
                                                          }),
                                                      ],
                                                      args,
                                                    },
                                                  }),
                                              ],
                                              args,
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.padding({
                                          pass: { arrayValue: ['10px'] },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '120px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '25px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderWidth({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '1px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.primary',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'Cancelar',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A11.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: {},
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A11.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: {},
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A11.toogles.newBikeImg',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'false',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.flex({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '1px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.ice',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadows({
                          pass: {
                            shadowColor: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '#000000',
                                },
                              }),
                            ],
                            shadowOffset: ['{width: 0, height: 0}'],
                            shadowOpacity: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '0.2',
                                },
                              }),
                            ],
                            shadowRadius: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'all.toggles.activeForm',
                              },
                            }),
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'a14profile',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'space-between',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flexDirection({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'row',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '60px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingHorizontal({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 10,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '2px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'lightgrey',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.SvgView1
                                            pass={{
                                              componentSvg: (
                                                Svg: any,
                                                SvgObj: any,
                                              ) => {
                                                const {
                                                  Defs,
                                                  Stop,
                                                  Path,
                                                  LinearGradient,
                                                  G,
                                                  Circle,
                                                  Rect,
                                                  Mask,
                                                } = SvgObj;
                                                return (props: any) => (
                                                  <Svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={14}
                                                    viewBox="0 0 20 14"
                                                    {...props}
                                                  >
                                                    <Path
                                                      fillRule="evenodd"
                                                      d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                                                      clipRule="evenodd"
                                                      {...props}
                                                    />
                                                  </Svg>
                                                );
                                              },

                                              svgOriginal: `
        <svg></svg>
      `,

                                              altura: '16px',

                                              largura: '16px',

                                              preenchimento: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.colors.primary',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A5.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.editData',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.toogles.newBikeImg',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'false',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [[]],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.maxWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Formulário de',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'PERFIL DA RESERVA',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),
                        (...args: any) => (
                          <Elements.ScrollBar
                            pass={{
                              styles: [],
                              arrProps: [],
                              arrItems: [
                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.padding({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.flex({
                                          pass: { arrayValue: ['1'] },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.white',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.paddingHorizontal({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '20px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '15px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.shadows({
                                                  pass: {
                                                    shadowColor: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '#000000',
                                                        },
                                                      }),
                                                    ],
                                                    shadowOffset: [
                                                      '{width: 0, height: 0}',
                                                    ],
                                                    shadowOpacity: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '0.2',
                                                        },
                                                      }),
                                                    ],
                                                    shadowRadius: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: 'true',
                                                  },
                                                }),
                                              ],

                                              expectedVal: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'true',
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.ImageBox
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.borderRadius({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues: 75,
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'white',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '120px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '120px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.alignSelf({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'center',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      URIvariablePath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A14.forms.editData.userImage',
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Nome',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.CustomListener
                                                            pass={{
                                                              variable: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A14.forms.editData.userName',
                                                                  },
                                                                }),
                                                              ],
                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.colors.grey2',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.fontSize(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.textSizes.small',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.padding(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            '2px',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'sc.A14.forms.editData.userName',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],
                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'E-mail',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.CustomListener
                                                            pass={{
                                                              variable: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A14.forms.editData.userEmail',
                                                                  },
                                                                }),
                                                              ],
                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.colors.grey2',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.fontSize(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.textSizes.small',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.padding(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            '2px',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'sc.A14.forms.editData.userEmail',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],
                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'RG',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.CustomListener
                                                            pass={{
                                                              variable: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A14.forms.editData.userRG',
                                                                  },
                                                                }),
                                                              ],
                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.colors.grey2',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.fontSize(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.textSizes.small',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.padding(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            '2px',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'sc.A14.forms.editData.userRG',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],
                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Telefone',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.CustomListener
                                                            pass={{
                                                              variable: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A14.forms.editData.userPhone',
                                                                  },
                                                                }),
                                                              ],
                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.colors.grey2',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.fontSize(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.textSizes.small',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.padding(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            '2px',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'sc.A14.forms.editData.userPhone',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],
                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'CPF',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.CustomListener
                                                            pass={{
                                                              variable: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A14.forms.editData.userCPF',
                                                                  },
                                                                }),
                                                              ],
                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.colors.grey2',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.fontSize(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.textSizes.small',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.padding(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            '2px',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'sc.A14.forms.editData.userCPF',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],
                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Endereço',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.CustomListener
                                                            pass={{
                                                              variable: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A14.forms.editData.userAddress.formatted_address',
                                                                  },
                                                                }),
                                                              ],
                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.colors.grey2',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.fontSize(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.textSizes.small',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.padding(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            '2px',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'sc.A14.forms.editData.userAddress.formatted_address',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],
                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Gênero',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.CustomListener
                                                            pass={{
                                                              variable: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A14.forms.editData.userGender',
                                                                  },
                                                                }),
                                                              ],
                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.colors.grey2',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.fontSize(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.textSizes.small',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.padding(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            '2px',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'sc.A14.forms.editData.userGender',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],
                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.white',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.padding({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '12px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '15px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.shadows({
                                                  pass: {
                                                    shadowColor: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '#000000',
                                                        },
                                                      }),
                                                    ],
                                                    shadowOffset: [
                                                      '{width: 0, height: 0}',
                                                    ],
                                                    shadowOpacity: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '0.2',
                                                        },
                                                      }),
                                                    ],
                                                    shadowRadius: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.flexDirection({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'row',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Ponto: ',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.CustomListener
                                                            pass={{
                                                              variable: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A14.forms.editData.localName',
                                                                  },
                                                                }),
                                                              ],
                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.colors.grey2',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.fontSize(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.textSizes.small',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'sc.A14.forms.editData.localName',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],
                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                                (...args: any) => (
                                                  <Elements.FlatList2
                                                    pass={{
                                                      elementProperties: [{}],

                                                      pData: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'sc.A14.forms.editData.bikesList',
                                                        },
                                                      }),

                                                      itemElements: [
                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.borderWidth(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '1px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderRadius(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '10px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.ice',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.alignItems(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'center',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.padding({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '6px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.backgroundColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.white',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.justifyContent(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'space-around',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.flexDirection(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'row',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.ImageBox
                                                                    pass={{
                                                                      elementsProperties:
                                                                        [{}],

                                                                      styles: [
                                                                        {
                                                                          height:
                                                                            '50px',
                                                                          width:
                                                                            '80px',
                                                                          backgroundColor:
                                                                            '#FDFDFD',
                                                                          borderWidth: 2,
                                                                          borderColor:
                                                                            '#EEE',
                                                                        },
                                                                      ],

                                                                      URIvariablePath:
                                                                        [
                                                                          jsvals.argReader(
                                                                            {
                                                                              pass: {
                                                                                argPath:
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '#0.0.item.imgUrl',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                args,
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),

                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.DynView
                                                                    pass={{
                                                                      elementsProperties:
                                                                        [{}],

                                                                      styles: [
                                                                        stls.width(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '10px',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],

                                                                      variablePath:
                                                                        [],

                                                                      expectedVal:
                                                                        [],

                                                                      childrenItems:
                                                                        [
                                                                          () => (
                                                                            <>

                                                                            </>
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),

                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.DynView
                                                                    pass={{
                                                                      elementsProperties:
                                                                        [{}],

                                                                      styles: [
                                                                        stls.backgroundColor(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  'white',
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                        stls.flex(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '1px',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],

                                                                      variablePath:
                                                                        [],

                                                                      expectedVal:
                                                                        [],

                                                                      childrenItems:
                                                                        [
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.DynView
                                                                              pass={{
                                                                                elementsProperties:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                styles:
                                                                                  [
                                                                                    stls.flexDirection(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'row',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                variablePath:
                                                                                  [],

                                                                                expectedVal:
                                                                                  [],

                                                                                childrenItems:
                                                                                  [
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.Text
                                                                                        pass={{
                                                                                          arrProps:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          arrStyles:
                                                                                            [
                                                                                              stls.fontSize(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.textSizes.small',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontWeight(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.j8(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              propertieValues:
                                                                                                                'bold',
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.color(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.grey2',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          children:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'Código: ',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.CustomListener
                                                                                        pass={{
                                                                                          variable:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'sc.A14.forms.editData.bikesList.modelCode',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                          childrenItems:
                                                                                            [
                                                                                              (
                                                                                                ...args: any
                                                                                              ) => (
                                                                                                <Elements.Text
                                                                                                  pass={{
                                                                                                    arrProps:
                                                                                                      [
                                                                                                        {},
                                                                                                      ],

                                                                                                    arrStyles:
                                                                                                      [
                                                                                                        stls.color(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              arrayValue:
                                                                                                                [
                                                                                                                  jsvals.varReader(
                                                                                                                    {
                                                                                                                      pass: {
                                                                                                                        path: jsvals.j8(
                                                                                                                          {
                                                                                                                            pass: {
                                                                                                                              propertieValues:
                                                                                                                                'all.colors.grey2',
                                                                                                                            },
                                                                                                                          },
                                                                                                                        ),
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                                ],
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                        stls.fontSize(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              arrayValue:
                                                                                                                [
                                                                                                                  jsvals.varReader(
                                                                                                                    {
                                                                                                                      pass: {
                                                                                                                        path: jsvals.j8(
                                                                                                                          {
                                                                                                                            pass: {
                                                                                                                              propertieValues:
                                                                                                                                'all.textSizes.small',
                                                                                                                            },
                                                                                                                          },
                                                                                                                        ),
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ),
                                                                                                                ],
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],

                                                                                                    children:
                                                                                                      [
                                                                                                        jsvals.argReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              argPath:
                                                                                                                jsvals.j8(
                                                                                                                  {
                                                                                                                    pass: {
                                                                                                                      propertieValues:
                                                                                                                        '#0.0.0.0.0.item.modelCode',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ),
                                                                                                              args,
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],

                                                                                                    args,
                                                                                                  }}
                                                                                                />
                                                                                              ),
                                                                                            ],
                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.CustomListener
                                                                              pass={{
                                                                                variable:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'sc.A14.forms.editData.bikesList.modelName',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                                childrenItems:
                                                                                  [
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.Text
                                                                                        pass={{
                                                                                          arrProps:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          arrStyles:
                                                                                            [
                                                                                              stls.color(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.grey2',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontSize(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.textSizes.small',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          children:
                                                                                            [
                                                                                              jsvals.argReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    argPath:
                                                                                                      jsvals.j8(
                                                                                                        {
                                                                                                          pass: {
                                                                                                            propertieValues:
                                                                                                              '#0.0.0.0.item.modelName',
                                                                                                          },
                                                                                                        },
                                                                                                      ),
                                                                                                    args,
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                  ],
                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),

                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.DynView
                                                                              pass={{
                                                                                elementsProperties:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                styles:
                                                                                  [
                                                                                    stls.width(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '4px',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                variablePath:
                                                                                  [],

                                                                                expectedVal:
                                                                                  [],

                                                                                childrenItems:
                                                                                  [
                                                                                    () => (
                                                                                      <>

                                                                                      </>
                                                                                    ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.Text
                                                                              pass={{
                                                                                arrProps:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                arrStyles:
                                                                                  [
                                                                                    stls.fontSize(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'all.textSizes.small',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.fontWeight(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      'bold',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.color(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'all.colors.grey2',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                children:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'Valor por hora',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.CustomListener
                                                                              pass={{
                                                                                variable:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'sc.A14.forms.editData.bikesList.modelPrice',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                                childrenItems:
                                                                                  [
                                                                                    (
                                                                                      ...args: any
                                                                                    ) => (
                                                                                      <Elements.Text
                                                                                        pass={{
                                                                                          arrProps:
                                                                                            [
                                                                                              {},
                                                                                            ],

                                                                                          arrStyles:
                                                                                            [
                                                                                              stls.color(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.colors.grey2',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                              stls.fontSize(
                                                                                                {
                                                                                                  pass: {
                                                                                                    arrayValue:
                                                                                                      [
                                                                                                        jsvals.varReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              path: jsvals.j8(
                                                                                                                {
                                                                                                                  pass: {
                                                                                                                    propertieValues:
                                                                                                                      'all.textSizes.small',
                                                                                                                  },
                                                                                                                },
                                                                                                              ),
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          children:
                                                                                            [
                                                                                              customs.currencyString(
                                                                                                {
                                                                                                  pass: {
                                                                                                    value:
                                                                                                      [
                                                                                                        jsvals.argReader(
                                                                                                          {
                                                                                                            pass: {
                                                                                                              argPath:
                                                                                                                jsvals.j8(
                                                                                                                  {
                                                                                                                    pass: {
                                                                                                                      propertieValues:
                                                                                                                        '#0.0.0.0.item.modelPrice',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ),
                                                                                                              args,
                                                                                                            },
                                                                                                          },
                                                                                                        ),
                                                                                                      ],
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],

                                                                                          args,
                                                                                        }}
                                                                                      />
                                                                                    ),
                                                                                  ],
                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.flexDirection({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'row',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Tempo contratado: ',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.CustomListener
                                                            pass={{
                                                              variable: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A14.forms.editData.formattedHours',
                                                                  },
                                                                }),
                                                              ],
                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.colors.grey2',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.fontSize(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.textSizes.small',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'sc.A14.forms.editData.formattedHours',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],
                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.flexDirection({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'row',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Valor: ',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.CustomListener
                                                            pass={{
                                                              variable: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A14.forms.editData.totalPrice',
                                                                  },
                                                                }),
                                                              ],
                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.colors.grey2',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.fontSize(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.textSizes.small',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          customs.currencyString(
                                                                            {
                                                                              pass: {
                                                                                value:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'sc.A14.forms.editData.totalPrice',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],
                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.white',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.padding({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '12px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '15px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.shadows({
                                                  pass: {
                                                    shadowColor: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '#000000',
                                                        },
                                                      }),
                                                    ],
                                                    shadowOffset: [
                                                      '{width: 0, height: 0}',
                                                    ],
                                                    shadowOpacity: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '0.2',
                                                        },
                                                      }),
                                                    ],
                                                    shadowRadius: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [
                                                (...args) => (
                                                  <customs.CountTimer
                                                    pass={{
                                                      pathToCheck: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B5.toggles.timerSeconds',
                                                          },
                                                        }),
                                                      ],
                                                      pathRunning: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B5.toggles.timerRunning',
                                                          },
                                                        }),
                                                      ],
                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [[]],

                                                      variablePath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B5.toggles.timerRunning',
                                                          },
                                                        }),
                                                      ],

                                                      expectedVal: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'waiting',
                                                          },
                                                        }),
                                                      ],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.textAlign({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                'center',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Aguardando',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Pressable3
                                                            pass={{
                                                              elementProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.backgroundColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.primary',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderRadius(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '15px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '90px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.alignItems(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'center',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.justifyContent(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'center',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '25px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.alignSelf({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                'center',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    'white',
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'Iniciar',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],

                                                              pressableFunctions:
                                                                [
                                                                  (...args) => {
                                                                    customs.custom04(
                                                                      {
                                                                        args,
                                                                        pass: {},
                                                                      },
                                                                    );
                                                                  },
                                                                  async (
                                                                    ...args
                                                                  ) =>
                                                                    functions.setVar(
                                                                      {
                                                                        args,
                                                                        pass: {
                                                                          keyPath:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'sc.B5.toggles.timerRunning',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          value:
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'true',
                                                                                },
                                                                              },
                                                                            ),
                                                                        },
                                                                      },
                                                                    ),
                                                                ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [[]],

                                                      variablePath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B5.toggles.timerRunning',
                                                          },
                                                        }),
                                                      ],

                                                      expectedVal: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'true',
                                                          },
                                                        }),
                                                      ],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.textAlign({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                'center',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Em viagem',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                        (...args: any) => (
                                                          <Elements.Pressable3
                                                            pass={{
                                                              elementProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.borderRadius(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '15px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '90px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.alignItems(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'center',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.justifyContent(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'center',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '25px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.backgroundColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.white',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.deepPurple',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderWidth(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '1px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.alignSelf({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                'center',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.colors.deepPurple',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'Parar',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],

                                                              pressableFunctions:
                                                                [
                                                                  (...args) => {
                                                                    customs.custom04(
                                                                      {
                                                                        args,
                                                                        pass: {},
                                                                      },
                                                                    );
                                                                  },
                                                                  async (
                                                                    ...args
                                                                  ) =>
                                                                    functions.setVar(
                                                                      {
                                                                        args,
                                                                        pass: {
                                                                          keyPath:
                                                                            [
                                                                              jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'sc.B5.toggles.timerRunning',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            ],
                                                                          value:
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'false',
                                                                                },
                                                                              },
                                                                            ),
                                                                        },
                                                                      },
                                                                    ),
                                                                ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [[]],

                                                      variablePath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B5.toggles.timerRunning',
                                                          },
                                                        }),
                                                      ],

                                                      expectedVal: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'false',
                                                          },
                                                        }),
                                                      ],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.textAlign({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                'center',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Finalizado',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '60px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderWidth({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '1px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderRadius({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.ice',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.alignItems({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'center',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.padding({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.backgroundColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.white',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.justifyContent({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'space-between',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.flexDirection({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'row',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.backgroundColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.white',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Text
                                                                    pass={{
                                                                      arrProps:
                                                                        [{}],

                                                                      arrStyles:
                                                                        [
                                                                          stls.fontSize(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.textSizes.small',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.fontWeight(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'bold',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                          stls.color(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'all.colors.grey2',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      children:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'Custo adicional',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),

                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.DynView
                                                                    pass={{
                                                                      elementsProperties:
                                                                        [{}],

                                                                      styles: [
                                                                        stls.height(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '4px',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                        stls.width(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '100%',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],

                                                                      variablePath:
                                                                        [],

                                                                      expectedVal:
                                                                        [],

                                                                      childrenItems:
                                                                        [
                                                                          () => (
                                                                            <>

                                                                            </>
                                                                          ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.CustomListener
                                                                    pass={{
                                                                      variable:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'sc.A14.currData.priceOver',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                      childrenItems:
                                                                        [
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.Text
                                                                              pass={{
                                                                                arrProps:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                arrStyles:
                                                                                  [
                                                                                    stls.color(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'all.colors.grey2',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.fontSize(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.varReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    path: jsvals.j8(
                                                                                                      {
                                                                                                        pass: {
                                                                                                          propertieValues:
                                                                                                            'all.textSizes.small',
                                                                                                        },
                                                                                                      },
                                                                                                    ),
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.padding(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '2px',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                children:
                                                                                  [
                                                                                    jsvals.varReader(
                                                                                      {
                                                                                        pass: {
                                                                                          path: jsvals.j8(
                                                                                            {
                                                                                              pass: {
                                                                                                propertieValues:
                                                                                                  'sc.A14.currData.priceOver',
                                                                                              },
                                                                                            },
                                                                                          ),
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                        ],
                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '4px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [[]],

                                                              variablePath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A14.forms.editData.chargedExtraAmount',
                                                                  },
                                                                }),
                                                              ],

                                                              expectedVal: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'false',
                                                                  },
                                                                }),
                                                              ],

                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.Pressable3
                                                                    pass={{
                                                                      elementProperties:
                                                                        [{}],

                                                                      styles: [
                                                                        stls.backgroundColor(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.varReader(
                                                                                    {
                                                                                      pass: {
                                                                                        path: jsvals.j8(
                                                                                          {
                                                                                            pass: {
                                                                                              propertieValues:
                                                                                                'all.colors.primary',
                                                                                            },
                                                                                          },
                                                                                        ),
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                        stls.borderRadius(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '15px',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                        stls.alignItems(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'center',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                        stls.justifyContent(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          'center',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                        stls.height(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '26px',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                        stls.padding(
                                                                          {
                                                                            pass: {
                                                                              arrayValue:
                                                                                [
                                                                                  jsvals.j8(
                                                                                    {
                                                                                      pass: {
                                                                                        propertieValues:
                                                                                          '10px',
                                                                                      },
                                                                                    },
                                                                                  ),
                                                                                ],
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],

                                                                      childrenItems:
                                                                        [
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <Elements.Text
                                                                              pass={{
                                                                                arrProps:
                                                                                  [
                                                                                    {},
                                                                                  ],

                                                                                arrStyles:
                                                                                  [
                                                                                    stls.color(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              'white',
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                    stls.fontSize(
                                                                                      {
                                                                                        pass: {
                                                                                          arrayValue:
                                                                                            [
                                                                                              jsvals.j8(
                                                                                                {
                                                                                                  pass: {
                                                                                                    propertieValues:
                                                                                                      '11px',
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                            ],
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                children:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'COBRAR',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],

                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                        ],

                                                                      pressableFunctions:
                                                                        [
                                                                          () =>
                                                                            console.log(
                                                                              'Você Clicou!',
                                                                            ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        () => <></>,
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),

                                                (...args: any) => (
                                                  <Elements.DynView
                                                    pass={{
                                                      elementsProperties: [{}],

                                                      styles: [
                                                        stls.height({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '180px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.width({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '100%',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderWidth({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '1px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderRadius({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '10px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.borderColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.ice',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.padding({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '20px',
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                        stls.backgroundColor({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.white',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      variablePath: [],

                                                      expectedVal: [],

                                                      childrenItems: [
                                                        (...args: any) => (
                                                          <Elements.Text
                                                            pass={{
                                                              arrProps: [{}],

                                                              arrStyles: [
                                                                stls.fontSize({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.textSizes.small',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.fontWeight(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'bold',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.color({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.varReader(
                                                                          {
                                                                            pass: {
                                                                              path: jsvals.j8(
                                                                                {
                                                                                  pass: {
                                                                                    propertieValues:
                                                                                      'all.colors.grey2',
                                                                                  },
                                                                                },
                                                                              ),
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              children: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Bicicleta Devolvida?',
                                                                  },
                                                                }),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.backgroundColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.white',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderBottomWidth(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '1px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderBottomColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.grey',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.CustomListener
                                                                    pass={{
                                                                      variable:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'sc.A14.forms.editData.bikeReturned',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                      childrenItems:
                                                                        [
                                                                          (
                                                                            ...args: any
                                                                          ) => (
                                                                            <customs.IptPicker
                                                                              pass={{
                                                                                pathList:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'mock.A14.lists.pickerStatus',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                                pathEdit:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'sc.A14.forms.editData.bikeReturned',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                                arrFuncs:
                                                                                  [
                                                                                    async (
                                                                                      ...args
                                                                                    ) =>
                                                                                      functions.setVar(
                                                                                        {
                                                                                          args,
                                                                                          pass: {
                                                                                            keyPath:
                                                                                              [
                                                                                                jsvals.j8(
                                                                                                  {
                                                                                                    pass: {
                                                                                                      propertieValues:
                                                                                                        'sc.A14.forms.editData.bikeReturned',
                                                                                                    },
                                                                                                  },
                                                                                                ),
                                                                                              ],
                                                                                            value:
                                                                                              jsvals.argReader(
                                                                                                {
                                                                                                  pass: {
                                                                                                    argPath:
                                                                                                      jsvals.j8(
                                                                                                        {
                                                                                                          pass: {
                                                                                                            propertieValues:
                                                                                                              '#1',
                                                                                                          },
                                                                                                        },
                                                                                                      ),
                                                                                                    args,
                                                                                                  },
                                                                                                },
                                                                                              ),
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                  ],
                                                                                pathReturn:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            'sc.A14.forms.editData.bikeReturned',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                                args,
                                                                              }}
                                                                            />
                                                                          ),
                                                                        ],
                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.height({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                () => <></>,
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),

                                                        (...args: any) => (
                                                          <Elements.DynView
                                                            pass={{
                                                              elementsProperties:
                                                                [{}],

                                                              styles: [
                                                                stls.padding({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '10px',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.alignItems(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'center',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderRadius(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '15px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.borderWidth(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  '1px',
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                                stls.alignSelf({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                'center',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.width({
                                                                  pass: {
                                                                    arrayValue:
                                                                      [
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '100%',
                                                                            },
                                                                          },
                                                                        ),
                                                                      ],
                                                                  },
                                                                }),
                                                                stls.borderColor(
                                                                  {
                                                                    pass: {
                                                                      arrayValue:
                                                                        [
                                                                          jsvals.varReader(
                                                                            {
                                                                              pass: {
                                                                                path: jsvals.j8(
                                                                                  {
                                                                                    pass: {
                                                                                      propertieValues:
                                                                                        'all.colors.grey',
                                                                                    },
                                                                                  },
                                                                                ),
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],
                                                                    },
                                                                  },
                                                                ),
                                                              ],

                                                              variablePath: [],

                                                              expectedVal: [],

                                                              childrenItems: [
                                                                (
                                                                  ...args: any
                                                                ) => (
                                                                  <Elements.IptTxt2
                                                                    pass={{
                                                                      propsArray:
                                                                        [
                                                                          (
                                                                            ...args: any
                                                                          ) =>
                                                                            props.pt24(
                                                                              {
                                                                                pass: {
                                                                                  A: [
                                                                                    'true',
                                                                                  ],
                                                                                },
                                                                              },
                                                                            ),
                                                                          (
                                                                            ...args: any
                                                                          ) =>
                                                                            props.pt23(
                                                                              {
                                                                                pass: {
                                                                                  A: [
                                                                                    '4',
                                                                                  ],
                                                                                },
                                                                              },
                                                                            ),
                                                                        ],

                                                                      stylesArray:
                                                                        [
                                                                          stls.width(
                                                                            {
                                                                              pass: {
                                                                                arrayValue:
                                                                                  [
                                                                                    jsvals.j8(
                                                                                      {
                                                                                        pass: {
                                                                                          propertieValues:
                                                                                            '100%',
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                  ],
                                                                              },
                                                                            },
                                                                          ),
                                                                        ],

                                                                      funcsArray:
                                                                        [
                                                                          async (
                                                                            ...args
                                                                          ) =>
                                                                            functions.setVar(
                                                                              {
                                                                                args,
                                                                                pass: {
                                                                                  keyPath:
                                                                                    [
                                                                                      jsvals.j8(
                                                                                        {
                                                                                          pass: {
                                                                                            propertieValues:
                                                                                              'sc.A14.forms.editData.reasonBlocking',
                                                                                          },
                                                                                        },
                                                                                      ),
                                                                                    ],
                                                                                  value:
                                                                                    jsvals.argReader(
                                                                                      {
                                                                                        pass: {
                                                                                          argPath:
                                                                                            jsvals.j8(
                                                                                              {
                                                                                                pass: {
                                                                                                  propertieValues:
                                                                                                    '#0',
                                                                                                },
                                                                                              },
                                                                                            ),
                                                                                          args,
                                                                                        },
                                                                                      },
                                                                                    ),
                                                                                },
                                                                              },
                                                                            ),
                                                                        ],

                                                                      args,
                                                                    }}
                                                                  />
                                                                ),
                                                              ],

                                                              args,
                                                            }}
                                                          />
                                                        ),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 40,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.padding({
                                                  pass: {
                                                    arrayValue: ['10px'],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '15px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '120px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '25px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.backgroundColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.shadows({
                                                  pass: {
                                                    shadowColor: ['#000'],
                                                    shadowOffset: [
                                                      '{width: 0, height: 0}',
                                                    ],
                                                    shadowOpacity: ['0.4px'],
                                                    shadowRadius: ['20px'],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              'white',
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'Salvar',
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                async (...args) =>
                                                  functions.firebase.updateDoc({
                                                    args,
                                                    pass: {
                                                      fbInit: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.fireInit',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      arrRefStrings: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'rents',
                                                          },
                                                        }),
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'sc.A12.currData.docId',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      arrPathData: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A14.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      arrFuncs: [() => {}],
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Pressable3
                                            pass={{
                                              elementProperties: [{}],

                                              styles: [
                                                stls.alignItems({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.justifyContent({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 40,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.padding({
                                                  pass: {
                                                    arrayValue: ['10px'],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '15px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '120px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderRadius({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '25px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '1px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.borderColor({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.primary',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              childrenItems: [
                                                (...args: any) => (
                                                  <Elements.Text
                                                    pass={{
                                                      arrProps: [{}],

                                                      arrStyles: [
                                                        stls.color({
                                                          pass: {
                                                            arrayValue: [
                                                              jsvals.varReader({
                                                                pass: {
                                                                  path: jsvals.j8(
                                                                    {
                                                                      pass: {
                                                                        propertieValues:
                                                                          'all.colors.deepPurple',
                                                                      },
                                                                    },
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                          },
                                                        }),
                                                      ],

                                                      children: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'Cancelar',
                                                          },
                                                        }),
                                                      ],

                                                      args,
                                                    }}
                                                  />
                                                ),
                                              ],

                                              pressableFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),

                                        (...args: any) => (
                                          <Elements.DynView
                                            pass={{
                                              elementsProperties: [{}],

                                              styles: [
                                                stls.height({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '10px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.width({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100%',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              variablePath: [],

                                              expectedVal: [],

                                              childrenItems: [() => <></>],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],
                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.flex({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '1px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.ice',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.shadows({
                          pass: {
                            shadowColor: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '#000000',
                                },
                              }),
                            ],
                            shadowOffset: ['{width: 0, height: 0}'],
                            shadowOpacity: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '0.2',
                                },
                              }),
                            ],
                            shadowRadius: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'all.toggles.activeForm',
                              },
                            }),
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'a15admprofile',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'space-between',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flexDirection({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'row',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '60px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingHorizontal({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 10,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '2px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'lightgrey',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Pressable3
                                    pass={{
                                      elementProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.SvgView1
                                            pass={{
                                              componentSvg: (
                                                Svg: any,
                                                SvgObj: any,
                                              ) => {
                                                const {
                                                  Defs,
                                                  Stop,
                                                  Path,
                                                  LinearGradient,
                                                  G,
                                                  Circle,
                                                  Rect,
                                                  Mask,
                                                } = SvgObj;
                                                return (props: any) => (
                                                  <Svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={14}
                                                    viewBox="0 0 20 14"
                                                    {...props}
                                                  >
                                                    <Path
                                                      fillRule="evenodd"
                                                      d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                                                      clipRule="evenodd"
                                                      {...props}
                                                    />
                                                  </Svg>
                                                );
                                              },

                                              svgOriginal: `
        <svg></svg>
      `,

                                              altura: '16px',

                                              largura: '16px',

                                              preenchimento: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.colors.primary',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      pressableFunctions: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.sideMenu',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'hide',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.toggles.activeForm',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'none',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.toggles.emptyField',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: 'no',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A3.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A5.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A7.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.iptsChanges',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A9.forms.editData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.iptsChanges',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.forms.editData',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    {},
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A11.toogles.newBikeImg',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    'false',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [[]],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.maxWidth({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            '100px',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'Formulário de',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 12,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues: 500,
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.textAlign({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'center',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'PERFIL DO ADMINISTRADOR',
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 40,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.alignItems({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues: 'all.colors.ice',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.padding({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.backgroundColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.varReader({
                                                pass: {
                                                  path: jsvals.j8({
                                                    pass: {
                                                      propertieValues:
                                                        'all.colors.white',
                                                    },
                                                  }),
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingHorizontal({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.paddingVertical({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '20px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '15px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadowOpacity({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '5px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.shadows({
                                          pass: {
                                            shadowColor: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '#000000',
                                                },
                                              }),
                                            ],
                                            shadowOffset: [
                                              '{width: 0, height: 0}',
                                            ],
                                            shadowOpacity: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '0.2',
                                                },
                                              }),
                                            ],
                                            shadowRadius: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '30px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.justifyContent({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'center',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [
                                        jsvals.varReader({
                                          pass: {
                                            path: 'true',
                                          },
                                        }),
                                      ],

                                      expectedVal: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'true',
                                          },
                                        }),
                                      ],

                                      childrenItems: [
                                        (...args: any) => (
                                          <Elements.Text
                                            pass={{
                                              arrProps: [{}],

                                              arrStyles: [
                                                stls.fontSize({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.textSizes.small',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.fontWeight({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'bold',
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                                stls.color({
                                                  pass: {
                                                    arrayValue: [
                                                      jsvals.varReader({
                                                        pass: {
                                                          path: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                'all.colors.grey2',
                                                            },
                                                          }),
                                                        },
                                                      }),
                                                    ],
                                                  },
                                                }),
                                              ],

                                              children: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.userData.userEmail',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],

                                              args,
                                            }}
                                          />
                                        ),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),
              ],

              args,
            }}
          />
        ),

        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.height({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '5px',
                        },
                      }),
                    ],
                  },
                }),
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [() => <></>],

              args,
            }}
          />
        ),

        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.height({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '10px',
                        },
                      }),
                    ],
                  },
                }),
                stls.width({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '100%',
                        },
                      }),
                    ],
                  },
                }),
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [() => <></>],

              args,
            }}
          />
        ),

        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.width({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '8px',
                        },
                      }),
                    ],
                  },
                }),
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [() => <></>],

              args,
            }}
          />
        ),

        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                {
                  backgroundColor: 'white',
                  justifyContent: 'center',
                  minHeight: 22,
                  width: '100%',
                },
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [
                (...args: any) => (
                  <Elements.SvgView1
                    pass={{
                      componentSvg: (Svg: any, SvgObj: any) => {
                        const {
                          Defs,
                          Stop,
                          Path,
                          LinearGradient,
                          G,
                          Circle,
                          Rect,
                          Mask,
                        } = SvgObj;
                        return (props: any) => (
                          <Svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={14}
                            viewBox="0 0 20 14"
                            {...props}
                          >
                            <Path
                              fillRule="evenodd"
                              d="M7.707.293a1 1 0 0 1 0 1.414L3.414 6H19a1 1 0 1 1 0 2H3.414l4.293 4.293a1 1 0 1 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
                              clipRule="evenodd"
                              {...props}
                            />
                          </Svg>
                        );
                      },

                      svgOriginal: `
        <svg></svg>
      `,

                      altura: '15px',

                      largura: '15px',

                      preenchimento: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'all.colors.primary',
                              },
                            }),
                          },
                        }),
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.SvgView1
                    pass={{
                      componentSvg: (Svg: any, SvgObj: any) => {
                        const {
                          Defs,
                          Stop,
                          Path,
                          LinearGradient,
                          G,
                          Circle,
                          Rect,
                          Mask,
                        } = SvgObj;
                        return (props: any) => (
                          <Svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={28}
                            height={28}
                            fill="red"
                            viewBox="0 0 28 28"
                            {...props}
                          >
                            
                            <Path
                              fill="#0F1729"
                              fillRule="evenodd"
                              d="M6.293 9.793a1 1 0 0 1 1.414 0L14 16.086l6.293-6.293a1 1 0 1 1 1.414 1.414l-6.823 6.823a1.25 1.25 0 0 1-1.768 0l-6.823-6.823a1 1 0 0 1 0-1.414Z"
                              clipRule="evenodd"
                            />
                          </Svg>
                        );
                      },

                      svgOriginal: `
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 9.79289C6.68342 9.40237 7.31658 9.40237 7.70711 9.79289L14 16.0858L20.2929 9.79289C20.6834 9.40237 21.3166 9.40237 21.7071 9.79289C22.0976 10.1834 22.0976 10.8166 21.7071 11.2071L14.8839 18.0303C14.3957 18.5185 13.6043 18.5185 13.1161 18.0303L6.29289 11.2071C5.90237 10.8166 5.90237 10.1834 6.29289 9.79289Z" fill="#0F1729"/> </svg>
      `,

                      altura: '20px',

                      largura: '20px',

                      preenchimento: ['transparent'],

                      args,
                    }}
                  />
                ),
              ],

              args,
            }}
          />
        ),

        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                {
                  backgroundColor: 'white',
                  justifyContent: 'center',
                  minHeight: 22,
                  width: '100%',
                },
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [
                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.primary',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.justifyContent({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'space-between',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.flexDirection({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'row',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.alignItems({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'center',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '60px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.paddingHorizontal({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 10,
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Pressable3
                            pass={{
                              elementProperties: [{}],

                              styles: [
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 40,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 40,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.SvgView1
                                    pass={{
                                      componentSvg: (Svg: any, SvgObj: any) => {
                                        const {
                                          Defs,
                                          Stop,
                                          Path,
                                          LinearGradient,
                                          G,
                                          Circle,
                                          Rect,
                                          Mask,
                                        } = SvgObj;
                                        return (props: any) => (
                                          <Svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 10"
                                            {...props}
                                          >
                                            
                                            <Path
                                              {...props}
                                              d="M0 1.5A1.5 1.5 0 0 1 1.5 0h17a1.5 1.5 0 0 1 0 3h-17A1.5 1.5 0 0 1 0 1.5ZM0 8.5A1.5 1.5 0 0 1 1.5 7h12a1.5 1.5 0 0 1 0 3h-12A1.5 1.5 0 0 1 0 8.5Z"
                                            />
                                          </Svg>
                                        );
                                      },

                                      svgOriginal: `
        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3.05979e-07 1.5C3.05979e-07 0.671573 0.671573 0 1.5 0H18.5C19.3284 0 20 0.671573 20 1.5C20 2.32843 19.3284 3 18.5 3H1.5C0.671573 3 3.05979e-07 2.32843 3.05979e-07 1.5Z" fill="white"/> <path d="M0 8.5C0 7.67157 0.671573 7 1.5 7H13.5C14.3284 7 15 7.67157 15 8.5C15 9.32843 14.3284 10 13.5 10H1.5C0.671573 10 0 9.32843 0 8.5Z" fill="white"/> </svg>
      `,

                                      altura: '16px',

                                      largura: '16px',

                                      preenchimento: [
                                        jsvals.varReader({
                                          pass: {
                                            path: jsvals.j8({
                                              pass: {
                                                propertieValues:
                                                  'all.colors.white',
                                              },
                                            }),
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              pressableFunctions: [
                                async (...args) =>
                                  functions.setVar({
                                    args,
                                    pass: {
                                      keyPath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'all.toggles.sideMenu',
                                          },
                                        }),
                                      ],
                                      value: jsvals.j8({
                                        pass: {
                                          propertieValues: 'show',
                                        },
                                      }),
                                    },
                                  }),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flex({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '1px',
                                        },
                                      }),
                                    ],
                                  },
                                }),

                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.flex({ pass: { arrayValue: ['1'] } }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.ImageBox
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '50px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '150px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      URIvariablePath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'https://firebasestorage.googleapis.com/v0/b/goodbike-cd338.appspot.com/o/images%2Flogo-no-bg.png?alt=media&token=c3d87ffd-08f1-4f6b-806b-ae60a31e7e04',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 40,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 40,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.ImageBox
                                    pass={{
                                      elementsProperties: [
                                        stls.resizeMode({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'contain',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      styles: [
                                        stls.borderRadius({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 75,
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.borderColor({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'white',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '40px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '40px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      URIvariablePath: [
                                        jsvals.varReader({
                                          pass: {
                                            path: jsvals.j8({
                                              pass: {
                                                propertieValues:
                                                  'all.userData.userImage',
                                              },
                                            }),
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        {
                          backgroundColor: 'white',
                          justifyContent: 'center',
                          minHeight: 22,
                          width: '100%',
                        },
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),
              ],

              args,
            }}
          />
        ),

        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.width({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 60,
                        },
                      }),
                    ],
                  },
                }),
                stls.height({ pass: { arrayValue: [10] } }),
                stls.backgroundColor({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'red',
                        },
                      }),
                    ],
                  },
                }),
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [() => <></>],

              args,
            }}
          />
        ),

        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.paddingHorizontal({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '30px',
                        },
                      }),
                    ],
                  },
                }),
                stls.width({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '200px',
                        },
                      }),
                    ],
                  },
                }),
                stls.borderRadius({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '20px',
                        },
                      }),
                    ],
                  },
                }),
                stls.flexDirection({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'row',
                        },
                      }),
                    ],
                  },
                }),
                stls.backgroundColor({
                  pass: {
                    arrayValue: [
                      jsvals.varReader({
                        pass: {
                          path: jsvals.j8({
                            pass: {
                              propertieValues: 'all.colors.white',
                            },
                          }),
                        },
                      }),
                    ],
                  },
                }),
                stls.justifyContent({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'space-between',
                        },
                      }),
                    ],
                  },
                }),
                stls.padding({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '6px',
                        },
                      }),
                    ],
                  },
                }),
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [
                (...args: any) => (
                  <Elements.IptTxtEdit
                    pass={{
                      propsArray: [{}],

                      stylesArray: [
                        stls.fontSize({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '12px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.paddingLeft({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '20px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      editPath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.A1.forms.iptsChanges.userCPF',
                          },
                        }),
                      ],

                      funcsArray: [
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues:
                                      'sc.A1.forms.iptsChanges.userCPF',
                                  },
                                }),
                              ],
                              value: jsvals.argReader({
                                pass: {
                                  argPath: jsvals.j8({
                                    pass: {
                                      propertieValues: '#0',
                                    },
                                  }),
                                  args,
                                },
                              }),
                            },
                          }),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.borderRadius({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '20px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.A4.cleanSearch',
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'show',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Pressable3
                            pass={{
                              elementProperties: [{}],

                              styles: [
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '#999',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.padding({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '5px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Text
                                    pass={{
                                      arrProps: [{}],

                                      arrStyles: [
                                        stls.color({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: 'white',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      children: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'X',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              pressableFunctions: [
                                async (...args) =>
                                  functions.setVar({
                                    args,
                                    pass: {
                                      keyPath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'sc.A1.forms.iptsChanges.userCPF',
                                          },
                                        }),
                                      ],
                                      value: jsvals.j8({
                                        pass: {
                                          propertieValues: '',
                                        },
                                      }),
                                    },
                                  }),
                                async (...args) =>
                                  functions.setVar({
                                    args,
                                    pass: {
                                      keyPath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'sc.A4.cleanSearch',
                                          },
                                        }),
                                      ],
                                      value: jsvals.j8({
                                        pass: {
                                          propertieValues: 'hidden',
                                        },
                                      }),
                                    },
                                  }),
                                async (...args) =>
                                  functions.funcGroup({
                                    args,
                                    pass: {
                                      arrFunctions: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A4.toggles.sideRight.show',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'false',
                                                },
                                              }),
                                            },
                                          }),
                                        async (...args) =>
                                          functions.firebase.getDocs({
                                            args,
                                            pass: {
                                              fbInit: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.fireInit',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              arrRefStrings: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'users',
                                                  },
                                                }),
                                              ],
                                              arrFuncs: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A4.currData.usersList',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                (...args) => {
                                                  // ---------- set Capsules Inputs
                                                  const itemsToLog = [
                                                    jsvals.argReader({
                                                      pass: {
                                                        argPath: jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              '#0',
                                                          },
                                                        }),
                                                        args,
                                                      },
                                                    }),
                                                  ];

                                                  // ---------- set Console Log
                                                  itemsToLog.forEach(item => {
                                                    if (
                                                      typeof item === 'function'
                                                    )
                                                      return console.log({
                                                        function: item,
                                                        response: item(args),
                                                      });

                                                    if (
                                                      typeof item === 'string'
                                                    ) {
                                                      // ---------- set Arguments Values (If Exists)
                                                      const checkArgs =
                                                        item.startsWith('#');
                                                      if (checkArgs)
                                                        item = tools.argSel(
                                                          args,
                                                          item,
                                                        );

                                                      // ---------- set Variables Values (If Exists)
                                                      const [
                                                        condVar,
                                                        varValue,
                                                      ] = tools.getVarValue(
                                                        item,
                                                        'noComponent',
                                                      );
                                                      if (condVar)
                                                        item = varValue;

                                                      return console.log(item);
                                                    }

                                                    console.log(item);
                                                  });
                                                },
                                              ],
                                            },
                                          }),
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A4.toggles.sideRight.show',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues: 'true',
                                                },
                                              }),
                                            },
                                          }),
                                      ],
                                    },
                                  }),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.borderRadius({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '20px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.A4.cleanSearch',
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'hidden',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Pressable3
                            pass={{
                              elementProperties: [{}],

                              styles: [
                                stls.flexDirection({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'row',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.SvgView1
                                    pass={{
                                      componentSvg: (Svg: any, SvgObj: any) => {
                                        const {
                                          Defs,
                                          Stop,
                                          Path,
                                          LinearGradient,
                                          G,
                                          Circle,
                                          Rect,
                                          Mask,
                                        } = SvgObj;
                                        return (props: any) => (
                                          <Svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            {...props}
                                          >
                                            
                                            <Path
                                              fillRule="evenodd"
                                              d="M11 5a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm-8 6a8 8 0 1 1 14.32 4.906l3.387 3.387a1 1 0 0 1-1.414 1.414l-3.387-3.387A8 8 0 0 1 3 11Z"
                                              clipRule="evenodd"
                                            />
                                          </Svg>
                                        );
                                      },

                                      svgOriginal: `
        <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.935028" y="0.35498" width="55.1775" height="55.1775" rx="6.26959" fill="#F5F5F5"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M35.2423 25.3459C35.2423 20.2006 31.0712 16.0295 25.9259 16.0295C20.7806 16.0295 16.6095 20.2006 16.6095 25.3459C16.6095 30.4912 20.7806 34.6623 25.9259 34.6623C28.0459 34.6623 30.0018 33.9531 31.5671 32.7607L38.2971 39.4907C38.7868 39.9805 39.5809 39.9805 40.0707 39.4907C40.5604 39.0009 40.5604 38.2069 40.0707 37.7171L33.3407 30.9871C34.5331 29.4218 35.2423 27.4659 35.2423 25.3459ZM25.9259 18.5378C29.686 18.5378 32.7341 21.5859 32.7341 25.3459C32.7341 27.2263 31.9734 28.9266 30.74 30.16C29.5066 31.3934 27.8063 32.1541 25.9259 32.1541C22.1659 32.1541 19.1178 29.106 19.1178 25.3459C19.1178 21.5859 22.1659 18.5378 25.9259 18.5378Z" fill="black"/> </svg>
      `,

                                      altura: '20px',

                                      largura: '20px',

                                      preenchimento: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'grey',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              pressableFunctions: [
                                async (...args) =>
                                  functions.firebase.where({
                                    args,
                                    pass: {
                                      fbInit: [
                                        jsvals.varReader({
                                          pass: {
                                            path: jsvals.j8({
                                              pass: {
                                                propertieValues: 'all.fireInit',
                                              },
                                            }),
                                          },
                                        }),
                                      ],
                                      arrRefStrings: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'users',
                                          },
                                        }),
                                      ],
                                      arrWhere: [
                                        (...args) =>
                                          functions.firebase.whereConds({
                                            args,
                                            pass: {
                                              arrStrings: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: 'userCPF',
                                                  },
                                                }),
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues: '==',
                                                  },
                                                }),
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'sc.A1.forms.iptsChanges.userCPF',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                            },
                                          }),
                                      ],
                                      arrFuncs: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A4.currData.usersList',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.argReader({
                                                pass: {
                                                  argPath: jsvals.j8({
                                                    pass: {
                                                      propertieValues: '#0',
                                                    },
                                                  }),
                                                  args,
                                                },
                                              }),
                                            },
                                          }),
                                        (...args) => {
                                          // ---------- set Capsules Inputs
                                          const itemsToLog = [
                                            jsvals.j8({
                                              pass: {
                                                propertieValues:
                                                  '<<<all.userData>>>',
                                              },
                                            }),
                                            jsvals.varReader({
                                              pass: {
                                                path: jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'all.userData',
                                                  },
                                                }),
                                              },
                                            }),
                                          ];

                                          // ---------- set Console Log
                                          itemsToLog.forEach(item => {
                                            if (typeof item === 'function')
                                              return console.log({
                                                function: item,
                                                response: item(args),
                                              });

                                            if (typeof item === 'string') {
                                              // ---------- set Arguments Values (If Exists)
                                              const checkArgs =
                                                item.startsWith('#');
                                              if (checkArgs)
                                                item = tools.argSel(args, item);

                                              // ---------- set Variables Values (If Exists)
                                              const [condVar, varValue] =
                                                tools.getVarValue(
                                                  item,
                                                  'noComponent',
                                                );
                                              if (condVar) item = varValue;

                                              return console.log(item);
                                            }

                                            console.log(item);
                                          });
                                        },
                                      ],
                                    },
                                  }),
                                async (...args) =>
                                  functions.setVar({
                                    args,
                                    pass: {
                                      keyPath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'sc.A4.cleanSearch',
                                          },
                                        }),
                                      ],
                                      value: jsvals.j8({
                                        pass: {
                                          propertieValues: 'show',
                                        },
                                      }),
                                    },
                                  }),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '30px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),
              ],

              args,
            }}
          />
        ),

        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.backgroundColor({
                  pass: {
                    arrayValue: [
                      jsvals.varReader({
                        pass: {
                          path: jsvals.j8({
                            pass: {
                              propertieValues: 'all.colors.white',
                            },
                          }),
                        },
                      }),
                    ],
                  },
                }),
                stls.width({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '100%',
                        },
                      }),
                    ],
                  },
                }),
                stls.alignItems({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'center',
                        },
                      }),
                    ],
                  },
                }),
                stls.padding({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '12px',
                        },
                      }),
                    ],
                  },
                }),
                stls.borderRadius({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '15px',
                        },
                      }),
                    ],
                  },
                }),
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [
                (...args) => (
                  <customs.CountTimer
                    pass={{
                      pathToCheck: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.B5.toggles.timerSeconds',
                          },
                        }),
                      ],
                      pathRunning: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.B5.toggles.timerRunning',
                          },
                        }),
                      ],
                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [[]],

                      variablePath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.B5.toggles.timerRunning',
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'true',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [{ color: 'black', fontSize: 12 }],

                              children: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'Em viagem',
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [[]],

                      variablePath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.B5.toggles.timerRunning',
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'false',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [{ color: 'black', fontSize: 12 }],

                              children: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'Finalizado',
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '60px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.borderWidth({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '1px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.borderRadius({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.borderColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.ice',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.alignItems({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'center',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.padding({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.white',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.justifyContent({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'space-between',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.flexDirection({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'row',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Text
                                    pass={{
                                      arrProps: [{}],

                                      arrStyles: [
                                        { color: 'black', fontSize: 12 },
                                      ],

                                      children: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'Custo adicional',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),

                                (...args: any) => (
                                  <Elements.DynView
                                    pass={{
                                      elementsProperties: [{}],

                                      styles: [
                                        stls.height({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '10px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                        stls.width({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '100%',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      variablePath: [],

                                      expectedVal: [],

                                      childrenItems: [() => <></>],

                                      args,
                                    }}
                                  />
                                ),
                                (...args: any) => (
                                  <Elements.Text
                                    pass={{
                                      arrProps: [{}],

                                      arrStyles: [
                                        { color: 'black', fontSize: 12 },
                                      ],

                                      children: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'R$ 0,00',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),
                        (...args: any) => (
                          <Elements.Pressable3
                            pass={{
                              elementProperties: [{}],

                              styles: [
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.primary',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderRadius({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '15px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '105px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '40px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.padding({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Text
                                    pass={{
                                      arrProps: [{}],

                                      arrStyles: [
                                        stls.color({
                                          pass: { arrayValue: ['white'] },
                                        }),
                                        stls.fontSize({
                                          pass: {
                                            arrayValue: [
                                              jsvals.j8({
                                                pass: {
                                                  propertieValues: '12px',
                                                },
                                              }),
                                            ],
                                          },
                                        }),
                                      ],

                                      children: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'COBRAR VALOR ADICIONAL',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              pressableFunctions: [
                                () => console.log('Você Clicou!'),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '180px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.borderWidth({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '1px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.borderRadius({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.borderColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.ice',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.padding({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '20px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.white',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [{ color: 'black', fontSize: 12 }],

                              children: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'Bicicleta Devolvida?',
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '1px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.grey',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.CustomListener
                                    pass={{
                                      variable: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'sc.A14.currData.bikeReturned',
                                          },
                                        }),
                                      ],
                                      childrenItems: [
                                        (...args: any) => (
                                          <customs.IptPicker
                                            pass={{
                                              pathList: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'mock.A14.lists.pickerStatus',
                                                  },
                                                }),
                                              ],
                                              pathEdit: [''],
                                              arrFuncs: [
                                                (...args) => {
                                                  customs.processCheckout({
                                                    args,
                                                    pass: {},
                                                  });
                                                },
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A14.forms.iptsChanges.bikeReturned',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#1',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                              pathReturn: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A14.forms.editData.bikeReturned',
                                                  },
                                                }),
                                              ],
                                              args,
                                            }}
                                          />
                                        ),
                                      ],
                                      args,
                                    }}
                                  />
                                ),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '80px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '1px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderRadius({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues: 'all.colors.ice',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.alignItems({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'center',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.padding({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.white',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.justifyContent({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'space-between',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),
              ],

              args,
            }}
          />
        ),
      ],

      startFunctions: [
        async (...args) =>
          functions.funcGroup({
            args,
            pass: {
              arrFunctions: [
                (...args) =>
                  customs.pagarme.createOrder({
                    args,
                    pass: {
                      ipt1: [''],
                      ipt2: [''],
                      ipt3: [''],
                      ipt4: [() => {}],
                    },
                  }),
              ],
            },
          }),
        async (...args) =>
          functions.firebase.where({
            args,
            pass: {
              fbInit: [
                jsvals.varReader({
                  pass: {
                    path: jsvals.j8({
                      pass: {
                        propertieValues: 'all.fireInit',
                      },
                    }),
                  },
                }),
              ],
              arrRefStrings: [
                jsvals.j8({
                  pass: {
                    propertieValues: 'bikeModels',
                  },
                }),
              ],
              arrWhere: [
                (...args) =>
                  functions.firebase.whereConds({
                    args,
                    pass: {
                      arrStrings: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'modelCode',
                          },
                        }),
                        jsvals.j8({
                          pass: {
                            propertieValues: '==',
                          },
                        }),
                        jsvals.j8({
                          pass: {
                            propertieValues: 'KX93',
                          },
                        }),
                      ],
                    },
                  }),
                (...args) =>
                  functions.firebase.whereConds({
                    args,
                    pass: {
                      arrStrings: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'modelName',
                          },
                        }),
                        jsvals.j8({
                          pass: {
                            propertieValues: '==',
                          },
                        }),
                        jsvals.j8({
                          pass: {
                            propertieValues: 'aa',
                          },
                        }),
                      ],
                    },
                  }),
              ],
              arrFuncs: [() => {}],
            },
          }),
        (...args) =>
          functions.firebase.deleteDoc({
            args,
            pass: {
              fbInit: [
                jsvals.varReader({
                  pass: {
                    path: jsvals.j8({
                      pass: {
                        propertieValues: 'all.fireInit',
                      },
                    }),
                  },
                }),
              ],
              arrRefStrings: [
                jsvals.j8({
                  pass: {
                    propertieValues: 'bikeModels',
                  },
                }),
                jsvals.j8({
                  pass: {
                    propertieValues: 'yT31jpo2BdIaQug1Jcos',
                  },
                }),
              ],
              arrFuncs: [() => {}],
            },
          }),
        async (...args) =>
          functions.firebase.where({
            args,
            pass: {
              fbInit: [
                jsvals.varReader({
                  pass: {
                    path: jsvals.j8({
                      pass: {
                        propertieValues: 'all.fireInit',
                      },
                    }),
                  },
                }),
              ],
              arrRefStrings: [
                jsvals.j8({
                  pass: {
                    propertieValues: 'permissions',
                  },
                }),
              ],
              arrWhere: [
                (...args) =>
                  functions.firebase.whereConds({
                    args,
                    pass: {
                      arrStrings: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'userEmail',
                          },
                        }),
                        jsvals.j8({
                          pass: {
                            propertieValues: '==',
                          },
                        }),
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues:
                                  'sc.A1.forms.iptsChanges.login',
                              },
                            }),
                          },
                        }),
                      ],
                    },
                  }),
              ],
              arrFuncs: [
                async (...args) =>
                  functions.setVar({
                    args,
                    pass: {
                      keyPath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'all.userData',
                          },
                        }),
                      ],
                      value: jsvals.argReader({
                        pass: {
                          argPath: jsvals.j8({
                            pass: {
                              propertieValues: '#0.0',
                            },
                          }),
                          args,
                        },
                      }),
                    },
                  }),
                async (...args) =>
                  functions.setVar({
                    args,
                    pass: {
                      keyPath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.A6.currData.currLocationName',
                          },
                        }),
                      ],
                      value: jsvals.argReader({
                        pass: {
                          argPath: jsvals.j8({
                            pass: {
                              propertieValues: '#0.0.locationName',
                            },
                          }),
                          args,
                        },
                      }),
                    },
                  }),
                async (...args) =>
                  functions.setVar({
                    args,
                    pass: {
                      keyPath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.A6.currData.currId',
                          },
                        }),
                      ],
                      value: jsvals.argReader({
                        pass: {
                          argPath: jsvals.j8({
                            pass: {
                              propertieValues: '#0.0.locationId',
                            },
                          }),
                          args,
                        },
                      }),
                    },
                  }),
                async (...args) =>
                  functions.condition({
                    args,
                    pass: {
                      conditionPath: [
                        jsvals.argReader({
                          pass: {
                            argPath: jsvals.j8({
                              pass: {
                                propertieValues: '#0.0.userPassword',
                              },
                            }),
                            args,
                          },
                        }),
                      ],
                      expectedValue: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues:
                                  'sc.A1.forms.iptsChanges.password',
                              },
                            }),
                          },
                        }),
                      ],
                      functionsTrue: [
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'sc.A1.toggles.msgError',
                                  },
                                }),
                              ],
                              value: jsvals.j8({
                                pass: {
                                  propertieValues: '',
                                },
                              }),
                            },
                          }),

                        (...args) => {
                          // ---------- get Function from A_Project Scope
                          return tools.goTo('a2permissions');
                        },
                        async (...args) =>
                          functions.condition({
                            args,
                            pass: {
                              conditionPath: [
                                jsvals.argReader({
                                  pass: {
                                    argPath: jsvals.j8({
                                      pass: {
                                        propertieValues: '#0.0.0.typeAccount',
                                      },
                                    }),
                                    args,
                                  },
                                }),
                              ],
                              expectedValue: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'adm',
                                  },
                                }),
                              ],
                              functionsTrue: [
                                (...args) => {
                                  // ---------- get Function from A_Project Scope
                                  return tools.goTo('a2permissions');
                                },
                              ],
                              functionsFalse: [
                                (...args) => {
                                  // ---------- get Function from A_Project Scope
                                  return tools.goTo('a13rents');
                                },
                              ],
                              args,
                            },
                          }),
                      ],
                      functionsFalse: [
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'sc.A1.toggles.msgError',
                                  },
                                }),
                              ],
                              value: jsvals.j8({
                                pass: {
                                  propertieValues: 'Email ou senha incorretos.',
                                },
                              }),
                            },
                          }),
                      ],
                      args,
                    },
                  }),
              ],
            },
          }),
        async (...args) =>
          functions.firebase.updateDoc({
            args,
            pass: {
              fbInit: [
                jsvals.varReader({
                  pass: {
                    path: jsvals.j8({
                      pass: {
                        propertieValues: 'all.fireInit',
                      },
                    }),
                  },
                }),
              ],
              arrRefStrings: [
                jsvals.j8({
                  pass: {
                    propertieValues: 'users',
                  },
                }),
                jsvals.varReader({
                  pass: {
                    path: jsvals.j8({
                      pass: {
                        propertieValues: 'sc.A5.currData.itemID',
                      },
                    }),
                  },
                }),
              ],
              arrPathData: [
                jsvals.j8({
                  pass: {
                    propertieValues: 'sc.A5.forms.editData',
                  },
                }),
              ],
              arrFuncs: [
                async (...args) =>
                  functions.funcGroup({
                    args,
                    pass: {
                      arrFunctions: [
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.varReader({
                                  pass: {
                                    path: jsvals.j8({
                                      pass: {
                                        propertieValues: 'all.toggles.sideMenu',
                                      },
                                    }),
                                  },
                                }),
                              ],
                              value: jsvals.j8({
                                pass: {
                                  propertieValues: 'hide',
                                },
                              }),
                            },
                          }),
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.varReader({
                                  pass: {
                                    path: jsvals.j8({
                                      pass: {
                                        propertieValues:
                                          'all.toggles.activeForm',
                                      },
                                    }),
                                  },
                                }),
                              ],
                              value: jsvals.j8({
                                pass: {
                                  propertieValues: 'none',
                                },
                              }),
                            },
                          }),
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'all.toggles.emptyField',
                                  },
                                }),
                              ],
                              value: jsvals.j8({
                                pass: {
                                  propertieValues: 'no',
                                },
                              }),
                            },
                          }),
                      ],
                    },
                  }),
              ],
            },
          }),
        async (...args) =>
          functions.firebase.getDocs({
            args,
            pass: {
              fbInit: [
                jsvals.varReader({
                  pass: {
                    path: jsvals.j8({
                      pass: {
                        propertieValues: 'all.fireInit',
                      },
                    }),
                  },
                }),
              ],
              arrRefStrings: [
                jsvals.j8({
                  pass: {
                    propertieValues: 'locations',
                  },
                }),
                jsvals.varReader({
                  pass: {
                    path: jsvals.j8({
                      pass: {
                        propertieValues: 'sc.A6.currData.itemID',
                      },
                    }),
                  },
                }),
              ],
              arrFuncs: [
                async (...args) =>
                  functions.setVar({
                    args,
                    pass: {
                      keyPath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.A6.locationData',
                          },
                        }),
                      ],
                      value: jsvals.argReader({
                        pass: {
                          argPath: jsvals.j8({
                            pass: {
                              propertieValues: '#0',
                            },
                          }),
                          args,
                        },
                      }),
                    },
                  }),
                (...args) => {
                  // ---------- set Capsules Inputs
                  const itemsToLog = [
                    jsvals.j8({
                      pass: {
                        propertieValues: '<<<LOCATION DATA>>>',
                      },
                    }),
                    jsvals.varReader({
                      pass: {
                        path: jsvals.j8({
                          pass: {
                            propertieValues: 'sc.A6.locationData',
                          },
                        }),
                      },
                    }),
                  ];

                  // ---------- set Console Log
                  itemsToLog.forEach(item => {
                    if (typeof item === 'function')
                      return console.log({
                        function: item,
                        response: item(args),
                      });

                    if (typeof item === 'string') {
                      // ---------- set Arguments Values (If Exists)
                      const checkArgs = item.startsWith('#');
                      if (checkArgs) item = tools.argSel(args, item);

                      // ---------- set Variables Values (If Exists)
                      const [condVar, varValue] = tools.getVarValue(
                        item,
                        'noComponent',
                      );
                      if (condVar) item = varValue;

                      return console.log(item);
                    }

                    console.log(item);
                  });
                },
              ],
            },
          }),
      ],

      args,
    }}
  />
);
