// ---------- import React Packs
import React from 'react';
import * as RN from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaView, Platform, View } from 'react-native';

// ---------- import Variables Pack
// import { create } from 'zustand';

// ---------- import Local Tools
import { mapElements } from '../../tools/project/mapElements';
import { setData } from './setData';
import { useData } from '../..';
// import * as Elements from '../../tools/Elements';
// import * as stls from '../../tools/stls';
// import * as props from '../../tools/props';
// import * as jsvals from '../../tools/jsvals';
// import { tools } from '../../tools';

// ---------- export Index Functions
export { getStlValues } from './getStlValues';
export { getVarValue } from './getVarValue';
export { setArgsVars } from './setArgsVars';
export { mapElements } from './mapElements';
export { getCtData } from './getCtData';
export { pathSel } from './pathSel';
export { setData } from './setData';
export { argSel } from './argSel';
export { goTo } from './goTo';

// ---------- set Caps Inputs
const currRoute = '!#!initial screen!#!';

let args: any = [];

// const screens = [
//   !#!Lista de Telas!#!
// ]

// export const initCt = {
//   !#!Lista de Variáveis!#!
// };

// const arrInitFuncs = [
//   !#!Start Functions!#!
// ];

// export const useRoutes = create(() => ({currRoute}))
// export const useData = create(() => ({ ...initCt }));

type Tprops = {
  configData: {
    screens: any[];
    initCt: Record<string, any>;
    arrInitFuncs: (() => void)[];
  };
};

// ---------- set Main Component
export const Project = ({ configData }: Tprops) => {
  // ---------- set Data
  const { screens, arrInitFuncs } = configData;

  // ---------- call Functions (If Exists)
  React.useEffect(() => {
    const callFn = async () => {
      for (const currFunc of arrInitFuncs) await currFunc();
    };

    callFn().catch(err => console.log('Project Start Functions', { err }));
  }, []);

  const isMobile = useData(ct => ct.isMobile);
  // console.log({ isMobile });
  const condStlSc = isMobile
    ? { width: 300, height: 600, overflow: 'hidden' }
    : { width: '100%', height: '100%', overflow: 'hidden' };

  const fnChangeSc = () => {
    setData({ path: 'isMobile', value: !isMobile });
    // console.log({ isMobile });
  };

  const stlMobView: any = { flexDirection: 'column', width: '100%' };
  const stlDeskView: any = {
    flexDirection: 'column',
    width: 400,
    maxHeight: 800,
  };
  const condView = Platform.OS === 'web' ? stlDeskView : stlMobView;

  return (
    <RN.View style={stlMobView}>
      {/* {Platform.OS === 'web' && (
        <RN.Pressable style={{ width: '100%' }} onPress={fnChangeSc}>
          <RN.Text style={{ color: 'white' }}>
            Mobile: {isMobile ? 'on' : 'off'}
          </RN.Text>
        </RN.Pressable>
      )} */}
      <SafeAreaView style={condStlSc}>
        {Platform.OS !== 'web' && (
          <View style={{ height: 30, backgroundColor: '#450b2c' }} />
        )}
        {mapElements(screens)}
        <StatusBar style="light" />
      </SafeAreaView>
    </RN.View>
  );
};
