import { useData } from '../../..';
import { tools } from '../..';
import { BtnCounter } from './BtnCounter';
import { pathSel, setData } from '../../project';
import React from 'react';
import { setCurrPrice } from './setCurrPrice';

// ---------- set Curr Item
// const checkCurrItem = (bikesSelecteds, currItem) => {
//   let newIdx = 0;
//   let objFound;

//   bikesSelecteds &&
//     bikesSelecteds?.map((item: any, idx: number) => {
//       const match = item?.docId === currItem?.docId;

//       if (match) {
//         objFound = item;
//         newIdx = idx;
//       }
//     });

//   return { alreadyAdd: !!objFound, newIdx };
// };

type Tprops = { pass: { type: 'B3B' | 'B3C'; args: any } };

export const BtnUpdateAmount = (props: Tprops) => {
  const { args } = props.pass;

  // ---------- set Args Data
  const sel1 = args[0];
  const sel2 = sel1[0];
  const currItem = sel2[0].item;
  const currIdx = sel2[0].index;
  console.log({ currItem });

  // ---------- set Watch Data
  // const objPricesCalc = useData(ct => ct.sc.B3B?.currData?.objPricesCalc);
  const pathHours = 'sc.B3C.currData.selecHours';
  const bikesSelecteds: any[] = useData(
    ct => ct.sc.B3C?.currData?.mainList ?? [],
  );
  const selecHours = useData(ct => pathSel(ct, pathHours)) ?? {};

  // ---------- set Update Lists + Update Amount
  const pathAll = `sc.B3B.currData.mainList`;
  const pathSelected = `sc.B3C.currData.mainList`;
  const pathAmountAll = pathAll + `.${currIdx}.modelAmount`;

  const newItem = { ...currItem };
  const newAmount = newItem?.modelAmount;
  const isDisabled = newItem?.status === 'unavailable';
  console.log({ STATUS: newItem.status });

  // ---------- set CALLBACK FUNC
  // Add or Exclude
  const getValue = (count: number, mode: 'inc' | 'dec') => {
    const strCount = String(count);
    tools.setData({ path: pathAmountAll, value: strCount });

    // ----- set Map Items
    const newSelecteds = bikesSelecteds.map(item => {
      console.log('checkbikeData0102', item);
      const checkID = item.docId === currItem.docId;

      if (!checkID) return item;

      item.modelAmount = strCount;

      // const val1 = Number(item?.modelAmount);
      // const val2 = Number(item?.modelPrice);
      // const currCalc = val1 * val2;

      // setData({
      //   path: 'sc.B3B.currData.objPricesCalc',
      //   value: { ...objPricesCalc, [item.docId]: currCalc },
      // });

      return item;
    });

    // ---------- set Find Item To Remove Checkout List
    if (mode === 'dec') {
      tools.setData({ path: pathSelected, value: newSelecteds });
      console.log({ newSelecteds });

      // procura o índice p/ apagar
      const ixdToDel = newSelecteds.findIndex(i => i.docId === currItem.docId);
      console.log({ ixdToDel });

      // apaga item pelo índice encontrado
      const condDelete = count === 0; // remove da lista se for 0
      if (condDelete) {
        newSelecteds.splice(ixdToDel, 1);
        console.log({ newSelecteds });
        setData({ path: 'sc.B3C.currData.currPrice', value: '0' });
      }
    }

    // ---------- set Add Item To Checkout List
    if (mode === 'inc') {
      const findItem = newSelecteds.find(i => i.docId === currItem.docId);
      // console.log({ findItem });
      // console.log({ newItem });
      newItem.modelAmount = strCount;
      if (!findItem) newSelecteds.push(newItem); // se o item não existir então adicione 1x
      // console.log({ objPricesCalc });
    }

    // --- atualizar lista modificada (apagar ou incluir novo item)
    tools.setData({ path: pathSelected, value: newSelecteds });
  };

  React.useEffect(() => {
    const arrInit = bikesSelecteds ? Object.values(bikesSelecteds) : [];
    const condInit = arrInit.length === 0;

    if (condInit) {
      console.log('FIRST TIME - INIT');
      console.log('FIRST TIME - INIT', newItem);
      setCurrPrice(newItem, 1, 'firstTime');
    }
    // Update Price
    setCurrPrice(bikesSelecteds, selecHours, 'manyTimes');
  }, [bikesSelecteds]);

  // ---------- set Render Area JSX
  return (
    <BtnCounter
      initValue={Number(newAmount)}
      cbFunc={getValue}
      isDisabled={isDisabled}
    />
  );
};
