import { where } from '../../functions/firebase/where';
import { whereConds } from '../../functions/firebase/whereConds';
import { getCtData, setData } from '../../project';

type Tprops = {
  pass: {
    ipt1: any[];
    ipt2: string;
    ipt3: any[];
  };
  args?: any;
};

// ------------------ Objetivo é buscar e mostrar Dados das Bikes
// ------------------ filtrados por Ponto (loja).

// --------------------------------------------------------------
// ---------------------- MERGE DATA DE -------------------------
// --------------------------------------------------------------
// ------------------ /locations/ID/localBikes ------------------
// ------------------ com os dados de ---------------------------
// ------------------ /bikeModels -------------------------------

export const mergeLocationBikes = async (props: Tprops) => {
  const { ipt1, ipt2 } = props.pass;

  // ---------- set Local Imports
  const path1 = ipt1?.join() ?? '';
  const { documentId } = await import('@firebase/firestore');

  const fbInit = getCtData('all.fireInit');
  const localBikes = props?.args[0]; // collection localBikes (código, quantidade etc)
  const arrData = localBikes.map(i => i?.modelId) ?? [];

  console.log('local Bikes (curr location selected)', props.args);
  console.log({ arrData });
  if (arrData.length > 0) {
    // Bike Models (Foto, Preço p/ Hora)
    const bikeModels = await where({
      args: {},
      pass: {
        fbInit: [fbInit],
        arrRefStrings: [ipt2],
        arrWhere: [
          (...args) =>
            whereConds({
              args,
              pass: {
                arrStrings: [documentId(), 'in', arrData],
              },
            }),
        ],
        arrFuncs: [],
      },
    });

    const condPath1 = path1.includes('A10'); // sc.A10.currData.bikesList
    const condPath2 = path1.includes('B3B'); // sc.B3B.currData.mainList
    console.log({ bikeModels });

    const isArray = Array.isArray(bikeModels);
    const newArray = [];
    if (isArray) {
      // --- WHEN SCREEN IS A10 (DESKTOP)
      if (condPath1) {
        for (const key in localBikes) {
          const item = localBikes[key];
          const fnFindItem: any = i => i?.docId === item?.modelId;
          const foundModelDetails = bikeModels.find(fnFindItem);

          if (foundModelDetails) {
            item.localBikeId = item?.docId;
            item.imgUrl = foundModelDetails?.imgUrl;
            item.modelAmount = foundModelDetails?.modelAmount;
            item.modelName = foundModelDetails?.modelName;
            item.modelPrice = foundModelDetails?.modelPrice;

            // ---- MODEL AVAILABILITY
            item.status = item?.status;
          }

          newArray.push(item);
        }
      }

      // --- WHEN SCREEN IS B3B (MOBILE)
      if (condPath2) {
        for (const key in bikeModels) {
          const item = bikeModels[key];
          const fnFindItem: any = i => i?.modelId === item.docId;
          const fnFilterItems: any = i => {
            const check = i?.modelId === item.docId;
            const check2 = i?.status === 'available';

            return check && check2;
          };
          const countLocalBikeAmount = localBikes.filter(fnFilterItems).length;
          const foundLocalBAmount = localBikes.find(fnFindItem);

          if (foundLocalBAmount) {
            item.localBikeId = foundLocalBAmount?.docId;
            item.localBikeAmount = countLocalBikeAmount;
            item.modelCode = foundLocalBAmount?.modelCode;
            item.modelId = foundLocalBAmount?.modelId;

            // ---- CHECK MODEL AVAILABILITY
            if (countLocalBikeAmount < 1) item.status = 'unavailable';
            if (countLocalBikeAmount > 0) item.status = 'available';
          }

          newArray.push(item);
        }
      }

      console.log({ path1 });
      console.log({ newArray });
      setData({ path: path1, value: newArray });
    }
  }
};
