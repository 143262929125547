export const toDecimal = (selectedHours: string) => {
  const checkString = String(selectedHours);
  const splitNum = checkString.split('.'); // valor ex: 1.30
  const hours = splitNum[0] ?? 0;
  const minutes = splitNum[1] ?? 0;

  // Converta os minutos para fração de horas (30 minutos = 0,5 horas)
  const fractionHours = Number(minutes) / 60;
  const toDecimal = Number(hours) + fractionHours;

  return Number(toDecimal.toFixed(2));
};
