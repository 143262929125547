import React from 'react';
import { View } from 'react-native';
import MapView from 'react-native-maps';

type Tprops = {
  pass: {
    googlePlacesKey: string;
    args: any;
  };
};

// MapView
export const MapPlaces = (props: Tprops) => {
  // ---------- set Props
  const { googlePlacesKey, args } = props.pass;
  console.log({ googlePlacesKey });

  //   const MapView = require('react-native-maps');
  // console.log({ MapView });

  // ---------- set Render
  return <></>;
};
