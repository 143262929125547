import { argSel, getCtData, pathSel } from '../project';

type Tprops = {
  pass: {
    ipt1: any[];
    ipt2: string;
    ipt3: any[];
  };
  args?: any;
};

export const ordenateLists = async (props: Tprops) => {
  const { args, pass } = props;
  const { ipt1, ipt3 } = pass;

  console.log('ORDER LISTS', { args });

  const pathString = ipt1.join();
  const condHash = pathString.startsWith('#');
  let valueRes = getCtData(pathString);

  if (condHash) valueRes = argSel(args, pathString);

  if (valueRes) {
    console.log('ORDER LISTS', { valueRes });
    // const ordenateArray =
    valueRes.sort((a, b) => {
      // return a.timer.isRunning - b.timer.isRunning;
      const order = ['waiting', 'true', 'false'];
      return (
        order.indexOf(a.timer.isRunning) - order.indexOf(b.timer.isRunning)
      );
    });
    for (const currFunc of ipt3) await currFunc(valueRes, args);
  }
};
