type Tprops = {
  args: any;
  pass: {
    fbInit: any[];
    arrFiles: any[];
    arrFuncs: any[];
  };
};

const css1 =
  'color: #ffb73b; background-color: black; font-size: 11px; padding: 2px 6px; border-radius: 3px';
const css2 =
  'color: black; background-color: #ffb73b; font-size: 10px; padding: 2px 6px; border-radius: 3px';

export const uploadFile = async (props: Tprops) => {
  console.log('UPLOAD DE ARQUIVOS');
  // ---------- set Props
  const { args, pass } = props;
  const { fbInit, arrFiles, arrFuncs } = pass;
  console.log({ fbInit, arrFiles, arrFuncs });

  // ---------- set Local Imports
  const { getStorage, uploadBytes, uploadString, ref, getDownloadURL } =
    await import('@firebase/storage');

  // -----------------------------
  // -------- set Firestore Call 1
  // -----------------------------
  const storage = getStorage(fbInit[0]);
  const objData = arrFiles[0];
  console.log({ objData });

  const checkAssets = objData ? Object.values(objData?.assets) : [];
  console.log({ checkAssets });

  checkAssets &&
    checkAssets?.forEach(async (currData: any, idx: number) => {
      if (currData?.name) {
        const time = Date.now().toString();
        const strRefFile = ref(storage, `images/${time}${currData.name}`);
        console.log({ strRefFile });
        const file = objData.output[idx];
        console.log({ file });
        await uploadBytes(strRefFile, file);
        // await uploadString(strRefFile, currData.uri, 'data_url');

        // ---------- set Return Functions
        const firestoreURL = await getDownloadURL(strRefFile);

        for (const currFunc of arrFuncs)
          await currFunc(args, firestoreURL, idx);

        return console.log('%cUpload Sucess!', css1, firestoreURL);
      }

      if (!currData?.name) {
        for (const currFunc of arrFuncs) {
          console.log({ currFunc });
          await currFunc(args, '', idx);
        }

        return console.log(
          '%cUpload canceled!!! There are no files to upload.',
          css2,
        );
      }
    });

  // return firestoreURL;
};
