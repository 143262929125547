import { TbikesByLoc, Tlocations, Tmodels, Tusers } from './types';

// ---------- collections
// ----------------------
export const users: Tusers = [
  // cada objeto é um item
  {
    createdAt: 'TimeStamp',
    docId: 'CLXwpSr3gVwzUaaklzD1',

    userName: 'João P.',
    userGender: 'male',
    userImage:
      'https://images.unsplash.com/photo-1528892952291-009c663ce843?q=80&w=944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    userEmail: 'joaop10@email.com',
    userPhone: '11 90000-0000',

    userCPF: '000.000.000-00',
    userRG: '00.000.000-5',

    password: '1477839',

    userAddress: {
      coords: {
        lat: '0000000000',
        long: '000000000',
      },
      street: 'Av. Olavo Bilac',
      number: '1532',
    },

    typeAccount: 'adm',
  },
  // cada objeto é um item
  {
    createdAt: 'TimeStamp',
    docId: 'xxx2',

    userName: 'Roberto K.',
    userGender: 'male',
    userImage:
      'https://images.unsplash.com/photo-1504257432389-52343af06ae3?q=80&w=987&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    userEmail: 'robertok0@email.com',
    userPhone: '',

    userCPF: '000.000.000-00',
    userRG: '00.000.000-5',

    userAddress: {
      coords: {
        lat: '0000000000',
        long: '0000000000',
      },
      street: 'Rua das Margaridas',
      number: '36',
    },

    typeAccount: 'cli',
  },
];

export const locations: Tlocations = [
  {
    createdAt: '',
    docId: '',

    localName: 'Guidão de Ouro',
    localAddress: {
      localRef: 'Ao lado do Mercado X',
      coords: {
        lat: '0000000000',
        long: '000000000',
      },
      street: 'Av. Olavo Bilac',
      number: '1532',
    },
  },
];

export const bikeModels: Tmodels = [
  {
    createdAt: '',
    docId: 'mmm1',

    modelImage:
      'https://static3.tcdn.com.br/img/img_prod/394779/bicicleta_eletrica_29_gts_m1_freio_a_disco_7v_shimano_suspensao_e_bike_draven_190w_3385_1_86d1b90ceb3dbd4dd276a6155ee27655.jpg',
    modelName: 'MTB Alumínio Shimano 21V Aro 29',
    modelPrice: '9',
    modelCode: '9',
    amount: '0',
  },
];

export const bikesByLocation: TbikesByLoc = [
  {
    createdAt: '',
    docId: 'blc1',

    bikeLocalAvailable: '10',
    modelID: 'mmm1',
    code: 'TK123',

    // get In bikeModels - Merged
    modelName: 'Bicicleta MTB Aluminio Shimano 21V Aro 29...',
    modelImage:
      'https://static.netshoes.com.br/produtos/bicicleta-gts-fat-bike-aro-26-com-freio-a-disco-hidraulico-cambio-shimano-altus-1x8-marchas-suporta/26/ABS-0359-026/ABS-0359-026_zoom1.jpg?ts=1660659415&ims=544x',
    modelPrice: '9.84',
    amount: '0',
  },
];

export const mock: any = {
  toggles: {
    list1: [
      { label: 'opção 1', id: 'opção 1' },
      { label: 'opção 2', id: 'opção 2' },
      { label: 'opção 3', id: 'opção 3' },
      { label: 'opção 4', id: 'opção 4' },
    ],
  },
  userData: {
    ...users[0],
  },
  A2: {
    currData: {
      usersList: users,
    },
  },
  A6: {
    currData: {
      locationsList: locations,
    },
  },
  A5: {
    currData: {
      status: 'Selecione...',
      userStatus: [
        { id: '0', label: 'Pendente' },
        { id: '1', label: 'Liberado' },
        { id: '2', label: 'Bloqueado' },
      ],
    },
    lists: {
      pickerStatus: [
        { label: 'Pendente', id: 'Pendente' },
        { label: 'Liberado', id: 'Liberado' },
        { label: 'Bloqueado', id: 'Bloqueado' },
      ],
    },
  },
  A8: {
    currData: {
      bikeModels,
    },
  },
  A14: {
    lists: {
      pickerStatus: [
        { label: 'SIM', id: 'sim' },
        { label: 'NÃO', id: 'não' },
      ],
    },
  },
  B3B: {
    currData: {
      bikesList: [...bikesByLocation],
    },
  },
  B3C: {
    currData: {
      localName: 'Guidão de Ouro',
      localAddress: {
        localRef: 'Ao lado do Mercado X',
        coords: {
          lat: '0000000000',
          long: '000000000',
        },
        street: 'Av. Olavo Bilac',
        number: '1532',
      },

      totalAmount: '40',
      selectedHour: 'Selecione...',
      hoursList: [
        { id: '0', label: '30 Minutos' },
        { id: '1', label: '1 Hora' },
        { id: '2', label: '1 Hora e 30 minutos' },
        { id: '3', label: '2 Horas' },
        { id: '4', label: '2 Horas e 30 minutos' },
      ],

      bikeCheckoutList: [],
    },
    forms: {
      iptsChanges: {
        rentPeriod: '',
      },
    },
    lists: {
      pickerHours: [
        { label: '30 minutos', id: '0.30' },
        { label: '1 hora', id: '1' },
        { label: '1 hora e 30 minutos', id: '1.30' },
        { label: '2 horas', id: '2' },
      ],
    },
  },

  // B4: {
  //   currData: {
  //     orderData: initOrderData,
  //   },
  // },
};

// ------------- DEV VARs
// ----------------------
export const initOrderData = {
  customer_id: null,
  items: [
    {
      amount: null,
      description: null,
      quantity: null,
      code: null,
    },
  ],
  payments: [
    {
      payment_method: null,
      credit_card: {
        operation_type: null,
        installments: null,
        statement_descriptor: null,
      },
    },
  ],
};

// all.apiDataExamples.contact
// (pre launch)
export const devVars: any = {
  all: {
    system: {
      version: 'ver.app.0.1.5',
      adm: 'ver.adm.0.1.2',
    },
    // ----- informações do
    //------ usuário logado
    // userData: {
    //   // userName: 'Carlos',
    // },
    // userData: {
    //   paymentData: {
    //     created_at: '2024-04-18T19:59:06Z',
    //     type: 'card',
    //     card: {
    //       last_four_digits: '0010',
    //       exp_month: 3,
    //       brand: 'Visa',
    //       first_six_digits: '400000',
    //       holder_name: 'Carlos A S',
    //       exp_year: 26,
    //     },
    //     id: 'token_PpNaxBAURSV35E1o',
    //     expires_at: '2024-04-18T20:00:06Z',
    //   },
    //   userImage:
    //     'https://lh3.googleusercontent.com/a/ACg8ocKkD01rA1kHtL4FROBR81qstgV9OpzJUcD17y9jUn9LQn8=s96-c',
    //   typeAccount: 'app',
    //   customerId: 'cus_gPp89XvTgT7b1oZQ',
    //   userEmail: 'carlosvsk@gmail.com',
    //   localName: 'ss',
    //   userCPF: '115 369 666',
    //   activeRentData: {
    //     rentId: 'IVCgpfhBpaL3fOfburC3',
    //   },
    //   userRG: '113 455 666',
    //   userPhone: '11 9633 6666',
    //   locationName: 'sss',
    //   id: '83d5a695-e786-47f6-b046-b8df4eb21bdc',
    //   createdAt: {
    //     seconds: 1712350562,
    //     nanoseconds: 427000000,
    //   },
    //   userName: 'Carlos Alberto',
    //   userGender: 'Masculino',
    //   userAddress: {
    //     reference: 'ChIJC4sAH8xDlpQRdBQ2J9FPOZE',
    //     adr_address:
    //       '<span class="street-address">R. Anhanguera, 2939-2147483647</span> - <span class="extended-address">Jardim Nova Yorque</span>, <span class="locality">Araçatuba</span> - <span class="region">SP</span>, <span class="postal-code">16018-390</span>, <span class="country-name">Brazil</span>',
    //     place_id: 'ChIJC4sAH8xDlpQRdBQ2J9FPOZE',
    //     geometry: {
    //       location: {
    //         lng: -50.44518,
    //         lat: -21.2322252,
    //       },
    //       viewport: {
    //         northeast: {
    //           lat: -21.22603956504215,
    //           lng: -50.43856843415993,
    //         },
    //         southwest: {
    //           lng: -50.45149136938366,
    //           lat: -21.23836863429165,
    //         },
    //       },
    //     },
    //     formatted_address:
    //       'R. Anhanguera, 2939-2147483647 - Jardim Nova Yorque, Araçatuba - SP, 16018-390, Brazil',
    //     icon_mask_base_uri:
    //       'https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet',
    //     url: 'https://maps.google.com/?q=16018-390&ftid=0x949643cc1f008b0b:0x91394fd127361474',
    //     vicinity: 'Jardim Nova Yorque',
    //     types: ['postal_code'],
    //     utc_offset: -180,
    //     name: '16018-390',
    //     address_components: [
    //       {
    //         short_name: '16018-390',
    //         long_name: '16018-390',
    //         types: ['postal_code'],
    //       },
    //       {
    //         short_name: '2939-2147483647',
    //         types: ['street_number'],
    //         long_name: '2939-2147483647',
    //       },
    //       {
    //         short_name: 'R. Anhanguera',
    //         types: ['route'],
    //         long_name: 'Rua Anhanguera',
    //       },
    //       {
    //         short_name: 'Jardim Nova Yorque',
    //         types: ['sublocality_level_1', 'sublocality', 'political'],
    //         long_name: 'Jardim Nova Yorque',
    //       },
    //       {
    //         long_name: 'Araçatuba',
    //         short_name: 'Araçatuba',
    //         types: ['administrative_area_level_2', 'political'],
    //       },
    //       {
    //         types: ['administrative_area_level_1', 'political'],
    //         short_name: 'SP',
    //         long_name: 'São Paulo',
    //       },
    //       {
    //         types: ['country', 'political'],
    //         short_name: 'BR',
    //         long_name: 'Brazil',
    //       },
    //     ],
    //     icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png',
    //     icon_background_color: '#7B9EB0',
    //   },
    //   updatedAt: {
    //     seconds: 1713473753,
    //     nanoseconds: 193000000,
    //   },
    //   userStatus: 'Pendente',
    //   docId: 'T7jRdozodLheCQTedNpD',
    //   fullRegister: 'true',
    // },

    // ----- textos que não
    // ------- são alterados
    static: {
      teste: 'ola',

      pickers: {
        bikeStatus: [
          { id: 'available', label: 'Disponível' },
          { id: 'unavailable', label: 'Indisponível' },
        ],
      },
    },

    // ------- TEMP (remover depois)
    toggles: {
      creditCard: 'open',
    },
  },

  sc: {
    A0: {
      forms: {
        iptsChanges: {
          userCPF: '448.003.986-63',
          userPhone: '(11) 66553-3222',
          userRG: '00.000.000-01',
        },
      },
    },
    B4: {
      currData: {
        orderData: initOrderData,
      },
    },
  },
  mock,
};
