import { getVarValue } from '../project';
import { argSel } from '../project';

// F8 - ccc_uploadImgs (newBase)
type Tprops_F8 = { pass: { A: any; B: any } };
export const F8 =
  (props: Tprops_F8) =>
  async (...args: any) => {
    const { A, B } = props.pass;

    console.log({ A });
    console.log({ B });
    // ---------- set Path Array with Arguments + Variables
    const iterator = A.map((item: any) => {
      // ---------- set Arguments Values (If Exists)
      const checkArgs = item.startsWith('#');
      if (checkArgs) item = argSel(args, item);

      // ---------- set Variables Values (If Exists)
      const [condVar, varValue] = getVarValue(item, 'noComponent');
      if (condVar) item = varValue;

      // ---------- set return
      return item;
    });

    // ---------- set Url Value as a single string
    // let varPath = newArrPath.reduce((prev: any, curr: any) => prev + curr, '');
    // console.log({ varPath });
    // const iterator = setArgsVars(varPath, args);
    console.log({ iterator });

    // -------------- Major Loop
    for (const currItem of iterator[0]) {
      // Arrumar
      console.log(currItem);
      for (const currFunc of B) await currFunc(currItem, args);
    }
  };
