import { getCtData } from '../project';

const css =
  'color: purple; background-color: black; font-size: 11px; padding: 2px 6px; border-radius: 3px';

type Tprops_condition = {
  args: any;
  pass: {
    conditionPath: any;
    expectedValue: any;
    functionsTrue: any;
    functionsFalse: any;
  };
};
export const condition = async (props: Tprops_condition) => {
  // ---------- set Caps Inputs
  const { args, pass } = props;
  const { conditionPath, expectedValue, functionsTrue, functionsFalse } = pass;

  // ---------- set VarPath and expectedVal as a single string
  const varPath = conditionPath.join();
  const newVal = expectedValue.join();
  console.log({ conditionPath });
  console.log({ expectedValue });

  // ---------- set Conditional Test
  let conditionValue = false;
  if (conditionPath.length > 0) {
    conditionValue = varPath === newVal;
  }
  console.log('%cCondition', css);
  // console.log(`${varPath} === ${newVal}`);
  console.log({ varPath }, '===', { newVal });
  console.log({ conditionValue });

  // console.log({ conditionPath });
  // console.log({ varPath });

  // console.log({ expectedValue });
  // console.log({ newVal });

  if (conditionValue) {
    for (const currFunc of functionsTrue) await currFunc(args);
  }
  if (!conditionValue) {
    for (const currFunc of functionsFalse) await currFunc(args);
  }
};

export const togglFuncs = async (props: Tprops_condition) => {
  // ---------- set Caps Inputs
  const { args, pass } = props;
  const { conditionPath, expectedValue, functionsTrue, functionsFalse } = pass;

  console.log('toggl funcs', conditionPath, expectedValue);
  // const cond1 = conditionPath.join();
  // const cond2 = !!expectedValue.join();

  const arrMultipleCond = [];
  let condExcFuncs;
  expectedValue.map(
    (cond: () => { field: string; operator: string; value: string }) => {
      const activeCaps = cond();

      const field = activeCaps.field;
      const operator = activeCaps.operator;
      const value = activeCaps.value;

      if (operator === 'falsy') {
        console.log({ arrMultipleCond });
        field === 'undefined' && arrMultipleCond.push(false); // where conds retorna 'undefined' se não ler nada (inicial)
        field !== 'undefined' && arrMultipleCond.push(!!field); // pq leu algum valor
      }
      if (operator === '===') arrMultipleCond.push(field === value);
      if (operator === '!==') arrMultipleCond.push(field !== value);
      if (operator === '||') {
        const splitedVals = value?.split(',') ?? [];
        console.log({ splitedVals });

        const orVals = splitedVals?.map(e => e?.trim() === field);
        arrMultipleCond.push(orVals.some(a => a));
      }
      // if (cond[1] === '>=') cond[0] >= cond[2];
      // if (cond[1] === '<=') cond[0] <= cond[2];
    },
  );
  console.log({ arrMultipleCond });
  condExcFuncs = arrMultipleCond.every(i => i); // testa se todos os valores passam (all true)
  console.log({ condExcFuncs });

  if (condExcFuncs) {
    console.log('FUNCS TRUE');
    for (const currFunc of functionsTrue) await currFunc(args);
  }
  if (!condExcFuncs) {
    console.log('FUNCS FALSE');
    for (const currFunc of functionsFalse) await currFunc(args);
  }
};
