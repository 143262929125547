import React, { useEffect } from 'react';
const srcPin = require('../../../../assets/pin.png');

import { useData } from '../../..';
import { getCtData, pathSel, setData } from '../../project';

type Tprops = {
  pass: {
    keyPlaces: string;
    pathInitMap: string[];
    arrPins: string[];
    arrFuncs: any[];
  };
  args?: any;
};

export const WebMap = (props: Tprops) => {
  // ---------- set Props
  const { args } = props;
  const { keyPlaces, pathInitMap, arrPins, arrFuncs } = props.pass;
  const strPathPins = arrPins.join();
  const strPathInit = pathInitMap.join();

  let condLat;
  let condLng;
  let condZoom;

  // ---------- set Watch Data
  // sc.B3A.currData.mainList
  //  --- ou ---
  // sc.B5.currData.mainList
  const dataPins = useData(ct => pathSel(ct, strPathPins)) ?? [];

  // sc.B3A.currData.currUserLocation
  //  --- ou ---
  // sc.B5.currData.initialPoint
  const initPoint = useData(ct => pathSel(ct, strPathInit));
  const newLocation = useData(ct =>
    pathSel(ct, 'sc.A7.forms.iptsChanges.localAddress.geometry.location'),
  );
  const changes = useData(ct => pathSel(ct, 'sc.A7.forms.iptsChanges'));

  // ---- When Get User Coords (B3A Curr User Coords / B5 Curr Location Rent)
  if (initPoint?.coords) {
    const { latitude, longitude } = initPoint?.coords;
    condLat = latitude;
    console.log('dentro 1', { initPoint });
    console.log('dentro 1', { condLat });
    condLng = longitude;
  }

  // ---- When Receive Location Coords (A7 Custom Coords / Drag and Drop)
  if (initPoint?.lat) {
    const { lat, lng, zoom } = initPoint;
    condLat = lat;
    condLng = lng;
    condZoom = changes?.zoom ?? zoom;
  }

  // ---------- set Prepare Data Pins
  const coordenadas = dataPins.filter(i => {
    const itemCoords = i?.localAddress?.geometry?.location;
    const condReturn = typeof itemCoords === 'object';

    return condReturn && i;
  });

  console.log('PINS', { coordenadas });

  const objInitPoint = {
    center: {
      lat: Number(condLat ?? -23.5505199),
      lng: Number(condLng ?? -46.63330939999999),
    },
    zoom: condZoom ?? 12,
    streetViewControl: false,
    mapTypeControl: false,
  };

  const fnSetMap = () => {
    console.log({ initPoint });

    const checkLat = typeof objInitPoint?.center?.lat === 'number';
    const checkLng = typeof objInitPoint?.center?.lng === 'number';
    const checkCoordsFilled = checkLat && checkLng;
    checkCoordsFilled && console.log({ objInitPoint });

    const mapHtmlEl = document.getElementById('googleMap');
    const map = new window.google.maps.Map(mapHtmlEl, objInitPoint);

    if (checkCoordsFilled) {
      // -------------------------------------------
      // -------------- Marcador Drag And Drop (ADM)
      // -------------------------------------------
      if (newLocation) {
        const newLatLng = new window.google.maps.LatLng(
          newLocation.lat,
          newLocation.lng,
        );
        map.panTo(newLatLng);
        const lat = Number(newLocation.lat);
        const lng = Number(newLocation.lng);

        const position = { lat, lng };
        const marker = new window.google.maps.Marker({
          position: position,
          map: map,
          draggable: true,
          icon: {
            url: srcPin,
            scaledSize: new google.maps.Size(38, 44),
          },
        });
        marker.addListener('dragend', () => {
          const position = marker.getPosition();
          const currZoom = marker?.map?.zoom;
          console.log({ currZoom });
          const lat = position.lat();
          const lng = position.lng();

          let values = { ...changes, zoom: currZoom };
          values.localAddress.geometry.location = { lat, lng };

          setData({
            path: 'sc.A7.forms.iptsChanges',
            value: values,
          });
        });
      }

      // --------------------------------
      // ------------ Marcadores "fixos"
      // --------------------------------
      if (coordenadas && map) {
        // ------------ Loop Map Pins
        coordenadas.forEach(item => {
          const currPoint = item?.localAddress?.geometry?.location;
          const lat = Number(currPoint.lat);
          const lng = Number(currPoint.lng);
          const position = { lat, lng };

          const marker = new window.google.maps.Marker({
            position,
            map,
            icon: {
              url: srcPin,
              scaledSize: new google.maps.Size(28, 34),
            },
          });

          // ------------ Set Loop Func
          // const activeRent = getCtData('sc.B5.currData.timer.endTimeStamp'); // Checar se Existe Carimbo de Finalização
          // console.log({ activeRent });

          marker.addListener('click', async () => {
            for (const currFunc of arrFuncs) await currFunc(item, args);
          });
        });
      }
    }
  };

  useEffect(() => {
    // ---------- set Script Call Google Api
    const loadSc = document.getElementById('mf-map-bk');
    console.log('fora', { loadSc, dataPins });

    // if (loadSc === null || dataPins.length === 0) {
    console.log({ loadSc, dataPins });
    const script = document.createElement('script');
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${keyPlaces}`;
    script.id = 'mf-map-bk';
    document.head.appendChild(script);

    // ---------- set Script Config Google Api
    script.onload = fnSetMap;
    // }
    // }, [dataPins]);
  }, [objInitPoint, dataPins]);

  return <div id="googleMap" style={{ width: '100%', height: '100%' }} />;
};
