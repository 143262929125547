// ---------- import React Packs
import { doc } from 'firebase/firestore';

// ---------- import Internals
import { goTo, setData } from '../../../project';
import { updateUser } from './updateUser';
import { addNewUser } from './addNewUser';
import { getDbInfo } from './getDbInfo';

// -----------------------------
// --------- Login WEB (Browser)
// -----------------------------
export const waitForUserData = async (userLogin: any) => {
  const userEmail = userLogin?.userData?.email;
  const userImage = userLogin?.userData?.image;
  const userName = userLogin?.userData?.name;

  // ---------- set Collection Reference
  const userDbInfo: any = { userName, userImage, userEmail };
  const dbInfo = await getDbInfo({ userEmail, userName, userImage });
  const { refColl, condNewUser, arrUser } = dbInfo;
  console.log({ condNewUser });
  console.log({ arrUser });

  const refDoc = doc(refColl);
  // ---------- set New User
  if (condNewUser) {
    (userDbInfo.userStatus = 'Pendente'),
      (userDbInfo.fullRegister = 'false'),
      await addNewUser({ userDbInfo, refDoc });
  }

  // ---------- set Update User
  if (!condNewUser) await updateUser({ arrUser, refColl, userDbInfo });

  // ------ return SUCCESS
  const dataSuccess = condNewUser ? userDbInfo : arrUser[0];
  const successUserName = dataSuccess?.userName;
  console.log({ dataSuccess });

  setData({ path: 'all.userData', value: dataSuccess });
  setData({ path: 'sc.B2.forms.editData.userName', value: successUserName });

  const condUrl = dataSuccess?.fullRegister === 'true';
  const condRedirect2 = condUrl ? 'b3bikemap' : 'b2register';

  goTo(condRedirect2);
};

// ---------- for Native Login (optional)
// export const waitForUserData = async (id: string) => {
//   console.log('init waitForUserData');
//   const userLogin = await getUserData(id);
//   console.log({ userLogin });
//   const userEmail = userLogin?.userData?.email;
//   const userImage = userLogin?.userData?.image;
//   const userName = userLogin?.userData?.name;

//   if (!userEmail) {
//     const func1 = () => waitForUserData(id);
//     setTimeout(func1, 1400);
//   } else {
//     const userDbInfo: any = {
//       id,

//       userName,
//       userImage,
//       userEmail,
//     };
//     console.log({ userDbInfo });

//     // ---------- set Collection Reference
//     console.log('antes do getdbInfo');
//     const dbInfo = await getDbInfo({ userEmail, userName, userImage });
//     console.log({ dbInfo });
//     const { refColl, condNewUser, arrUser } = dbInfo;

//     const refDoc = doc(refColl);
//     // ---------- set New User
//     if (condNewUser) {
//       (userDbInfo.userStatus = 'Pendente'),
//         (userDbInfo.fullRegister = 'false'),
//         await addNewUser({ userDbInfo, refDoc });
//     }

//     // ---------- set Update User
//     if (!condNewUser) await updateUser({ arrUser, refColl, userDbInfo });

//     // ------ return SUCCESS
//     const dataSuccess = condNewUser ? userDbInfo : arrUser[0];
//     console.log({ condNewUser });
//     console.log({ dataSuccess });
//     setData({ path: 'all.userData', value: dataSuccess });
//     setData({
//       path: 'sc.B2.forms.editData.userName',
//       value: dataSuccess.userName,
//     });

//     // const userData = getCtData('sc.all.userData');
//     // console.log({ userData });
//     // const condFullRegister = getCtData('sc.all.userData.fullRegister');
//     console.log({ fullRegister: dataSuccess.fullRegister });

//     const condUrl = dataSuccess.fullRegister === 'true';
//     console.log({ condUrl });
//     const condRedirect2 = condUrl ? 'b3bikemap' : 'b2register';

//     goTo(condRedirect2);
//   }
// };
//
//
