export const multiplyUnits = arrPricesCalc => {
  const newArr = [];
  // let objPricesCalc = {};
  console.log({ arrPricesCalc });

  arrPricesCalc.map((item, idx) => {
    console.log({ item });
    const val1 = Number(item?.modelAmount);
    const val2 = Number(item?.modelPrice.replace(/[^0-9]/g, ''));
    const currCalc = val1 * val2; // bike uni. * price p. hour

    newArr.push(currCalc);
  });

  return newArr;
};
