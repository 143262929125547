import { errFnc } from '.';
import { baseUrl, secrets } from '../../../globalVars/data';
import { updateDoc } from '../../functions/firebase/updateDoc';
import { getCtData, setData } from '../../project';
import { asyncFn } from './asyncFn';
import { getAddress } from './helpers/getAddress';
import { errorPathShow, errorPathTxt, txtErrorDefault1 } from './helpers/paths';
import { setCtDataError, setErrorTrue } from './helpers/setCtDataError';

type Tprops = {
  args?: any;
  pass?: {
    ipt1?: any[];
    ipt2?: string;
    ipt3?: any[];
  };
};

export const createCustomer = async (props?: Tprops) => {
  console.log('Create Customer...');
  const userData = getCtData('all.userData');
  const condMale = userData?.userGender === 'Masculino';
  const condGender = condMale ? 'male' : 'female';

  const arrayAd = userData?.userAddress?.address_components;
  const prefix = 'administrative_area_level_';
  const formatedZip = getAddress(arrayAd, 'postal_code').replace(',', '');
  const condZip = formatedZip === '' ? '00000-000' : formatedZip;

  // ---------- Get CtData
  const processedData = {
    name: userData?.userName.replace(/[^\w\s]/g, ''),
    email: userData?.userEmail,
    code: userData?.docId,

    type: 'individual',
    document_type: 'CPF',
    document: userData?.userCPF.replace(/[^0-9]/g, ''), // get only numbers

    gender: condGender,

    address: {
      line_1: getAddress(arrayAd, 'route'),
      // line_2: '8º andar',
      zip_code: condZip,
      city: getAddress(arrayAd, prefix + 2),
      state: getAddress(arrayAd, prefix + 1),
      country: getAddress(arrayAd, 'country'),
    },

    phones: {
      home_phone: {
        country_code: '55',
        area_code: '11',
        // number: userData?.userPhone,
        number: '000000000',
      },
    },
    metadata: {
      company: 'Aluguel de Bikes',
    },
  };
  console.log('CUSTOMER - dataToSet', { processedData });

  // ---------- EndPoint
  const url = baseUrl + '/customers';

  // ---------- Headers
  const myHeaders = new Headers();
  myHeaders.append('accept', 'application/json');
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Basic ${secrets.sk}`);

  // ---------- Body
  const raw = JSON.stringify(processedData); // ctData REAL DATA
  console.log('CUSTOMER - dataToSet RAW', raw);
  // const raw = JSON.stringify(templateOrder); // Template

  // ---------- Request Config
  const reqConfig = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  // ---------------------------
  // ----------- SET CALL API --
  // ---------------------------
  console.log('antes do fetch');
  const callRequest = await asyncFn(url, reqConfig).catch(errFnc);
  console.log({ promise_RES: callRequest });

  // if (!callRequest) return console.log({ promise_error: callRequest });

  // ----- check IF Stop Process
  // const res = setCtDataError(callRequest);
  const res = await callRequest.json();
  const condStop = setCtDataError(res, callRequest.status);
  console.log('Customer Log Cond', condStop);
  if (condStop !== 'follow') return condStop;

  // if (status === 200) {
  //   const res = await callRequest.json();
  //   console.log('DEU 200', { res });
  //   const errLayer1 = res?.charges?.errors;
  //   const errLayer2 = res?.message;

  //   if (errLayer1) {
  //     // ------------------------------
  //     // --------- UPDATE CT DATA ERROR
  //     // ------------------------------
  //     const statusText = '';
  //     const resErr = statusText ? 'Error ' + status + ': ' + statusText : false;
  //     setData({ path: errorPathTxt, value: resErr ?? txtErrorDefault1 });
  //     setData({ path: errorPathShow, value: 'true' });
  //   }

  //   if (errLayer2) {
  //     // ------------------------------
  //     // --------- UPDATE CT DATA ERROR
  //     // ------------------------------
  //     const statusText = errLayer2;
  //     const resErr = statusText ? 'Error: ' + statusText : false;
  //     setData({ path: errorPathTxt, value: resErr ?? txtErrorDefault1 });
  //     setData({ path: errorPathShow, value: 'true' });
  //   }

  //   console.log('Depois do Fetch - createCUSTOMER', res);
  //   // const condError = setCtDataError(callRequest);
  //   // if (condError) return;

  //   // const res = await callRequest.json();

  setData({ path: 'all.tempData', value: { customerId: res.id } });

  //   console.log('Depois do Fetch - createCUSTOMER', callRequest, res);
  // }

  // ----------------------------------------
  // ----------- SET UPDATE FIRESTORE USER --
  // ----------------------------------------
  const selectedUser = getCtData('all.userData.docId');
  const fireInit = getCtData('all.fireInit');
  const passProps = {
    fbInit: [fireInit],
    arrRefStrings: ['users', selectedUser],
    arrPathData: ['all.tempData'],
    arrFuncs: [() => {}],
  };

  await updateDoc({ args: {}, pass: passProps });

  // clean temp data
  setData({ path: 'all.tempData', value: null });

  return 'follow';
};

//   {
//     "name": "Carlos",
//     "email": "teste@teste.com" ,

//     "type": "individual",
//     "document_type": "CPF",
//     "document": "00000000000",

//     "birthdate": "05/03/1984",
//     "phones": {
//         "home_phone": {
//             "country_code": "55",
//             "area_code": "21",
//             "number": "000000000"
//         },
//         "mobile_phone": {
//             "country_code": "55",
//             "area_code": "21",
//             "number": "000000000"
//         }
//     },
//     "metadata": {
//         "company": "Aluguel de Bikes"
//     }
// }
