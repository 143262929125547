import { ViewStyle } from 'react-native';
import { useEffect, useState } from 'react';
import { Picker } from '@react-native-picker/picker';

import { pathSel, setData } from '../../project';
import { useData } from '../../..';
import { setVar } from '../../functions';

type Tprops = {
  pass: {
    pathList: any[];
    pathEdit: any[];
    arrFuncs: any[];
    pathReturn: any[];
    args: any;
  };
};

export const IptPicker = (props: Tprops) => {
  let { pathList, pathEdit, arrFuncs, pathReturn, args } = props.pass;

  const fixCacaNaA3 = pathEdit.join().includes('A3');

  if (fixCacaNaA3) pathEdit = ['sc.A3.forms.editData.locationId'];

  const [sttSelected, setSelected] = useState('');
  const dataList = useData(ct => pathSel(ct, pathList.join()));
  const newEditData = useData(ct => pathSel(ct, pathEdit.join()));
  console.log({ pathEdit });
  console.log({ newEditData });

  // ---------- set Render Item
  const renderItem = () => {
    const checkData = dataList && dataList.length > 0;

    if (checkData) {
      const containFirstOpt = dataList.find(
        (e: any) => e.label === 'Selecione...',
      );
      !containFirstOpt && dataList.unshift({ label: 'Selecione...', id: '0' });

      return dataList.map((item: any, idx: number) => (
        <Picker.Item key={idx} label={item.label} value={item.id} />
      ));
    }

    return (
      <>
        <Picker.Item
          label={'Nenhum item encontrado'}
          value={'0'}
          style={{ color: '#ff0000' }}
        />
      </>
    );
  };

  // ---------- set Func Fx
  useEffect(() => {
    if (newEditData && newEditData.length > 0) {
      if (newEditData !== '') setSelected(newEditData);
      if (newEditData === '') setSelected('1');
    } else {
      setSelected('0');
    }
  }, [newEditData]);

  // ---------- set Func On Change
  const funOnChange = (val: string) => {
    // console.log('1', { args, val });
    setSelected(val);
    // setVar({ pass: { keyPath: pathReturn, value: val }, args });
    // console.log({ arrFuncs });
    for (const currFunc of arrFuncs) currFunc(args, val, sttSelected);
  };

  console.log('2', { sttSelected });
  return (
    <>
      <Picker
        style={stlPicker}
        selectedValue={sttSelected}
        onValueChange={funOnChange}
      >
        {renderItem()}
      </Picker>
    </>
  );
};

const stlPicker: any = {
  color: '#444',
  backgroundColor: 'transparent',
  borderWidth: 0,
};
