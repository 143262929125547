// F3 - new callback (newBase)
type Tprops_F3 = { pass: { functions: any; B: any; C: any } };
export const F3 =
  (props: Tprops_F3) =>
  async (...args: any) => {
    const { functions } = props.pass;

    // ---------- set Capsules Inputs
    // const arrFuncs = [!#!functions!#!];
    // ---------- set Get Value Functions
    for (const currFunc of functions) {
      // console.log({ args });
      await currFunc(args[0]);
    }
  };
