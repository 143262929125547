// file: A1_Login.tsx

// ---------- import Local Tools
import { mapElements } from '../tools/project/mapElements';
import * as functions from '../tools/functions';
import * as Elements from '../tools/Elements';
import * as customs from '../tools/customs';
import { Project } from '../tools/project/';
import * as jsvals from '../tools/jsvals';
import { props } from '../tools/props';
import * as stls from '../tools/stls';
import { tools } from '../tools';

// ---------- set Temp Dev DataBase
import * as devDB from '../devDB';
import { mock } from '../devDB';

export const screen = (...args: any) => (
  <Elements.Screen3
    pass={{
      pathScreen: 'a1login',

      styles: [
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.flex({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '1px',
                },
              }),
            ],
          },
        }),

        stls.alignItems({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.justifyContent({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.ice',
                    },
                  }),
                },
              }),
            ],
          },
        }),
      ],

      screenElements: [
        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.alignItems({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'center',
                        },
                      }),
                    ],
                  },
                }),
                stls.justifyContent({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'center',
                        },
                      }),
                    ],
                  },
                }),
                stls.width({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '300px',
                        },
                      }),
                    ],
                  },
                }),
                stls.padding({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '15px',
                        },
                      }),
                    ],
                  },
                }),
                stls.backgroundColor({
                  pass: {
                    arrayValue: [
                      jsvals.varReader({
                        pass: {
                          path: jsvals.j8({
                            pass: {
                              propertieValues: 'all.colors.white',
                            },
                          }),
                        },
                      }),
                    ],
                  },
                }),
                stls.borderRadius({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '18px',
                        },
                      }),
                    ],
                  },
                }),
                stls.shadows({
                  pass: {
                    shadowColor: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '#000000',
                        },
                      }),
                    ],
                    shadowOffset: ['{width: 0, height: 0}'],
                    shadowOpacity: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '0.2',
                        },
                      }),
                    ],
                    shadowRadius: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '10px',
                        },
                      }),
                    ],
                  },
                }),
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [
                (...args: any) => (
                  <Elements.ImageBox
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '150px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '180px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      URIvariablePath: [
                        jsvals.j8({
                          pass: {
                            propertieValues:
                              'https://firebasestorage.googleapis.com/v0/b/goodbike-cd338.appspot.com/o/images%2Flogo_vertical.png?alt=media&token=19d722aa-d757-4ba6-b37f-9d7527468d47',
                          },
                        }),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [
                                stls.color({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.deepPurple',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.fontSize({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 12,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              children: [
                                jsvals.varReader({
                                  pass: {
                                    path: jsvals.j8({
                                      pass: {
                                        propertieValues: 'sc.A1.login',
                                      },
                                    }),
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),
                        (...args: any) => (
                          <Elements.IptTxt2
                            pass={{
                              propsArray: [{}],

                              stylesArray: [
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '2px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.grey',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              funcsArray: [
                                async (...args) =>
                                  functions.setVar({
                                    args,
                                    pass: {
                                      keyPath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'sc.A1.forms.iptsChanges.login',
                                          },
                                        }),
                                      ],
                                      value: jsvals.argReader({
                                        pass: {
                                          argPath: jsvals.j8({
                                            pass: {
                                              propertieValues: '#0',
                                            },
                                          }),
                                          args,
                                        },
                                      }),
                                    },
                                  }),
                              ],

                              args,
                            }}
                          />
                        ),

                        (...args: any) => (
                          <Elements.DynView
                            pass={{
                              elementsProperties: [{}],

                              styles: [
                                stls.height({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '10px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.width({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '100%',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              variablePath: [],

                              expectedVal: [],

                              childrenItems: [() => <></>],

                              args,
                            }}
                          />
                        ),
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [
                                stls.color({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.deepPurple',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.fontSize({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 12,
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              children: [
                                jsvals.varReader({
                                  pass: {
                                    path: jsvals.j8({
                                      pass: {
                                        propertieValues: 'sc.A1.password',
                                      },
                                    }),
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),
                        (...args: any) => (
                          <Elements.IptTxtPassword
                            pass={{
                              propsArray: [{}],

                              stylesArray: [
                                stls.borderBottomWidth({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '2px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderBottomColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.grey',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              editPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues:
                                      'sc.A1.forms.iptsChanges.password',
                                  },
                                }),
                              ],

                              funcsArray: [
                                async (...args) =>
                                  functions.setVar({
                                    args,
                                    pass: {
                                      keyPath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              'sc.A1.forms.iptsChanges.password',
                                          },
                                        }),
                                      ],
                                      value: jsvals.argReader({
                                        pass: {
                                          argPath: jsvals.j8({
                                            pass: {
                                              propertieValues: '#0',
                                            },
                                          }),
                                          args,
                                        },
                                      }),
                                    },
                                  }),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.Text
                    pass={{
                      arrProps: [{}],

                      arrStyles: [{ color: 'black', fontSize: 12 }],

                      children: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'sc.A1.loginUsing',
                              },
                            }),
                          },
                        }),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.margin({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '4px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.A1.toggles.msgError',
                          },
                        }),
                      ],

                      expectedVal: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'Email ou senha incorretos.',
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [
                                stls.color({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: 'red',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                              ],

                              children: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues:
                                      'Email ou senha incorretos.',
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.Pressable3
                    pass={{
                      elementProperties: [{}],

                      styles: [
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '90px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '25px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.borderRadius({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '12px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.alignItems({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'center',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.primary',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.justifyContent({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'center',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [
                                stls.color({ pass: { arrayValue: ['white'] } }),
                              ],

                              children: [
                                jsvals.varReader({
                                  pass: {
                                    path: jsvals.j8({
                                      pass: {
                                        propertieValues: 'sc.A1.confirm',
                                      },
                                    }),
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      pressableFunctions: [
                        async (...args) =>
                          functions.firebase.where({
                            args,
                            pass: {
                              fbInit: [
                                jsvals.varReader({
                                  pass: {
                                    path: jsvals.j8({
                                      pass: {
                                        propertieValues: 'all.fireInit',
                                      },
                                    }),
                                  },
                                }),
                              ],
                              arrRefStrings: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'permissions',
                                  },
                                }),
                              ],
                              arrWhere: [
                                (...args) =>
                                  functions.firebase.whereConds({
                                    args,
                                    pass: {
                                      arrStrings: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'userEmail',
                                          },
                                        }),
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: '==',
                                          },
                                        }),
                                        jsvals.varReader({
                                          pass: {
                                            path: jsvals.j8({
                                              pass: {
                                                propertieValues:
                                                  'sc.A1.forms.iptsChanges.login',
                                              },
                                            }),
                                          },
                                        }),
                                      ],
                                    },
                                  }),
                              ],
                              arrFuncs: [
                                async (...args) =>
                                  functions.setVar({
                                    args,
                                    pass: {
                                      keyPath: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'all.userData',
                                          },
                                        }),
                                      ],
                                      value: jsvals.argReader({
                                        pass: {
                                          argPath: jsvals.j8({
                                            pass: {
                                              propertieValues: '#0.0',
                                            },
                                          }),
                                          args,
                                        },
                                      }),
                                    },
                                  }),
                                async (...args) =>
                                  functions.condition({
                                    args,
                                    pass: {
                                      conditionPath: [
                                        jsvals.varReader({
                                          pass: {
                                            path: jsvals.j8({
                                              pass: {
                                                propertieValues:
                                                  'all.userData.userPassword',
                                              },
                                            }),
                                          },
                                        }),
                                      ],
                                      expectedValue: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: '',
                                          },
                                        }),
                                      ],
                                      functionsTrue: [
                                        async (...args) =>
                                          functions.setVar({
                                            args,
                                            pass: {
                                              keyPath: [
                                                jsvals.j8({
                                                  pass: {
                                                    propertieValues:
                                                      'sc.A1.toggles.msgError',
                                                  },
                                                }),
                                              ],
                                              value: jsvals.j8({
                                                pass: {
                                                  propertieValues:
                                                    'Email ou senha incorretos.',
                                                },
                                              }),
                                            },
                                          }),
                                      ],
                                      functionsFalse: [
                                        async (...args) =>
                                          functions.condition({
                                            args,
                                            pass: {
                                              conditionPath: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'all.userData.userPassword',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              expectedValue: [
                                                jsvals.varReader({
                                                  pass: {
                                                    path: jsvals.j8({
                                                      pass: {
                                                        propertieValues:
                                                          'sc.A1.forms.iptsChanges.password',
                                                      },
                                                    }),
                                                  },
                                                }),
                                              ],
                                              functionsTrue: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A1.toggles.msgError',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues: '',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A6.currData.currLocationName',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0.0.locationName',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A6.currData.currLocationName',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0.0.locationId',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.condition({
                                                    args,
                                                    pass: {
                                                      conditionPath: [
                                                        jsvals.varReader({
                                                          pass: {
                                                            path: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  'all.userData.typeAccount',
                                                              },
                                                            }),
                                                          },
                                                        }),
                                                      ],
                                                      expectedValue: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'adm',
                                                          },
                                                        }),
                                                      ],
                                                      functionsTrue: [
                                                        (...args) => {
                                                          // ---------- get Function from A_Project Scope
                                                          return tools.goTo(
                                                            'a2permissions',
                                                          );
                                                        },
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A1.forms.iptsChanges.login',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A1.forms.iptsChanges.password',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                      functionsFalse: [
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A6.currData.currId',
                                                                  },
                                                                }),
                                                              ],
                                                              value:
                                                                jsvals.argReader(
                                                                  {
                                                                    pass: {
                                                                      argPath:
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '#0.0.0.0.item.docId',
                                                                            },
                                                                          },
                                                                        ),
                                                                      args,
                                                                    },
                                                                  },
                                                                ),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A6.currData.currLocationName',
                                                                  },
                                                                }),
                                                              ],
                                                              value:
                                                                jsvals.argReader(
                                                                  {
                                                                    pass: {
                                                                      argPath:
                                                                        jsvals.j8(
                                                                          {
                                                                            pass: {
                                                                              propertieValues:
                                                                                '#0.0.0.0.item.locationName',
                                                                            },
                                                                          },
                                                                        ),
                                                                      args,
                                                                    },
                                                                  },
                                                                ),
                                                            },
                                                          }),

                                                        (...args) => {
                                                          // ---------- get Function from A_Project Scope
                                                          return tools.goTo(
                                                            'a13rents',
                                                          );
                                                        },
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A1.forms.iptsChanges.login',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.setVar({
                                                            args,
                                                            pass: {
                                                              keyPath: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'sc.A1.forms.iptsChanges.password',
                                                                  },
                                                                }),
                                                              ],
                                                              value: jsvals.j8({
                                                                pass: {
                                                                  propertieValues:
                                                                    '',
                                                                },
                                                              }),
                                                            },
                                                          }),
                                                      ],
                                                      args,
                                                    },
                                                  }),
                                              ],
                                              functionsFalse: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.A1.toggles.msgError',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.j8({
                                                        pass: {
                                                          propertieValues:
                                                            'Email ou senha incorretos.',
                                                        },
                                                      }),
                                                    },
                                                  }),
                                              ],
                                              args,
                                            },
                                          }),
                                      ],
                                      args,
                                    },
                                  }),
                              ],
                            },
                          }),
                      ],

                      args,
                    }}
                  />
                ),
              ],

              args,
            }}
          />
        ),

        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.height({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '10px',
                        },
                      }),
                    ],
                  },
                }),
                stls.width({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '100%',
                        },
                      }),
                    ],
                  },
                }),
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [() => <></>],

              args,
            }}
          />
        ),
        (...args: any) => (
          <Elements.Text
            pass={{
              arrProps: [{}],

              arrStyles: [
                stls.color({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '#d7d7d7',
                        },
                      }),
                    ],
                  },
                }),
              ],

              children: [
                jsvals.varReader({
                  pass: {
                    path: jsvals.j8({
                      pass: {
                        propertieValues: 'all.system.adm',
                      },
                    }),
                  },
                }),
              ],

              args,
            }}
          />
        ),
      ],

      startFunctions: [() => {}],

      args,
    }}
  />
);
