// file: A0_Escolha.tsx

// ---------- import Local Tools
import { mapElements } from '../tools/project/mapElements';
import * as functions from '../tools/functions';
import * as Elements from '../tools/Elements';
import * as customs from '../tools/customs';
import { Project } from '../tools/project/';
import * as jsvals from '../tools/jsvals';
import { props } from '../tools/props';
import * as stls from '../tools/stls';
import { tools } from '../tools';

// ---------- set Temp Dev DataBase
import * as devDB from '../devDB';
import { mock } from '../devDB';

export const screen = (...args: any) => (
  <Elements.Screen3
    pass={{
      pathScreen: 'ab0login',

      styles: [
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.flex({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '1px',
                },
              }),
            ],
          },
        }),

        stls.alignItems({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.justifyContent({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.deepPurple',
                    },
                  }),
                },
              }),
            ],
          },
        }),
      ],

      screenElements: [
        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.alignItems({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'center',
                        },
                      }),
                    ],
                  },
                }),
                stls.justifyContent({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'center',
                        },
                      }),
                    ],
                  },
                }),

                stls.width({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '300px',
                        },
                      }),
                    ],
                  },
                }),
                stls.height({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '310px',
                        },
                      }),
                    ],
                  },
                }),
                stls.padding({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '15px',
                        },
                      }),
                    ],
                  },
                }),
                stls.backgroundColor({
                  pass: {
                    arrayValue: [
                      jsvals.varReader({
                        pass: {
                          path: jsvals.j8({
                            pass: {
                              propertieValues: 'all.colors.white',
                            },
                          }),
                        },
                      }),
                    ],
                  },
                }),
                stls.borderRadius({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '18px',
                        },
                      }),
                    ],
                  },
                }),
                stls.shadows({
                  pass: {
                    shadowColor: ['#000'],
                    shadowOffset: ['{width: 0, height: 0}'],
                    shadowOpacity: ['0.4px'],
                    shadowRadius: ['20px'],
                  },
                }),
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [
                (...args: any) => (
                  <Elements.Pressable3
                    pass={{
                      elementProperties: [{}],

                      styles: [
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.deepPurple',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.deepPurple',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.paddingHorizontal({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '32px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.paddingVertical({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '8px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.borderBottomLeftRadius({
                          pass: { arrayValue: ['10px'] },
                        }),
                        stls.borderRadius({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '20px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [
                                stls.color({ pass: { arrayValue: ['white'] } }),
                              ],

                              children: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'A1 Login ADM',
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      pressableFunctions: [
                        (...args) => {
                          // ---------- get Function from A_Project Scope
                          return tools.goTo('a1login');
                        },
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.Pressable3
                    pass={{
                      elementProperties: [{}],

                      styles: [
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.deepPurple',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.backgroundColor({
                          pass: {
                            arrayValue: [
                              jsvals.varReader({
                                pass: {
                                  path: jsvals.j8({
                                    pass: {
                                      propertieValues: 'all.colors.deepPurple',
                                    },
                                  }),
                                },
                              }),
                            ],
                          },
                        }),
                        stls.paddingHorizontal({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '32px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.paddingVertical({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '8px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.borderBottomLeftRadius({
                          pass: { arrayValue: ['10px'] },
                        }),
                        stls.borderRadius({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '20px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Text
                            pass={{
                              arrProps: [{}],

                              arrStyles: [
                                stls.color({ pass: { arrayValue: ['white'] } }),
                              ],

                              children: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'B1 Login APP',
                                  },
                                }),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      pressableFunctions: [
                        (...args) => {
                          // ---------- get Function from A_Project Scope
                          return tools.goTo('b1login');
                        },
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.IptTxtMaskCPF
                    pass={{
                      propsArray: [{}],

                      stylesArray: [
                        {
                          color: 'black',
                          fontSize: 14,
                          // fontSize: '20px',<= #ATTENTION: Native ERROR! No string!
                        },
                      ],

                      editPath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.A0.forms.iptsChanges.userCPF',
                          },
                        }),
                      ],

                      funcsArray: [
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues:
                                      'sc.A0.forms.iptsChanges.userCPF',
                                  },
                                }),
                              ],
                              value: jsvals.argReader({
                                pass: {
                                  argPath: jsvals.j8({
                                    pass: {
                                      propertieValues: '#0',
                                    },
                                  }),
                                  args,
                                },
                              }),
                            },
                          }),
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.IptTxtMaskPhone
                    pass={{
                      propsArray: [{}],

                      stylesArray: [
                        {
                          color: 'black',
                          fontSize: 14,
                          // fontSize: '20px',<= #ATTENTION: Native ERROR! No string!
                        },
                      ],

                      editPath: [
                        jsvals.j8({
                          pass: {
                            propertieValues:
                              'sc.A0.forms.iptsChanges.userPhone',
                          },
                        }),
                      ],

                      funcsArray: [
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues:
                                      'sc.A0.forms.iptsChanges.userPhone',
                                  },
                                }),
                              ],
                              value: jsvals.argReader({
                                pass: {
                                  argPath: jsvals.j8({
                                    pass: {
                                      propertieValues: '#0',
                                    },
                                  }),
                                  args,
                                },
                              }),
                            },
                          }),
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.IptTxtMaskRG
                    pass={{
                      propsArray: [{}],

                      stylesArray: [
                        {
                          color: 'black',
                          fontSize: 14,
                          // fontSize: '20px',<= #ATTENTION: Native ERROR! No string!
                        },
                      ],

                      editPath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.A0.forms.iptsChanges.userRG',
                          },
                        }),
                      ],

                      funcsArray: [
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues:
                                      'sc.A0.forms.iptsChanges.userRG',
                                  },
                                }),
                              ],
                              value: jsvals.argReader({
                                pass: {
                                  argPath: jsvals.j8({
                                    pass: {
                                      propertieValues: '#0',
                                    },
                                  }),
                                  args,
                                },
                              }),
                            },
                          }),
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.IptTxtMaskCCard
                    pass={{
                      propsArray: [{}],

                      stylesArray: [
                        {
                          color: 'black',
                          fontSize: 14,
                          // fontSize: '20px',<= #ATTENTION: Native ERROR! No string!
                        },
                      ],

                      editPath: [
                        jsvals.j8({
                          pass: {
                            propertieValues:
                              'sc.A0.forms.iptsChanges.card.number',
                          },
                        }),
                      ],

                      funcsArray: [
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues:
                                      'sc.A0.forms.iptsChanges.card.number',
                                  },
                                }),
                              ],
                              value: jsvals.argReader({
                                pass: {
                                  argPath: jsvals.j8({
                                    pass: {
                                      propertieValues: '#0',
                                    },
                                  }),
                                  args,
                                },
                              }),
                            },
                          }),
                      ],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.IptTxtMaskCustomNumbers
                    pass={{
                      propsArray: [
                        (...args: any) =>
                          props.placeholder({
                            pass: {
                              A: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues: 'Nome',
                                  },
                                }),
                              ],
                            },
                          }),
                      ],

                      stylesArray: [
                        {
                          color: 'black',
                          fontSize: 14,
                          // fontSize: '20px',<= #ATTENTION: Native ERROR! No string!
                        },
                      ],

                      editPath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: 'sc.A0.forms.iptsChanges.card.cvv',
                          },
                        }),
                      ],

                      funcsArray: [
                        async (...args) =>
                          functions.setVar({
                            args,
                            pass: {
                              keyPath: [
                                jsvals.j8({
                                  pass: {
                                    propertieValues:
                                      'sc.A0.forms.iptsChanges.card.cvv',
                                  },
                                }),
                              ],
                              value: jsvals.argReader({
                                pass: {
                                  argPath: jsvals.j8({
                                    pass: {
                                      propertieValues: '#0',
                                    },
                                  }),
                                  args,
                                },
                              }),
                            },
                          }),
                      ],

                      ipt5: [
                        jsvals.j8({
                          pass: {
                            propertieValues: '4',
                          },
                        }),
                      ],

                      args,
                    }}
                  />
                ),
              ],

              args,
            }}
          />
        ),
      ],

      startFunctions: [() => {}],

      args,
    }}
  />
);
