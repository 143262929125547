import React from 'react';
import { FlatList } from 'react-native';

import { mapElements, pathSel } from '../project';
import { useData } from '../..';

type Tprops = {
  pass: {
    elementProperties: any;
    pData: any;
    startFuncs: Function[];
    finishFuncs: Function[];
    itemElements: any;
    args: any;
  };
};

// FlatList2 - ccc_flatList (newBase)
export const FlatListView = (props: Tprops) => {
  // ------- set Caps Inputs
  const {
    elementProperties,
    pData,
    startFuncs,
    finishFuncs,
    itemElements,
    args,
  } = props.pass;

  // ---------- call Start Functions (If Exists)
  React.useEffect(() => {
    const callFn = async () => {
      for (const currFunc of startFuncs) await currFunc();
    };
    console.log('MONTANDO LISTA');
    callFn().catch(err => console.log('Project Start Functions', { err }));

    return () => {
      // ---------- call Finish  Functions (If Exists)
      console.log('DESMONTANDO LISTA');
      const callFn2 = async () => {
        for (const currFunc of finishFuncs) await currFunc();
      };
      callFn2().catch(err => console.log('Project Start Functions', { err }));
    };
  }, []);

  // ---------- set Data Listener
  let watchData = '';
  console.log({ pData });
  if (typeof pData === 'string') watchData = useData(ct => pathSel(ct, pData));
  console.log({ watchData });

  // ---------- set List Item
  const renderItem = ({ item, index }: any) => (
    <>{mapElements(itemElements, { item, index })}</>
  );

  // ------- set User Element Properties (If Exists)
  const userElProps = {};

  for (const object of elementProperties) {
    for (const keyProp in object) {
      const valueProp = object[keyProp];
      userElProps[keyProp] = valueProp;
    }
  }

  const allProps = {
    data: watchData,
    renderItem,

    ...userElProps,
  };

  // ------- set Render
  return <FlatList {...allProps} />;
};
