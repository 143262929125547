import { errFnc } from '.';
import { getCtData, goTo, setData } from '../../project';
import { createCustomer } from './createCustomer';
import { editCCard } from './editCCard';
import { getCardHash } from './getCardHash';
import { setErrorTrue } from './helpers/setCtDataError';

export const updateCredCard = async () => {
  console.log('Update Credit Card');
  setData({ path: 'all.toggles.loaderPurchase', value: 'show' });
  const cardId = getCtData('all.userData.paymentData.id');

  // --------------------------
  // ------- CRIAR NOVO CARTÃO
  // --------------------------
  if (!cardId) {
    // 1 -- CRIAR CUSTOMER PAGAR.ME
    const customerId = getCtData('all.userData.customerId');
    if (!customerId) {
      console.log('pagarme', 'New Customer');
      // const resCustomer = await createCustomer().catch(errFnc);
      const resCus = await createCustomer().catch(errFnc);
      console.log({ resCus });
      if (resCus !== 'follow') return;
    }

    // 2 -- CRIAR CARTÃO E ASSOCIAR AO ID PAGAR.ME
    const condError: any = await getCardHash();

    // ----- check IF Stop Process
    if (condError !== 'follow') return;

    // ----- check IF Success
    if (condError === 'follow') {
      setData({ path: 'all.toggles.loaderPurchase', value: null });
      goTo('b4chistory');
    }
  }

  // --------------------------
  // ----------- EDITAR CARTÃO
  // --------------------------
  if (cardId) {
    // ----------------------------------
    // ---------------- FIRST DELETE CARD
    // ----------------------------------
    const condDelCardError: any = await editCCard(); // DELETE CARD
    // ----- check IF Stop Process
    if (condDelCardError !== 'follow') return;

    // ---------------------------------------
    // ---------------- AFTER CREATE NEW CARD
    // ---------------------------------------
    const condCreateCardError: any = await getCardHash();

    // ----- check IF Stop Process
    if (condCreateCardError !== 'follow') return;

    // ----- check IF Success
    if (condCreateCardError === 'follow') goTo('b4chistory');
  }
};
