// file: B1_Login.tsx

// ---------- import Local Tools
import { mapElements } from '../tools/project/mapElements';
import * as functions from '../tools/functions';
import * as Elements from '../tools/Elements';
import * as customs from '../tools/customs';
import { Project } from '../tools/project/';
import * as jsvals from '../tools/jsvals';
import { props } from '../tools/props';
import * as stls from '../tools/stls';
import { tools } from '../tools';

// ---------- set Temp Dev DataBase
import * as devDB from '../devDB';
import { mock } from '../devDB';

export const screen = (...args: any) => (
  <Elements.Screen3
    pass={{
      pathScreen: 'b1login',

      styles: [
        stls.width({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.height({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '100%',
                },
              }),
            ],
          },
        }),
        stls.flex({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '1px',
                },
              }),
            ],
          },
        }),

        stls.alignItems({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.justifyContent({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: 'center',
                },
              }),
            ],
          },
        }),
        stls.backgroundColor({
          pass: {
            arrayValue: [
              jsvals.varReader({
                pass: {
                  path: jsvals.j8({
                    pass: {
                      propertieValues: 'all.colors.deepPurple',
                    },
                  }),
                },
              }),
            ],
          },
        }),
        stls.padding({
          pass: {
            arrayValue: [
              jsvals.j8({
                pass: {
                  propertieValues: '30px',
                },
              }),
            ],
          },
        }),
      ],

      screenElements: [
        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.alignItems({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'center',
                        },
                      }),
                    ],
                  },
                }),
                stls.justifyContent({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 'center',
                        },
                      }),
                    ],
                  },
                }),

                stls.width({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '300px',
                        },
                      }),
                    ],
                  },
                }),
                stls.height({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '310px',
                        },
                      }),
                    ],
                  },
                }),
                stls.padding({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '15px',
                        },
                      }),
                    ],
                  },
                }),
                stls.backgroundColor({
                  pass: {
                    arrayValue: [
                      jsvals.varReader({
                        pass: {
                          path: jsvals.j8({
                            pass: {
                              propertieValues: 'all.colors.white',
                            },
                          }),
                        },
                      }),
                    ],
                  },
                }),
                stls.borderRadius({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '18px',
                        },
                      }),
                    ],
                  },
                }),
                stls.width({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '100%',
                        },
                      }),
                    ],
                  },
                }),
                stls.shadows({
                  pass: {
                    shadowColor: ['#000'],
                    shadowOffset: ['{width: 0, height: 0}'],
                    shadowOpacity: ['0.4px'],
                    shadowRadius: ['20px'],
                  },
                }),
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [
                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.ImageBox
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '150px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '180px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      URIvariablePath: [
                        jsvals.j8({
                          pass: {
                            propertieValues:
                              'https://firebasestorage.googleapis.com/v0/b/goodbike-cd338.appspot.com/o/images%2Flogo_vertical.png?alt=media&token=19d722aa-d757-4ba6-b37f-9d7527468d47',
                          },
                        }),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),
                (...args: any) => (
                  <Elements.Text
                    pass={{
                      arrProps: [{}],

                      arrStyles: [
                        stls.fontSize({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 12,
                                },
                              }),
                            ],
                          },
                        }),
                        stls.color({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: 'gray',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      children: [
                        jsvals.varReader({
                          pass: {
                            path: jsvals.j8({
                              pass: {
                                propertieValues: 'sc.A1.loginUsing',
                              },
                            }),
                          },
                        }),
                      ],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '5px',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),

                (...args) => (
                  <customs.LoginWithGoogle
                    pass={{
                      ipt1: [''],
                      ipt2: [''],
                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        stls.height({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '10px',
                                },
                              }),
                            ],
                          },
                        }),
                        stls.width({
                          pass: {
                            arrayValue: [
                              jsvals.j8({
                                pass: {
                                  propertieValues: '100%',
                                },
                              }),
                            ],
                          },
                        }),
                      ],

                      variablePath: [],

                      expectedVal: [],

                      childrenItems: [() => <></>],

                      args,
                    }}
                  />
                ),

                (...args: any) => (
                  <Elements.DynView
                    pass={{
                      elementsProperties: [{}],

                      styles: [
                        {
                          backgroundColor: 'white',
                          justifyContent: 'center',
                          minHeight: 22,
                          width: '100%',
                        },
                      ],

                      variablePath: [
                        jsvals.j8({
                          pass: {
                            propertieValues: '',
                          },
                        }),
                      ],

                      expectedVal: [],

                      childrenItems: [
                        (...args: any) => (
                          <Elements.Pressable3
                            pass={{
                              elementProperties: [{}],

                              styles: [
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.deepPurple',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.backgroundColor({
                                  pass: {
                                    arrayValue: [
                                      jsvals.varReader({
                                        pass: {
                                          path: jsvals.j8({
                                            pass: {
                                              propertieValues:
                                                'all.colors.deepPurple',
                                            },
                                          }),
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingHorizontal({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '32px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.paddingVertical({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '8px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.borderRadius({
                                  pass: {
                                    arrayValue: [
                                      jsvals.j8({
                                        pass: {
                                          propertieValues: '20px',
                                        },
                                      }),
                                    ],
                                  },
                                }),
                                stls.shadows({
                                  pass: {
                                    shadowColor: ['#000'],
                                    shadowOffset: ['{width: 0, height: 0}'],
                                    shadowOpacity: ['0.4px'],
                                    shadowRadius: ['20px'],
                                  },
                                }),
                              ],

                              childrenItems: [
                                (...args: any) => (
                                  <Elements.Text
                                    pass={{
                                      arrProps: [{}],

                                      arrStyles: [
                                        stls.color({
                                          pass: { arrayValue: ['white'] },
                                        }),
                                      ],

                                      children: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'Google',
                                          },
                                        }),
                                      ],

                                      args,
                                    }}
                                  />
                                ),
                              ],

                              pressableFunctions: [
                                async (...args) =>
                                  functions.firebase.getDoc({
                                    args,
                                    pass: {
                                      fbInit: [
                                        jsvals.varReader({
                                          pass: {
                                            path: jsvals.j8({
                                              pass: {
                                                propertieValues: 'all.fireInit',
                                              },
                                            }),
                                          },
                                        }),
                                      ],
                                      arrRefStrings: [
                                        jsvals.j8({
                                          pass: {
                                            propertieValues: 'users',
                                          },
                                        }),
                                        jsvals.j8({
                                          pass: {
                                            propertieValues:
                                              '0LFPsAkFyHntvR80pIaM',
                                          },
                                        }),
                                      ],
                                      arrFuncs: [
                                        async (...args) =>
                                          functions.funcGroup({
                                            args,
                                            pass: {
                                              arrFunctions: [
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B2.forms.editData.userName',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0.1.userName',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B2.forms.editData.userCPF',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0.1.userCPF',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B2.forms.editData.userRG',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0.1.userRG',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B2.forms.editData.userPhone',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0.1.userPhone',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B2.forms.editData.userAddress',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0.1.userAddress',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'all.userData',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0.1',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.setVar({
                                                    args,
                                                    pass: {
                                                      keyPath: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'sc.B2.forms.editData.userGender',
                                                          },
                                                        }),
                                                      ],
                                                      value: jsvals.argReader({
                                                        pass: {
                                                          argPath: jsvals.j8({
                                                            pass: {
                                                              propertieValues:
                                                                '#0.1.userGender',
                                                            },
                                                          }),
                                                          args,
                                                        },
                                                      }),
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.funcGroup({
                                                    args,
                                                    pass: {
                                                      arrFunctions: [
                                                        async (...args) =>
                                                          functions.condition({
                                                            args,
                                                            pass: {
                                                              conditionPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'sc.B2.forms.editData.userGender',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              expectedValue: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      'Masculino',
                                                                  },
                                                                }),
                                                              ],
                                                              functionsTrue: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'all.toggles.radioButton',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'male',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              functionsFalse: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'all.toggles.radioButton',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'female',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              args,
                                                            },
                                                          }),
                                                        async (...args) =>
                                                          functions.condition({
                                                            args,
                                                            pass: {
                                                              conditionPath: [
                                                                jsvals.varReader(
                                                                  {
                                                                    pass: {
                                                                      path: jsvals.j8(
                                                                        {
                                                                          pass: {
                                                                            propertieValues:
                                                                              'sc.B2.forms.editData.userGender',
                                                                          },
                                                                        },
                                                                      ),
                                                                    },
                                                                  },
                                                                ),
                                                              ],
                                                              expectedValue: [
                                                                jsvals.j8({
                                                                  pass: {
                                                                    propertieValues:
                                                                      '',
                                                                  },
                                                                }),
                                                              ],
                                                              functionsTrue: [
                                                                async (
                                                                  ...args
                                                                ) =>
                                                                  functions.setVar(
                                                                    {
                                                                      args,
                                                                      pass: {
                                                                        keyPath:
                                                                          [
                                                                            jsvals.j8(
                                                                              {
                                                                                pass: {
                                                                                  propertieValues:
                                                                                    'all.toggles.radioButton',
                                                                                },
                                                                              },
                                                                            ),
                                                                          ],
                                                                        value:
                                                                          jsvals.j8(
                                                                            {
                                                                              pass: {
                                                                                propertieValues:
                                                                                  'unselected',
                                                                              },
                                                                            },
                                                                          ),
                                                                      },
                                                                    },
                                                                  ),
                                                              ],
                                                              functionsFalse: [
                                                                () => {},
                                                              ],
                                                              args,
                                                            },
                                                          }),
                                                      ],
                                                    },
                                                  }),
                                                async (...args) =>
                                                  functions.condition({
                                                    args,
                                                    pass: {
                                                      conditionPath: [
                                                        jsvals.argReader({
                                                          pass: {
                                                            argPath: jsvals.j8({
                                                              pass: {
                                                                propertieValues:
                                                                  '#0.1.fullRegister',
                                                              },
                                                            }),
                                                            args,
                                                          },
                                                        }),
                                                      ],
                                                      expectedValue: [
                                                        jsvals.j8({
                                                          pass: {
                                                            propertieValues:
                                                              'true',
                                                          },
                                                        }),
                                                      ],
                                                      functionsTrue: [
                                                        (...args) => {
                                                          // ---------- get Function from A_Project Scope
                                                          return tools.goTo(
                                                            'b3bikemap',
                                                          );
                                                        },
                                                      ],
                                                      functionsFalse: [
                                                        (...args) => {
                                                          // ---------- get Function from A_Project Scope
                                                          return tools.goTo(
                                                            'b2register',
                                                          );
                                                        },
                                                      ],
                                                      args,
                                                    },
                                                  }),
                                              ],
                                            },
                                          }),
                                      ],
                                    },
                                  }),
                              ],

                              args,
                            }}
                          />
                        ),
                      ],

                      args,
                    }}
                  />
                ),
              ],

              args,
            }}
          />
        ),

        (...args: any) => (
          <Elements.DynView
            pass={{
              elementsProperties: [{}],

              styles: [
                stls.height({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '10px',
                        },
                      }),
                    ],
                  },
                }),
                stls.width({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '100%',
                        },
                      }),
                    ],
                  },
                }),
              ],

              variablePath: [],

              expectedVal: [],

              childrenItems: [() => <></>],

              args,
            }}
          />
        ),
        (...args: any) => (
          <Elements.Text
            pass={{
              arrProps: [{}],

              arrStyles: [
                stls.fontSize({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: 12,
                        },
                      }),
                    ],
                  },
                }),
                stls.color({
                  pass: {
                    arrayValue: [
                      jsvals.j8({
                        pass: {
                          propertieValues: '#58173b',
                        },
                      }),
                    ],
                  },
                }),
              ],

              children: [
                jsvals.varReader({
                  pass: {
                    path: jsvals.j8({
                      pass: {
                        propertieValues: 'all.system.version',
                      },
                    }),
                  },
                }),
              ],

              args,
            }}
          />
        ),
      ],

      startFunctions: [() => {}],

      args,
    }}
  />
);
