import React from 'react';
import { Text as RNText } from 'react-native';
import { getStlValues } from '../project';

type Tprops = {
  pass: { arrProps: any; arrStyles: any; children: any; args?: any };
};

export const Text = (props: Tprops) => {
  // ---------- set Capsules Inputs
  const { arrProps, arrStyles, args } = props.pass;
  let { children } = props.pass;

  if (typeof children === 'object') {
    // console.log({ children: children[0](args) });
    const newMap = children.map((item, idx) => {
      if (typeof item === 'function') {
        console.log({ item });
        const test = item(args);

        return test;
      }

      return item;
    });

    children = newMap.reduce((prev, curr) => prev + curr, '');
  }

  // ------- set User Props
  let userElProps = {};

  for (const object of arrProps) {
    const isFnc = typeof object === 'function';
    let newObj = {};
    if (isFnc) {
      console.log('IS A FUNCTION !!!!!!!!!!');
      newObj = object();
      userElProps = { ...userElProps, ...newObj };
    }

    if (!isFnc) {
      console.log('NOT FUNCTION');
      console.log({ object });

      for (const keyProp in object) {
        const valueProp = object[keyProp];
        userElProps[keyProp] = valueProp;
      }
    }
  }

  // ---------- set Styles
  const stl = getStlValues(arrStyles);

  const allProps = {
    style: [stl],
    children,

    ...userElProps,
  };

  // ---------- set Render
  return <RNText {...allProps} />;
};
