export const getAddress = (array, type) => {
  // console.warn('GET ADDRESS');
  // console.log('GET ADDRESS');
  let address_part_found = '';

  array.map(i => {
    const types = i.types;
    // console.log({ i });
    // console.log({ types });

    types.map(e => {
      // console.log({ e });
      const match = e.includes(type);
      // console.log({ match, type });

      if (match) address_part_found = i.short_name;
    });
  });
  // console.log({ address_part_found });

  return address_part_found;
};
